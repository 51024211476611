/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getSignalById } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export default function ViewSignal() {
    let { id } = useParams();
    const [signalDetails, setSignalDetails] = useState(null)
    const [chartImage, setChartImage] = useState(null);
    const [reportPDF, setReportPDF] = useState(null);

    useEffect(() => {
        if (id != undefined) {
            getSignalById({ "signal_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setSignalDetails(res.data)
                    // setChartImage(res.data.chart_image)
                    // setReportPDF(res.data.report_pdf)
                }
            });

        }
    }, [id])
    console.log(signalDetails,"-------------signalDetails-------------");
    if (signalDetails == null) return <> </>
    return (

        <><div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid'>
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>View Signal</h5>
                        <hr></hr>
                        <div className='form-grup'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <label className="form-label font-weight-bold"> Chart Image </label><br />
                                    {signalDetails.chart_image_url != null ? <>

                                        <span class="loc-caption">
                                            <div className="mb-3">
                                                <div className="profile-pic">

                                                    <img src={signalDetails.chart_image_url} id="output" width="200" />
                                                </div>
                                            </div>
                                        </span>
                                    </> : <>Not uploaded</>}
                                </div>
                                <div className='col-md-9'>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Siganl Name</label><br />
                                                <span class="font-15">{signalDetails?.name}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Siganl Category</label><br />
                                                <span class="font-15">{signalDetails?.category}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Publish Date</label><br />
                                                <span class="font-15">{signalDetails?.publish_date}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Trade Direction</label><br />
                                                <span class="font-15">{signalDetails?.trade_direction === 1 ? "LONG" : "SHORT"}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Siganl Type</label><br />
                                                <span class="font-15">{signalDetails?.type === 1 ? "Free" : "Premium"}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Entry Price</label><br />
                                                <span class="font-15">{'\u20A8'} {signalDetails?.entry_price}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Stop Loss / Risk</label><br />
                                                <span class="font-15">{'\u20A8'} {signalDetails?.stoploss.price}</span>&nbsp;/ {signalDetails?.stoploss.percentage}%
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Target Price</label><br />
                                                <span className="font-15">{'\u20A8'} {signalDetails?.target_price.price}</span>&nbsp;/ {signalDetails?.target_price.percentage}%
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Trade Category</label><br />
                                                <span class="font-15">{signalDetails?.trade_category}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Symbol</label><br />
                                                <span class="font-15">{signalDetails?.symbol}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold"> New Idea </label><br />
                                                <span class="font-15">{signalDetails?.is_new_idea === 1 ? "Yes" : "No"}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold"> Display on Homepage </label><br />
                                                <span class="font-15">{signalDetails?.is_display_on_homepage === 1 ? "Yes" : "No"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold"> Save To Draft </label><br />
                                                <span class="font-15">{signalDetails?.is_draft === 1 ? "Yes" : "No"}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold"> Segment </label><br />
                                                <span class="font-15">{signalDetails?.segment}</span>
                                            </div>
                                            {/* <div className='col-md-6'>
                                                <label className="form-label font-weight-bold"> Strategies </label><br />
                                                <span class="font-15">{signalDetails?.strategies}</span>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold"> Buying Zone </label><br />
                                                <span class="font-15">{'\u20A8'} {signalDetails?.buying_zone?.lowerRange}</span> &nbsp; / {'\u20A8'} {signalDetails?.buying_zone?.upperRange}
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold"> Selling Zone </label><br />
                                                <span class="font-15">{'\u20A8'} {signalDetails?.selling_zone?.lowerRange??'-'}</span> &nbsp; / {'\u20A8'} {signalDetails?.selling_zone?.upperRange??'-'}
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Potential Profit</label><br />
                                                <span class="font-15">{'\u20A8'} {signalDetails?.profit.value}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">TradeLog Risk</label><br />
                                                <span class="font-15">{'\u20A8'} {signalDetails?.tradelog_risk.value}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Trailing Stoploss</label><br />
                                                <span class="font-15">{'\u20A8'} {signalDetails?.trailing_stoploss??'-'}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Partial Profit</label><br />
                                                <span class="font-15">{'\u20A8'} {signalDetails?.partial_profit??'-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Setup</label><br />
                                                <span class="font-15"> {signalDetails?.setup}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Signal Status</label><br />
                                                <span class="font-15"> {signalDetails?.signal_status === 1 ? "OPEN" : "CLOSE" }</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>

                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold">Status</label><br />
                                                <span class="font-15">{signalDetails?.is_active == 0 ? "🔴 Inactive" : "🟢 Active"}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label font-weight-bold"> Report PDF </label><br />
                                                {reportPDF != null ? <>

                                                    <span class="loc-caption">
                                                        <div className="mb-3">
                                                            <a href={`${process.env.REACT_APP_S3URL}/report_pdf/${reportPDF}`} target="_blank" style={{ color: 'pink' }}> Click Here To View PDF </a>
                                                        </div>
                                                    </span>
                                                </> : <>Not uploaded</>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div></div></div></div>

            {/* popup model */}
            <div id="show_image_popup">
                <div class="close-btn-area">

                    <button id="close-btn">X</button>
                </div>
                <div id="image-show-area">
                    <img id="large-image" src="" alt="">
                    </img></div>
            </div></>
    )
}