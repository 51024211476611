import React, { useEffect, useState, useRef } from 'react'
import { getStrategyList, getSubAdminById, getMarketList, importMarketSheet, removeSymbol, changeSymbolStatus } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';
import * as Alert from '../../common/Alert'
import S3 from 'react-aws-s3';
import ExcelJS from 'exceljs';
import { excelUpload } from '../../common/Common'
import { itemsPerPage } from '../../globals/constant';



function MarketList() {

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [marketList, setMarketList] = useState(null)

    const [maeketImport, setMarketImport] = useState(0)
    const fileInputRef = useRef(null);
    useEffect(() => {
        if (localStorage.getItem("Arole") == "S") {

            setMarketImport(1)
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            Swal.close()
            fetchList();
        }
        else {
            Swal.close()
            fetchList();
            getSubAdminById({ "sub_admin_id": localStorage.getItem('Aid') }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {

                    setMarketImport(res.data.permissions.filter(module => module.class_name === 'market' && module.is_import_sheet === 1).length > 0)
                }
            });

        }

    }, [])
    function fetchList() {
        // { "page": currentPage, "record_count": itemsPerPage }
        getMarketList({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setMarketList(response.data)
            } else {
                console.log("Else");
                setMarketList([])
            }
        });
    }

    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                // paging: false,
                pageLength: itemsPerPage,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'copy',
                    className: 'btn-mute'
                }, {
                    extend: 'csv',
                    className: 'btn-mute'
                }, {
                    extend: 'print',
                    className: 'btn-mute'
                }],
                "bDestroy": true,
            }
        );
    }, [marketList])
    const handleShowModal = () => {
        if (maeketImport == 1) {
            setShowModal(true);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const handleExtensionSubmit = async (event) => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        const file = fileInputRef.current.files[0];
        const uploadData = await excelUpload(file)
        const marketData = uploadData.map((item, index) => {
            return (
                {
                    symbol: item[1],
                    type: item[2],
                }
            )
        })

        importMarketSheet({ 'data': marketData }).then((res) => {
            Swal.close()
            res = JSON.parse(res)
            if (res.code == 200) {
                setMarketList(null)
                fetchList();
                Alert.SuccessAlert(res.message)

            } else {
                Alert.ErrorAlert(res.message)
            }
        });
        handleCloseModal()
    };
    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }





    if (marketList == null) return <> </>
    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <div className='col-md-2 offset-md-5' style={{ marginLeft: '92%' }}>
                                <button className='add_btn btn-custom' onClick={handleShowModal}><span className="mdi mdi-cloud-upload mdi-18px " ></span></button>
                            </div>

                            <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Scrip Name</th>
                                        <th style={{ textAlign: "center" }}>Scrip Symbol</th>
                                        <th style={{ textAlign: "center" }}>ISIN Code</th>
                                        <th style={{ textAlign: "center" }}>Security Id</th>
                                        <th style={{ textAlign: "center" }}>Type</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Scrip Name</th>
                                        <th style={{ textAlign: "center" }}>Scrip Symbol</th>
                                        <th style={{ textAlign: "center" }}>ISIN Code</th>
                                        <th style={{ textAlign: "center" }}>Type</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    {
                                        marketList && marketList.map((item, index) => {
                                            return (
                                                <tr id={"symbolId" + item._id} >
                                                    <td style={{ textAlign: "center" }}>
                                                        {index + 1}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.scrip_name}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.scrip_symbol}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.isin}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.security_id}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.category_name}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>


                        </div>

                        <Modal show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Import Excel File</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group controlId="excelFile">
                                        <Form.Label>Upload Excel File</Form.Label>
                                        <Form.Control type="file" ref={fileInputRef} name="excelFile" accept=".xlsx" />

                                    </Form.Group>
                                    <Button variant="primary" className="btn btn-primary mt-2 ml-1" onClick={handleExtensionSubmit} type="button">
                                        Submit
                                    </Button>
                                    <a href='/assets/demo/Sample_Database_Stocks.xlsx' className='mt-2 ml-1 btn btn-info' download="Sample_Database_Stocks.xlsx">
                                        Download Demo File
                                    </a>
                                </Form>
                            </Modal.Body>
                        </Modal>

                    </div>
                </div>
            </div>
        </>
    );
}

export default MarketList;