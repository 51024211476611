import React, { useEffect, useState } from 'react'
import { getStrategyList, getSubAdminById, removeStrategy, changeStartegyStatus } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';

function StrategyList() {
    const navigate = useNavigate();

    const [strategyList, setStrategyList] = useState(null)
    const [strategyAdd, setStrategyAdd] = useState(0)
    const [strategyView, setStrategyView] = useState(0)

    const [strategyRemove, setStrategyRemove] = useState(0)
    const [strategyEdit, setStrategyEdit] = useState(0)
    const [startegyChangeStatus, setStrategyChangeStatus] = useState(0)


    useEffect(() => {
        if (localStorage.getItem("Arole") == "S") {
            setStrategyView(1)
            setStrategyChangeStatus(1)
            setStrategyRemove(1)
            setStrategyAdd(1)
            setStrategyEdit(1)
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            getStrategyList({}).then((response) => {
                Swal.close()
                
                response = JSON.parse(response)
                if (response.code == 200) {
                    setStrategyList(response.data)
                } else {
                    setStrategyList([])
                }
            })
        }
        else {
            getStrategyList({}).then((response) => {
                Swal.close()

                response = JSON.parse(response)
                if (response.code == 200) {
                    getSubAdminById({ "sub_admin_id": localStorage.getItem('Aid') }).then((res) => {
                        res = JSON.parse(res)
                        if (res.code == 200) {

                            setStrategyList(response.data)
                            setStrategyEdit(res.data.permissions.filter(module => module.class_name === 'strategy' && module.is_edit === 1).length > 0
                            );
                            setStrategyView(res.data.permissions.filter(module => module.class_name === 'strategy' && module.is_view === 1).length > 0
                            );
                            setStrategyRemove(res.data.permissions.filter(module => module.class_name === 'strategy' && module.is_delete === 1).length > 0)
                            setStrategyChangeStatus(res.data.permissions.filter(module => module.class_name === 'strategy' && module.is_change_status === 1).length > 0)
                            setStrategyAdd(res.data.permissions.filter(module => module.class_name === 'strategy' && module.is_create === 1).length > 0
                            );
                        }
                    });
                } else {
                    setStrategyList([])
                }
            })
        }


    }, [])

    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 5,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'copy',
                    className: 'btn-mute'
                  },{
                    extend: 'csv',
                    className: 'btn-mute'
                  },{
                    extend: 'print',
                    className: 'btn-mute'
                  }],
                "bDestroy": true,
            }
        );
    }, [strategyList])

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }


    function renderStatusUiText(item) {
        return (
            item.is_active == 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    const addStrategy = () => {
        if (strategyAdd == 1) {
            navigate(`/strategy/add`);
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }

    }
    const viewStrategy = (item) => {
        if (strategyView == 1) {
            navigate(`/strategy/view/${item._id}`);
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    const editStrategy = (item) => {
        if (strategyEdit == 1) {

            navigate(`/strategy/edit/${item._id}`);
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    function changeStatus(strategyId, item, position) {
        if (startegyChangeStatus == 1) {

            const status = (item.is_active == 1) ? "0" : "1"

            changeStartegyStatus({ "strategy_id": strategyId, "is_active": status }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    showMessage(response.message)
                    strategyList[position].is_active = status
                    const data = renderToStaticMarkup(renderStatusUiText(strategyList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                    $('#mangeUser').DataTable().row("#strategyId" + strategyId).cell("#strategyStatus" + strategyId).data(data).draw(false);

                }
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    function deleteStrategyIdWise(strategyId) {
        if (strategyRemove == 1) {
            Swal.fire({
                title: 'Are you sure you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#02C9D6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    // console.log("strategyId " + strategyId)
                    removeStrategy({ "strategy_id": strategyId }).then((response) => {
                        response = JSON.parse(response)
                        if (response.code == 200) {
                            $('#mangeUser').DataTable().row("#strategyId" + strategyId).remove().draw(false);
                            showMessage(response.message)
                        }
                    });
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    if (strategyList == null) return <> </>
    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <button className='add btn-custom' onClick={addStrategy}><span className="mdi mdi-plus"></span></button>
                            <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Name</th>
                                        <th style={{ textAlign: "center" }}>Status</th>
                                        <th style={{ textAlign: "center" }}>Status Action</th>
                                        <th style={{ textAlign: "center" }}>Action</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Name</th>
                                        <th style={{ textAlign: "center" }}>Status</th>
                                        <th style={{ textAlign: "center" }}>Status Action</th>
                                        <th style={{ textAlign: "center" }}>Action</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    {
                                        strategyList && strategyList.map((item, index) => {
                                            return (
                                                <tr id={"strategyId" + item._id} >
                                                    <td style={{ textAlign: "center" }}>
                                                        {index + 1}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.name}
                                                    </td>
                                                    <td id={"strategyStatus" + item._id} style={{ textAlign: "center" }}>
                                                        {renderStatusUiText(item)}
                                                    </td>
                                                    <td id={"strategyStatus" + item._id} style={{ textAlign: "center" }}>
                                                        <Tooltip title="Change Status">
                                                            <IconButton onClick={() => { changeStatus(item._id, item, index) }}>
                                                                <FeatherIcon icon="repeat" width="18" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => { deleteStrategyIdWise(item._id) }}>
                                                                <FeatherIcon icon="trash-2" width="18" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={() => { editStrategy(item) }}>
                                                                <FeatherIcon icon="edit" width="18" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="View">
                                                            <IconButton onClick={() => viewStrategy(item)}>
                                                                <FeatherIcon icon="eye" width="18" /> {/* Replace with your desired view icon */}
                                                            </IconButton>
                                                        </Tooltip>



                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StrategyList;