/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getBasketById, getSignalData } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export default function ViewBasket() {
    let { id } = useParams();
    const [BasketDetails, setBasketDetails] = useState(null)
    const [bgImage, setBgImage] = useState(null);
    const [icon, setIcon] = useState(null);
    const [schematic, setSchematic] = useState(null);
    const [subBaskets, setSubBaskets] = useState([]);


    useEffect(() => {
        if (id != undefined) {
            getBasketById({ "basket_id": id, "is_view": 1 }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setBasketDetails(res.data)
                    setBgImage(res.data.bg_image)
                    setIcon(res.data.icon)
                    setSchematic(res.data.schematic)
                    if (res.data.sub_baskets.length > 0) {
                        setSubBaskets(res.data.sub_baskets)
                    }
                }
            });

        }
    }, [id])

    if (BasketDetails == null) return <> </>
    return (

        <><div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid'>
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>


                        <h5 className="mt-0 header-title">View Basket</h5>

                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#home2" role="tab">Basic Details</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#profile3" role="tab">Sub Baskets</a>
                            </li>

                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane active p-3" id="home2" role="tabpanel">
                                <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                {BasketDetails.basket_icon != null ?
                                                    <>

                                                        <div className="member-img">
                                                            <img src={BasketDetails.basket_icon_url} className="rounded-circle" alt="profile-image" style={{ width: 200, height: 200 }} />
                                                        </div>
                                                    </> : <>
                                                        <div>Not Uploaded</div>
                                                    </>
                                                }
                                            </div>


                                            <div className='col-md-9'>
                                                <div className="form-group">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label class="form-label">Basket Title</label><br />
                                                            <span class="font-15">{BasketDetails.title}</span>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <label class="form-label">About / Bio</label><br />
                                                            <span class="font-15">{BasketDetails.about}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label class="form-label">Category</label><br />
                                                            <span class="font-15">{BasketDetails.category_id.name}</span>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <label class="form-label">Report PDF</label><br />
                                                            {BasketDetails.report_pdf_url != null ? <>

                                                                <span class="loc-caption">
                                                                    <div className="mb-3">
                                                                        <a href={BasketDetails.report_pdf_url} target="_blank" style={{ color: 'pink' }}> Click Here To View PDF </a>
                                                                    </div>
                                                                </span>
                                                            </> : <>Not uploaded</>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <label class="form-label"><b>Stock Allocations : </b></label><br />
                                                            <ul>
                                                                {BasketDetails.stock_allocations.map((allocation, index) => (
                                                                    <li key={index}>{`${index + 1}. ${allocation.stock.scrip_name}: ${allocation.percentage}%`}</li>
                                                                ))}
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="tab-pane p-3" id="profile3" role="tabpanel">
                                <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                    {subBaskets.length > 0 ? (
                                        <>
                                            <div id="accordion">
                                                {
                                                    subBaskets.map((tradeItem) => (
                                                        <div className="card mb-1 mt-2" key={tradeItem._id}>
                                                            <div className="card-header p-3" id={`heading${tradeItem._id}`}>
                                                                <h6 className="m-0">
                                                                    <a
                                                                        href={`#collapse${tradeItem._id}`}
                                                                        className={`text-dark ${tradeItem._id !== 'One' ? 'collapsed' : ''}`}
                                                                        data-toggle="collapse"
                                                                        aria-expanded={tradeItem._id === 'One'}
                                                                        aria-controls={`collapse${tradeItem._id}`}
                                                                    >
                                                                        Basket Name #{tradeItem.title}
                                                                    </a>
                                                                </h6>
                                                            </div>
                                                            <div
                                                                id={`collapse${tradeItem._id}`}
                                                                className={`collapse ${tradeItem._id === 'One' ? 'show' : ''}`}
                                                                aria-labelledby={`heading${tradeItem._id}`}
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className='form-group'>
                                                                        <div className='row'>

                                                                            <div className='col-md-3'>
                                                                                {tradeItem.sub_basket_icon_url != null ?
                                                                                    <>

                                                                                        <div className="member-img">
                                                                                            <img src={tradeItem.sub_basket_icon_url} className="rounded-circle" alt="profile-image" style={{ width: 200, height: 200 }} />
                                                                                        </div>
                                                                                    </> : <>
                                                                                        <div>Not Uploaded</div>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            <div className='col-md-9'>
                                                                                <div className="form-group">
                                                                                    <div className='row'>

                                                                                        <div className='col-md-3'>
                                                                                            <label class="form-label"> <strong>Category</strong></label><br />
                                                                                            <span class="font-15">{tradeItem.category_id.name}</span>
                                                                                        </div>
                                                                                        <div className='col-md-3'>
                                                                                            <label class="form-label"> <strong> About/Bio</strong></label><br />
                                                                                            <span class="font-15">{tradeItem.about}</span>
                                                                                        </div>
                                                                                        <div className='col-md-3'>
                                                                                            <label class="form-label"> <strong>Report PDF</strong></label><br />
                                                                                            {tradeItem.sub_report_pdf_url != null ? <>

                                                                                                <span class="loc-caption">
                                                                                                    <div className="mb-3">
                                                                                                        <a href={tradeItem.sub_report_pdf_url} target="_blank" style={{ color: 'pink' }}> Click Here To View PDF </a>
                                                                                                    </div>
                                                                                                </span>
                                                                                            </> : <>Not uploaded</>}
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <div className='row'>
                                                                                        <div className='col-md-12'>
                                                                                            <label class="form-label"><b>Stock Allocations : </b></label><br />
                                                                                            <ul>
                                                                                                {tradeItem.stock_allocations.map((allocation, index) => (
                                                                                                    <li key={index}>{`${index + 1}. ${allocation.stock.scrip_name}: ${allocation.percentage}%`}</li>
                                                                                                ))}
                                                                                            </ul>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </>
                                    ) : (
                                        <center>
                                            Not added.
                                        </center>
                                    )}
                                </div>
                            </div>

                        </div>







                    </div></div></div></div>

            {/* popup model */}
            <div id="show_image_popup">
                <div class="close-btn-area">

                    <button id="close-btn">X</button>
                </div>
                <div id="image-show-area">
                    <img id="large-image" src="" alt="">
                    </img></div>
            </div></>
    )
}