/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getSymbolDataById } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export default function ViewSymbol() {
    let { id } = useParams();
    const [SymbolDetails, setSymbolDetails] = useState(null)
    const [symbolLogo, setSymbolLogo] = useState(null);

    useEffect(() => {
        if (id != undefined) {
            getSymbolDataById({ "symbol_id": id }).then((res) => {
                res = JSON.parse(res)
                console.log(res.data.scrip_logo);
                if (res.code == 200) {
                    setSymbolDetails(res.data)
                    setSymbolLogo(res.data.scrip_logo)
                }
            });

        }
    }, [id])
    
    if (SymbolDetails == null) return <> </>
    return (

        <><div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid'>
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>View Symbol</h5>
                        <hr></hr>
                        <div className="form-group">
                            <div className='row'>
                                {/* <div className="mb-3">
                                                    <div className="admin-profile image-view">

                                                        <img src={imageUrl} id="output" width="200" />
                                                    </div>
                                                </div> */}
                                <div className='col-md-3'>
                                    {SymbolDetails.scrip_logo_url != null ? <>
                                    
                                        <div className="member-img">
                                        <img src={SymbolDetails.scrip_logo_url} className="rounded-circle" alt="symbol-logo" style={{ width: 200, height: 200 }} />
                                    </div>
                                    </>
                                    : <><div>Not Uploaded</div></>
                                    }
                                    
                                </div>
                                <div className='col-md-9'>

                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label class="form-label">Scrip Name</label><br />
                                                <span class="font-15">{SymbolDetails.scrip_name}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label class="form-label">Scrip Symbol</label><br />
                                                <span class="font-15">{SymbolDetails.scrip_symbol}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label class="form-label">Category</label><br />
                                                <span class="font-15">{SymbolDetails.category}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label class="form-label">Exchange</label><br />
                                                <span class="font-15">{SymbolDetails.exchange}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label class="form-label">Sector</label><br />
                                                <span class="font-15">{SymbolDetails.sector}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label class="form-label">Sub Sector</label><br />
                                                <span class="font-15">{SymbolDetails.sub_sector}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>

                                            <div className='col-md-6'>
                                                <label class="form-label">Status</label><br />
                                                <span class="font-15">{SymbolDetails.is_active == 0 ? "🔴 Inactive" : "🟢 Active"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></div></div></div>

            {/* popup model */}
            <div id="show_image_popup">
                <div class="close-btn-area">

                    <button id="close-btn">X</button>
                </div>
                <div id="image-show-area">
                    <img id="large-image" src="" alt="">
                    </img></div>
            </div></>
    )
}