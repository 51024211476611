import React, { useEffect, useState } from 'react'
import { getSignalList, changesignalStatus, getSubAdminById, removeSignal, addLastTradePrice, getAdminNames , closeSignal } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';
import { getStockLTP, getFNOStockLTP } from '../../common/Common'
import Select from 'react-select';
import { typeOptions, signalDraftOptions } from '../../globals/constant';
import moment from 'moment';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./cta-model.css";

function SignalList() {
    const navigate = useNavigate();

    const [signalList, setSignalList] = useState(null)
    const [signalRemove, setSignalRemove] = useState(0)
    const [signalChangeStatus, setSignalChangeStatus] = useState(0)
    const [signalEdit, setSignalEdit] = useState(0)
    const [signalAdd, setSignalAdd] = useState(0)
    const [signalView, setSignalView] = useState(0)
    const [showModal, setShowModal] = useState(false);
    const [err, setErr] = useState('');
    const [signalID, setSignalID] = useState(null);
    const [radioType, setRadioType] = useState(null);
    const [closeType, setCloseType] = useState(null);
    const [LTP, setLTP] = useState(0);
    const [closeValue, setCloseValue] = useState(null);
    const [selectedSignal, setSelectedSignal] = useState(null);
    const [divState, setDivState] = useState(null);
    const [signalType, setSignalType] = useState(null);
    const [adminNames, setAdminNames] = useState(null);
    const [adminName, setAdminName] = useState(null);
    const [showSignal, setShowSignal] = useState(0);
    const [qty, setQty] = useState(null);
    const [reqObj, setReqObj] = useState({})
    const [customValue, setCustomValue] = useState(null)
    const [trailSL, setTrailSL] = useState(null);
    const [targetUpdate, setTragetUpdate] = useState(null)
    const [show, isShow] = useState(false);
    const [validationMessage, setValidationMessage] = useState(null);
    const [rrr, setrrr] = useState(null);
    const [showCloseModal, setShowCloseModal] = useState(false);
    const [dates, setDatesState] = useState({
        startDate: "",
        endDate: ""
    });

    const setDates = (e, { startDate, endDate }) => {
        setDatesState({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD")
        });
    };
    function getAdminData() {
        getAdminNames({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setAdminNames(response.data)
            }
        })
    }
    function clearFilter() {

        // Alert.processing()
        setTimeout(() => {
            Swal.close()
            setAdminName()
            setSignalType()
            setDatesState({
                startDate: "",
                endDate: ""
            });
        }, 0)

    }
    useEffect(() => {
        setSignalList(null)
        getSignalList({ "start_date": dates.startDate, "end_date": dates.endDate, "signal_status": 1, "type": signalType != null ? signalType.value : signalType, "admin_id": adminName != null ? adminName.value : adminName, "is_draft": showSignal.value }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                // setUserList([])
                setSignalList(response.data)

                getAdminData()
            } else {
                setSignalList([])
            }
        })
    }, [dates, signalType, adminName, showSignal])
    useEffect(() => {
        console.log("Hello 1", localStorage.getItem("Arole"));
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        if (localStorage.getItem("Arole") == "S") {
            setSignalRemove(1)
            setSignalChangeStatus(1)
            setSignalEdit(1)
            setSignalAdd(1)
            setSignalView(1)

            console.log("Hello 2");
            getSignalList({}).then((response) => {

                Swal.close()
                response = JSON.parse(response)
                if (response.code == 200) {
                    setSignalList(response.data)
                    setAdminName({ value: localStorage.getItem('Aid'), label: localStorage.getItem('Aname') })

                } else {
                    setSignalList([])
                }
            })
        } else {
            console.log("Hello 3");
            getSignalList({}).then((response) => {

                Swal.close()

                response = JSON.parse(response)
                if (response.code == 200) {
                    setSignalList(response.data)
                    setAdminName({ value: localStorage.getItem('Aid'), label: localStorage.getItem('Aname') })

                    getSubAdminById({ "sub_admin_id": localStorage.getItem('Aid') }).then((res) => {
                        res = JSON.parse(res)
                        if (res.code == 200) {
                            setSignalEdit(res.data.permissions.filter(module => module.class_name === 'signal' && module.is_edit === 1).length > 0
                            );
                            setSignalView(res.data.permissions.filter(module => module.class_name === 'signal' && module.is_view === 1).length > 0
                            );
                            setSignalRemove(res.data.permissions.filter(module => module.class_name === 'signal' && module.is_delete === 1).length > 0)
                            setSignalChangeStatus(res.data.permissions.filter(module => module.class_name === 'signal' && module.is_change_status === 1).length > 0)
                            setSignalAdd(res.data.permissions.filter(module => module.class_name === 'signal' && module.is_create === 1).length > 0)
                        }
                    });
                } else {
                    setSignalList([])
                }
            })
        }
    }, [])
    useEffect(() => {
        $('#mangeUser').DataTable({
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            order: [[0, 'desc']],
            dom: 'Bfrtip',
            buttons: [{
                extend: 'copy',
                className: 'btn-mute'
            }, {
                extend: 'csv',
                className: 'btn-mute'
            }, {
                extend: 'print',
                className: 'btn-mute'
            }],
            "bDestroy": true,
            scrollX: true,
            scrollY: "500px", // adjust the height of the scrollable area
            fixedHeader: true,
        });
    }, [signalList])
    useEffect(() => {

        const riskRewardRatio = (((LTP - selectedSignal?.entry_price) / (selectedSignal?.entry_price - selectedSignal?.stoploss.price))).toFixed(2);
        setrrr(riskRewardRatio)
    }, [LTP, selectedSignal])
    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function changeStatus(signalId, item, position) {
        if (signalChangeStatus == 1) {

            const status = (item.is_active == 1) ? "0" : "1"

            changesignalStatus({ "signal_id": signalId, "is_active": status }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    showMessage(response.message)
                    signalList[position].is_active = status
                    const data = renderToStaticMarkup(renderStatusUiText(signalList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                    $('#mangeUser').DataTable().row("#signalId" + signalId).cell("#signalStatus" + signalId).data(data).draw(false);

                }
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    function renderStatusUiText(item) {
        return (
            item.is_active == 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    function deleteSignalIdWise(signalId) {
        if (signalRemove == 1) {
            Swal.fire({
                title: 'Are you sure you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#02C9D6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    // console.log("signalId " + signalId)
                    removeSignal({ "signal_id": signalId }).then((response) => {
                        response = JSON.parse(response)
                        if (response.code == 200) {
                            $('#mangeUser').DataTable().row("#signalId" + signalId).remove().draw(false);
                            showMessage(response.message)
                        }
                    });
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    const editSignal = (item) => {
        if (signalEdit == 1) {
            navigate(`/signal/edit/${item._id}`);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    const addSignal = () => {
        if (signalAdd == 1) {
            navigate(`/signal/add`);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    function validateCustomPrice(value) {
        if (selectedSignal?.trade_direction == '1') {
            if (radioType === 'TRAIL_SL' && value <= selectedSignal?.stoploss.price) {
                setValidationMessage(<div style={{ color: 'red' }}>Custom Price must be greater than SL</div>);
            } else if (radioType === 'TARGET_UPDATE' && value <= selectedSignal?.entry_price) {
                setValidationMessage(<div style={{ color: 'red' }}>Custom Price must be greater than Entry</div>);
            } else {
                setValidationMessage(null);
            }
        } else if (selectedSignal?.trade_direction == '2') {
            if (radioType === 'TRAIL_SL' && value >= selectedSignal?.stoploss.price) {
                setValidationMessage(<div style={{ color: 'red' }}>Custom Price must be less than SL</div>);
            } else if (radioType === 'TARGET_UPDATE' && value >= selectedSignal?.entry_price) {
                setValidationMessage(<div style={{ color: 'red' }}>Custom Price must be less than Entry</div>);
            } else {
                setValidationMessage(null);
            }
        }
    }
    const viewSignal = (item) => {
        if (signalView == 1) {
            navigate(`/signal/view/${item._id}`);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    const handleShowModal = (item) => {
        setSelectedSignal(item)
        // setrrr(item.risk_reward_ratio)
        setRadioType(item.cta_option && item.cta_option !== 'BOOK_PARTIALS' ? item.cta_option : 'SQUARE_OFF')
        isShow(item.cta_option === 'BOOK_PARTIALS' ? true : false)
        if (item.cta_option && item.cta_option === 'BOOK_PARTIALS') {
            setQty('REMAINING');
        }
        else {
            setQty('FULL')
        }
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleShowCloseModel = (item) => {
        setSelectedSignal(item)
        setShowCloseModal(true)
    }
    const handleMoveCloseModal = () => {
        setShowCloseModal(false);
    };
    useEffect(() => {
        if (radioType === 'SQUARE_OFF' || radioType === 'BOOK_PARTIALS') {
            setReqObj({});
            const Quantity = radioType === 'SQUARE_OFF' ? 'FULL' : 'HALF'
            if (qty !== 'REMAINING') {
                setQty(Quantity)
            }

            async function fetchData() {
                Swal.fire({
                    title: 'Please wait...',
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                let stockValue;
                if (selectedSignal.fno_symbol) {
                    stockValue = await getFNOStockLTP(selectedSignal.fno_symbol);
                }
                else {
                    stockValue = await getStockLTP(selectedSignal.symbol_id);
                }
                setLTP(stockValue?.ltp)
                setCloseValue(stockValue?.ltp)
                Swal.close()
                const tradeReturn = ((stockValue?.ltp - selectedSignal?.entry_price) / selectedSignal?.entry_price) * 100;

                // const tradeReturn = ((stockValue.ltp - selectedSignal?.entry_price) / 100).toFixed(2);

                setReqObj(prevState => ({
                    ...prevState,
                    qty: Quantity,
                    trade_close_price: LTP,
                    cta_option: radioType,
                    ...(radioType === 'SQUARE_OFF' && { signal_status: 2 }),
                    signal_id: selectedSignal._id,
                    trade_return: tradeReturn,
                    risk_reward_ratio: rrr
                }));
            }
            fetchData();
        } else if (radioType === 'TRAIL_SL') {
            setReqObj({});
            if (qty !== 'REMAINING') {
                setQty('FULL');
            }
            setReqObj(prevState => ({
                ...prevState,
                qty: qty,
                cta_option: radioType,
                trailing_stoploss: customValue,
                signal_id: selectedSignal._id
            }))
        }
        else if (radioType === 'TARGET_UPDATE') {
            if (qty !== 'REMAINING') {
                setQty('FULL');
            }
            setReqObj({});
            setReqObj(prevState => ({
                ...prevState,
                qty: qty,
                cta_option: radioType,
                signal_id: selectedSignal._id,
                partial_profit: customValue
            }))
        }
    }, [radioType, selectedSignal, qty, customValue, LTP, rrr]);
    const handleExtensionSubmit = (event) => {
        // Handle the form submission logic here.
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        if (radioType) {
            addLastTradePrice(reqObj).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: res.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setSignalList(null);
                    getSignalList({}).then((response) => {
                        Swal.close()
                        response = JSON.parse(response)
                        if (response.code == 200) {
                            setSignalList(response.data)

                        } else {
                            setSignalList([])
                        }
                    })
                }
            });
        }
        handleCloseModal();
    };
    const handleCloseSubmit = (event) => {
        // Handle the form submission logic here.
        event.preventDefault();
        const form = event.target;
        if (closeType) {
            closeSignal({"signal_id" : selectedSignal?._id , "close_type" : closeType}).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: res.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setSignalList(null);
                    getSignalList({}).then((response) => {
                        Swal.close()
                        response = JSON.parse(response)
                        if (response.code == 200) {
                            setSignalList(response.data)

                        } else {
                            setSignalList([])
                        }
                    })
                }
            });
        }
        handleMoveCloseModal();
    };
    if (signalList == null) return <> </>

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        {/* Close Signal Model */}
                        <Modal show={showCloseModal} onHide={handleMoveCloseModal} size="lg" className=" font-lg ">
                            <Modal.Header className='cta-header border-0' closeButton>

                                <div className="modal-header-info">
                                    <h5 className="modal-title" id="exampleModalLabel">Move To Close Trades</h5>
                                </div>
                            </Modal.Header>
                            <Modal.Body>

                                <Form onSubmit={handleCloseSubmit}>
                                    <div className="form-group">

                                        <div className='row'>
                                            <div className='col-md'>
                                                <label className="form-label font-weight-bold">Entry Price</label><br />
                                                <span className="font-15">{selectedSignal?.entry_price}</span>
                                            </div>
                                            <div className='col-md'>
                                                <label className="form-label font-weight-bold">StopLoss Price</label><br />
                                                <span className="font-15">{'\u20A8'} {selectedSignal?.stoploss.price}</span>
                                            </div>
                                            <div className='col-md'>
                                                <label className="form-label font-weight-bold">Target Price</label><br />
                                                <span className="font-15">{'\u20A8'} {selectedSignal?.target_price.price}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <h4>Exit Criteria : </h4>
                                    <div className='col-md-12'>
                                        <div>
                                            <input
                                                type="radio"
                                                id="exit1"
                                                name="stype"
                                                value="SL_HIT"
                                                checked={closeType === 'SL_HIT'}
                                                onClick={(event) => setCloseType(event.target.value)}
                                                className='mr-1'
                                            />
                                            <label htmlFor="exit1">SL Hit</label>

                                            <input
                                                type="radio"
                                                id="exit2"
                                                className='mr-1 ml-4'
                                                name="stype"
                                                hidden={show}
                                                value="TARGET_HIT"
                                                checked={closeType === 'TARGET_HIT'}
                                                onClick={(event) => setCloseType(event.target.value)}
                                            />
                                            <label htmlFor="exit2" >Target Hit</label>

                                            <input
                                                type="radio"
                                                id="exit2"
                                                className='mr-1 ml-4'
                                                name="stype"
                                                hidden={show}
                                                value="COST_EXIT"
                                                checked={closeType === 'COST_EXIT'}
                                                onClick={(event) => setCloseType(event.target.value)}
                                            />
                                            <label htmlFor="exit2" >Cost Exit</label>
                                        </div>
                                    </div>

                                    <Button variant="primary" className="btn btn-primary mt-2 ml-4" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                            </Modal.Body>
                        </Modal>
                        {/* Manage CTA Modal */}
                        <Modal show={showModal} onHide={handleCloseModal} size="lg" className=" font-lg ">
                            <Modal.Header className='cta-header border-0' closeButton>
                                {/* <Modal.Title>Manage CTA</Modal.Title> */}
                                {/* <div className='modal-header-info'> */}
                                <div className="modal-header-info">
                                    <h5 className="modal-title" id="exampleModalLabel">Manage CTA</h5>

                                </div>

                            </Modal.Header>
                            <Modal.Body>
                                {/* <div className="cta-section">
                                    <div className="cta-tabing">
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade active show" id="square-off" role="tabpanel" aria-labelledby="nav-square-off-tab">
                                                <div className="cta-table">
                                                    <div>
                                                        <label>Entry Price</label>
                                                        <span>Rs 3173.35</span>
                                                    </div>
                                                    <div>
                                                        <label>StopLoss Price</label>
                                                        <span>Rs 3200.00</span>
                                                    </div>
                                                    <div>
                                                        <label>Target Price</label>
                                                        <span>Rs 3000.00</span>
                                                    </div>
                                                    <div>
                                                        <label>LTP</label>
                                                        <span>Rs 0.00</span>
                                                    </div>
                                                    <div>
                                                        <label>Live Risk Reward Ratio</label>
                                                        <span>Rs 0.36</span>
                                                    </div>
                                                    <div>
                                                        <label>Risk Reward Ratio</label>
                                                        <span>1</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="nav-book" role="tabpanel" aria-labelledby="nav-book-tab">
                                                <div className="cta-table">
                                                    <div>
                                                        <label>Entry Price</label>
                                                        <span>Rs 3273.35</span>
                                                    </div>
                                                    <div>
                                                        <label>StopLoss Price</label>
                                                        <span>Rs 3300.00</span>
                                                    </div>
                                                    <div>
                                                        <label>Target Price</label>
                                                        <span>Rs 4000.00</span>
                                                    </div>
                                                    <div>
                                                        <label>LTP</label>
                                                        <span>Rs 0.00</span>
                                                    </div>
                                                    <div>
                                                        <label>Live Risk Reward Ratio</label>
                                                        <span>Rs 0.36</span>
                                                    </div>
                                                    <div>
                                                        <label>Risk Reward Ratio</label>
                                                        <span>2</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="nav-trails" role="tabpanel" aria-labelledby="nav-trails-tab">
                                                <div className="cta-table">
                                                    <div>
                                                        <label>Entry Price</label>
                                                        <span>Rs 3173.35</span>
                                                    </div>
                                                    <div>
                                                        <label>StopLoss Price</label>
                                                        <span>Rs 3200.00</span>
                                                    </div>
                                                    <div>
                                                        <label>Target Price</label>
                                                        <span>Rs 3000.00</span>
                                                    </div>
                                                    <div>
                                                        <label>LTP</label>
                                                        <span>Rs 0.00</span>
                                                    </div>
                                                    <div>
                                                        <label>Live Risk Reward Ratio</label>
                                                        <span>Rs 0.36</span>
                                                    </div>
                                                    <div>
                                                        <label>Risk Reward Ratio</label>
                                                        <span>3</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="nav-target" role="tabpanel" aria-labelledby="nav-target-tab">
                                                <div className="cta-table">
                                                    <div>
                                                        <label>Entry Price</label>
                                                        <span>Rs 4173.35</span>
                                                    </div>
                                                    <div>
                                                        <label>StopLoss Price</label>
                                                        <span>Rs 5200.00</span>
                                                    </div>
                                                    <div>
                                                        <label>Target Price</label>
                                                        <span>Rs 6000.00</span>
                                                    </div>
                                                    <div>
                                                        <label>LTP</label>
                                                        <span>Rs 0.00</span>
                                                    </div>
                                                    <div>
                                                        <label>Live Risk Reward Ratio</label>
                                                        <span>Rs 0.36</span>
                                                    </div>
                                                    <div>
                                                        <label>Risk Reward Ratio</label>
                                                        <span>4</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <nav>
                                            <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                                                <button className="nav-link" id="nav-quare-off-tab" data-bs-toggle="tab" data-bs-target="#square-off" type="button" role="tab" aria-controls="nav-quare-off" aria-selected="true"><img alt = "checked-img" src="assets/images/checked.png" className="act-img" /> Square Off</button>
                                                <button className="nav-link" id="nav-book-tab" data-bs-toggle="tab" data-bs-target="#nav-book" type="button" role="tab" aria-controls="nav-book" aria-selected="false"><img alt = "checked-img" src="assets/images/checked.png" className="act-img" /> Book Partials</button>
                                                <button className="nav-link" id="nav-trails-tab" data-bs-toggle="tab" data-bs-target="#nav-trails" type="button" role="tab" aria-controls="nav-trails" aria-selected="false"><img alt = "checked-img" src="assets/images/checked.png" className="act-img" /> Trails SL</button>
                                                <button className="nav-link" id="nav-target-tab" data-bs-toggle="tab" data-bs-target="#nav-target" type="button" role="tab" aria-controls="nav-target" aria-selected="false"><img alt = "checked-img" src="assets/images/checked.png" className="act-img" /> Target Update</button>
                                            </div>
                                        </nav>
                                    </div>
                                    <div className="cta-form">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-6">
                                                <div className="form-group mb-3">
                                                    <label>Enter Custom Price</label>
                                                    <input type="text" name="" placeholder="Please Enter Price" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-3">
                                                    <label>Quantity</label>
                                                    <input type="text" name="" placeholder="Please Enter Quantity" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mb-3 mt-4">
                                                    <a href="#" className="btn btn-submit w-100">Submit</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <Form onSubmit={handleExtensionSubmit}>
                                    <div className="form-group">

                                        <div className='row'>
                                            <div className='col-md'>
                                                <label className="form-label font-weight-bold">Entry Price</label><br />
                                                <span className="font-15">{selectedSignal?.entry_price}</span>
                                            </div>
                                            <div className='col-md'>
                                                <label className="form-label font-weight-bold">StopLoss Price</label><br />
                                                <span className="font-15">{'\u20A8'} {selectedSignal?.stoploss.price}</span>
                                            </div>
                                            <div className='col-md'>
                                                <label className="form-label font-weight-bold">Target Price</label><br />
                                                <span className="font-15">{'\u20A8'} {selectedSignal?.target_price.price}</span>
                                            </div>
                                            <div className='col-md'>
                                                <label className="form-label font-weight-bold">LTP</label><br />
                                                <span className="font-15"> {LTP}</span>
                                            </div>
                                            <div className='col-md'>
                                                <label className="form-label font-weight-bold">Live Risk Reward Ratio</label><br />
                                                <span className="font-15"> {rrr}</span>
                                            </div>
                                            <div className='col-md'>
                                                <label className="form-label font-weight-bold">Risk Reward Ratio</label><br />
                                                <span className="font-15"> {selectedSignal?.risk_reward_ratio}</span>
                                            </div>
                                            <div className='col-md'>
                                                <label className="form-label font-weight-bold">Trailing Stoploss</label><br />
                                                <span className="font-15"> {selectedSignal?.trailing_stoploss ?? 0}</span>
                                            </div>
                                            <div className='col-md'>
                                                <label className="form-label font-weight-bold">Target Update</label><br />
                                                <span className="font-15"> {selectedSignal?.partial_profit ?? 0}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className='col-md-12'>

                                        <div>
                                            <input
                                                type="radio"
                                                id="age1"
                                                name="stype"
                                                value="SQUARE_OFF"
                                                checked={radioType === 'SQUARE_OFF'}
                                                onClick={(event) => setRadioType(event.target.value)}
                                                className='mr-1'
                                            />
                                            <label htmlFor="age1">Square Off</label>

                                            <input
                                                type="radio"
                                                id="age2"
                                                className='mr-1 ml-4'
                                                name="stype"
                                                hidden={show}
                                                value="BOOK_PARTIALS"
                                                checked={radioType === 'BOOK_PARTIALS'}
                                                onClick={(event) => setRadioType(event.target.value)}
                                            />
                                            <label htmlFor="age2" hidden={show}>Book Partials</label>

                                            <input
                                                type="radio"
                                                id="age3"
                                                className='mr-1 ml-4'
                                                name="stype"
                                                value="TRAIL_SL"
                                                checked={radioType === 'TRAIL_SL'}
                                                onClick={(event) => setRadioType(event.target.value)}
                                            />
                                            <label htmlFor="age3" >Trail SL</label>

                                            <input
                                                type="radio"
                                                id="age4"
                                                className='mr-1 ml-4'
                                                name="stype"
                                                value="TARGET_UPDATE"
                                                checked={radioType === 'TARGET_UPDATE'}
                                                onClick={(event) => setRadioType(event.target.value)}
                                            />
                                            <label htmlFor="age4" >Target Update</label>

                                            {(radioType === 'SQUARE_OFF' || radioType === 'BOOK_PARTIALS') && (
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label>LTP</label>
                                                        <input
                                                            className="form-control bold-text"
                                                            name="t2"
                                                            type="text"
                                                            value={LTP}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label>Quantity</label>
                                                        <input
                                                            className="form-control bold-text"
                                                            name="qty"
                                                            type="text"
                                                            value={qty}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>

                                            )}

                                            {(radioType === 'TRAIL_SL' || radioType === 'TARGET_UPDATE') && (

                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label>Enter Custom Price</label>
                                                        <input
                                                            className="form-control bold-text"
                                                            name="t1"
                                                            type="text"
                                                            value={customValue}
                                                            placeholder="Please enter price"
                                                            onChange={(event) => {
                                                                setCustomValue(event.target.value);
                                                                validateCustomPrice(event.target.value);
                                                            }}
                                                            required
                                                        />
                                                        {validationMessage}

                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label>Quantity</label>
                                                        <input
                                                            className="form-control bold-text"
                                                            name="qty"
                                                            type="text"
                                                            value={qty}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            )}


                                        </div>
                                    </div>

                                    <Button variant="primary" className="btn btn-primary mt-2 ml-4" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                            </Modal.Body>
                        </Modal>
                        <div className='cal'>
                            <button className='btn btn-primary btn-custom' style={{ marginLeft: '90%' }} onClick={addSignal}>Add Signal</button>
                            <p>
                                <a className="btn btn-primary mo-mb-2" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                    Filter
                                </a>

                                <div className={`collapse ${divState !== null ? 'show' : ''}`} id="collapseExample">
                                    <div className="card card-body">
                                        <div className='row'>
                                            <div className='col-md-2'>
                                                <label>Admin Name : </label>

                                                <Select
                                                    aria-labelledby="aria-label"
                                                    placeholder="Select Type.."
                                                    value={adminName}
                                                    onChange={(e) => { setAdminName(e); setDivState('show'); }}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    name="aria-live-color"
                                                    options={adminNames}
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <label>Signal Type : </label>
                                                <Select
                                                    aria-labelledby="aria-label"
                                                    placeholder="Select Type.."
                                                    value={signalType}
                                                    onChange={(e) => { setSignalType(e); setDivState('show'); }}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    name="aria-live-color"
                                                    options={typeOptions}
                                                />

                                            </div>

                                            <div className='col-md-6'>
                                                <label>Select Date Range : </label>
                                                <DateRangePicker
                                                    onApply={setDates}
                                                    initialSettings={{ startDate: moment().subtract(6, "days").format('MM/DD/YYYY'), endDate: moment().format('MM/DD/YYYY') }}
                                                >
                                                    <input
                                                        type="text"
                                                        value={dates.startDate !== '' ? moment(dates.startDate).format('MMMM DD, YYYY') + " - " + moment(dates.endDate).format('MMMM DD, YYYY') : 'Select Time'}
                                                        className="form-control"
                                                        onChange={() => { console.log(`changes`); }}
                                                    />
                                                </DateRangePicker>


                                            </div>
                                            <div className='col-md-2'>
                                                <label>Display Signals : </label>
                                                <Select
                                                    aria-labelledby="aria-label"
                                                    placeholder="Select Type.."
                                                    value={showSignal}
                                                    onChange={(e) => { setShowSignal(e); setDivState('show'); }}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    name="aria-live-color"
                                                    options={signalDraftOptions}
                                                />

                                            </div>

                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <div className="form-inline">
                                                    <button href="" className="btn btn-warning waves-effect btn-file" onClick={clearFilter} >
                                                        <i className="mdi mdi-filter-remove-outline"></i> <span><b>Clear Filter</b></span>
                                                    </button>

                                                    <button className="btn btn-success waves-effect btn-file mx-1"  >
                                                        <i className="mdi mdi-cloud-download me-1 font-14"></i><span><b> Export </b></span>
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <table id="mangeUser" className="table table-hover dataTable js-exportable ">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Admin Name</th>
                                        <th style={{ textAlign: "center" }}>Name</th>
                                        <th style={{ textAlign: "center" }}>Scrip Name</th>
                                        <th style={{ textAlign: "center" }}>Entry Price</th>
                                        <th style={{ textAlign: "center" }}>Category</th>
                                        <th style={{ textAlign: "center" }}>Signal Type</th>
                                        <th style={{ textAlign: "center" }}> Publish Date </th>
                                        <th style={{ textAlign: "center" }}>Signal Status</th>

                                        <th style={{ textAlign: "center" }}>Status</th>
                                        <th style={{ textAlign: "center" }}>Status Action</th>
                                        <th style={{ textAlign: "center", width: '20%' }}>Action</th>

                                    </tr>
                                </thead>
                                {/* <tfoot>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Admin Name</th>
                                        <th style={{ textAlign: "center" }}>Name</th>
                                        <th style={{ textAlign: "center" }}>Scrip Name</th>
                                        <th style={{ textAlign: "center" }}>Entry Price</th>
                                        <th style={{ textAlign: "center" }}>Category</th>
                                        <th style={{ textAlign: "center" }}>Signal Type</th>
                                        <th style={{ textAlign: "center" }}>Publish Date </th>
                                        <th style={{ textAlign: "center" }}>Signal Status</th>

                                        <th style={{ textAlign: "center" }}>Status</th>
                                        <th style={{ textAlign: "center" }}>Status Action</th>
                                        <th style={{ textAlign: "center", width: '20%' }}>Action</th>
                                    </tr>
                                </tfoot> */}
                                <tbody>
                                    {
                                        signalList && signalList.map((item, index) => {
                                            return (
                                                <tr id={"signalId" + item._id} >
                                                    <td style={{ textAlign: "center" }}>
                                                        {index + 1}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.userName}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.name}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.symbolName}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.entry_price}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.category}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.type == 1 ? "Free" : "Premium"}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.publish_date}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.signal_status == 1 ? "OPEN" : "CLOSE"}
                                                    </td>

                                                    <td id={"signalStatus" + item._id} style={{ textAlign: "center" }}>
                                                        {renderStatusUiText(item)}
                                                    </td>
                                                    <td id={"signalStatus" + item._id} style={{ textAlign: "center" }}>
                                                        <Tooltip title="Change Status">
                                                            <IconButton onClick={() => { changeStatus(item._id, item, index) }}>
                                                                <FeatherIcon icon="repeat" width="18" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>

                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={() => { editSignal(item) }}>
                                                                <FeatherIcon icon="edit" width="18" />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => { deleteSignalIdWise(item._id) }}>
                                                                <FeatherIcon icon="trash-2" width="18" />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title="View">
                                                            <IconButton onClick={() => viewSignal(item)}>
                                                                <FeatherIcon icon="eye" width="18" /> {/* Replace with your desired view icon */}
                                                            </IconButton>
                                                        </Tooltip>

                                                        {item.signal_status === 1 && (
                                                            <div>
                                                                <Tooltip title="Manage CTA">
                                                                    <IconButton onClick={() => { handleShowModal(item) }}>
                                                                        <FeatherIcon icon="settings" width="18" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Move Signal to Close">
                                                                    <IconButton onClick={() => { handleShowCloseModel(item) }}>
                                                                        <FeatherIcon icon="move" width="18" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignalList;