import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getSubAdminById, getSignalData } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';


export default function ViewSubAdmin() {
    let { id } = useParams();
    const [adminDetails, setAdminDetails] = useState(null)
    const [bgImage, setBgImage] = useState(null);
    const [icon, setIcon] = useState(null);
    const [schematic, setSchematic] = useState(null);
    const [sectionList, setSectionList] = useState([]);


    useEffect(() => {
        if (id != undefined) {
            getSubAdminById({ "sub_admin_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setAdminDetails(res.data)
                    setSectionList(res.data.rights)


                }
            });

        }
    }, [id])

    if (adminDetails == null) return <> </>
    return (

        <><div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid'>
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>


                        <h5 className="mt-0 header-title">View SubAdmin</h5>

                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#home2" role="tab">Basic Details</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#profile3" role="tab">Admin Permissions</a>
                            </li>

                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane active p-3" id="home2" role="tabpanel">
                                <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                {adminDetails.basket_icon != null ?
                                                    <>

                                                        <div className="member-img">
                                                            <img src={adminDetails.basket_icon_url} className="rounded-circle" alt="profile-image" style={{ width: 200, height: 200 }} />
                                                        </div>
                                                    </> : <>
                                                        <div>Not Uploaded</div>
                                                    </>
                                                }
                                            </div>


                                            <div className='col-md-9'>
                                                <div className="form-group">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label className="form-label">Name</label><br />
                                                            <span className="font-15">{adminDetails.name}</span>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <label className="form-label">Email</label><br />
                                                            <span className="font-15">{adminDetails.email}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label className="form-label">Phone Number</label><br />
                                                            <span className="font-15">{adminDetails.country_code} {adminDetails.phone}</span>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <label className="form-label">Status</label><br />
                                                            <span className="font-15">{adminDetails.is_active == 0 ? "🔴 Inactive" : "🟢 Active"}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="tab-pane p-3" id="profile3" role="tabpanel">
                                <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                    {sectionList.length > 0 ? (
                                        <>
                                            <div id="accordion">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <label className="form-label" htmlFor="validationTooltip04">
                                                                <strong>Module</strong>
                                                            </label>
                                                        </div>
                                                        <div className="col-md">
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                <strong>Listing</strong>
                                                            </label>
                                                        </div>
                                                        <div className="col-md">
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                <strong>Add</strong>
                                                            </label>
                                                        </div>
                                                        <div className="col-md">
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                <strong>Update</strong>
                                                            </label>
                                                        </div>
                                                        <div className="col-md">
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                <strong>Delete</strong>
                                                            </label>
                                                        </div>
                                                        <div className="col-md">
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                <strong>Change Status</strong>
                                                            </label>
                                                        </div>
                                                        <div className="col-md">
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                <strong>View</strong>
                                                            </label>
                                                        </div>
                                                        <div className="col-md">
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                <strong>Import Sheet</strong>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {sectionList?.map((item, index) => (
                                                    <div key={index} className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <label className="form-label" htmlFor="validationTooltip04">
                                                                    <strong>{item.module}</strong>
                                                                </label>
                                                            </div>
                                                            <div className="col-md">
                                                                {item.is_list ? (
                                                                    <span style={{ color: 'green', fontSize: '1.2em' }}>✅</span>
                                                                ) : (
                                                                    <span>-</span>
                                                                )}
                                                            </div>
                                                            <div className="col-md">
                                                                {item.is_create ? (
                                                                    <span style={{ color: 'green', fontSize: '1.2em' }}>✅</span>
                                                                ) : (
                                                                    <span>-</span>
                                                                )}
                                                            </div>

                                                            <div className="col-md">
                                                                {item.is_edit ? (
                                                                    <span style={{ color: 'blue', fontSize: '1.2em' }}>✅</span>
                                                                ) : (
                                                                    <span>-</span>
                                                                )}
                                                            </div>

                                                            <div className="col-md">
                                                                {item.is_delete ? (
                                                                    <span style={{ color: 'red', fontSize: '1.2em' }}>✅</span>
                                                                ) : (
                                                                    <span>-</span>
                                                                )}
                                                            </div>

                                                            <div className="col-md">
                                                                {item.is_change_status ? (
                                                                    <span style={{ color: 'red', fontSize: '1.2em' }}>✅</span>
                                                                ) : (
                                                                    <span>-</span>
                                                                )}
                                                            </div>

                                                            <div className="col-md">
                                                                {item.is_view ? (
                                                                    <span style={{ color: 'red', fontSize: '1.2em' }}>✅</span>
                                                                ) : (
                                                                    <span>-</span>
                                                                )}
                                                            </div>
                                                            <div className="col-md">
                                                                {item.is_import_sheet ? (
                                                                    <span style={{ color: 'red', fontSize: '1.2em' }}>✅</span>
                                                                ) : (
                                                                    <span>-</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    ) : (
                                        <center>
                                            Not added.
                                        </center>
                                    )}
                                </div>
                            </div>

                        </div>







                    </div></div></div></div>

        </>
    )
}