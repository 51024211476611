import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getWebsiteDetails, editWebsite, getSubAdminById } from '../../api/apiHandler';
import { uploadImageS3 } from '../../common/Common';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// uploadImageS3

export default function EditWebsiteSettings() {

    const [edit, setEdit] = useState(null)

    const [ctaUrl1, setCTAUrl1] = useState('');
    const [ctaUrl2, setCTAUrl2] = useState('');
    const [images, setImages] = useState(new Array(20).fill(null));

    const [uploadedUrls, setUploadedUrls] = useState(new Array(20).fill(''));

    const [fetchedImages, setFetchedImages] = useState([]);

    console.log(uploadedUrls);
    const handleImageChange = async (e, index) => {
        const file = e.target.files[0];
        const url = await uploadImageS3({ dir_name: 'chart_image', file });
        const newUrl = `https://stockwiz-dev.s3.ap-south-1.amazonaws.com/chart_image/${url}`;
        setUploadedUrls((prevUrls) => {
            prevUrls[index] = newUrl;
            return [...prevUrls];
        });
    };

    useEffect(() => {
        if (localStorage.getItem("Arole") == "S") {
            setEdit(1)
            getAPIData();
        }
        else {
            getSubAdminById({ "sub_admin_id": localStorage.getItem('Aid') }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setEdit(res.data.permissions.filter(module => module.class_name === 'website' && module.is_edit === 1).length > 0
                    );
                }
            });
        }

    }, [])


    function getAPIData() {
        getWebsiteDetails({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setUploadedUrls(response.data.past_perfomance_images);
                setCTAUrl1(response.data.cta_url_1)
                setCTAUrl2(response.data.cta_url_2)
            }
        });
    }

    console.log(uploadedUrls, "============");

    const validationSchema = Yup.object().shape({
        // name: Yup.string().required("Please enter tag").max(128, "Tag must be at most 128 character"),
        // url: Yup.string().required('URL is required').nullable(),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate("/website")
    }

    const onSubmit = (data) => {
        if (edit == 1) {
            editWebsite({ "cta_url_2": ctaUrl2.trimStart().trimEnd(), "cta_url_1": ctaUrl1.trimStart().trimEnd(), "past_perfomance_images": uploadedUrls }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setTimeout(() => {
                        navigate("/website")
                    }, 2000)
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }

    }

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Website Settings Details</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >


                                <div className="form-group col-md-6">
                                    <label className='font-weight-bold'>CTA Link 1 - 1 Year Link </label>
                                    <div>
                                        <input type="text" className="form-control" placeholder="Please enter URL" defaultValue={ctaUrl1} onChange={(e) => setCTAUrl1(e.target.value)} required onInvalid={(e) => e.target.setCustomValidity('Please enter a valid URL')}
                                            onInput={(e) => e.target.setCustomValidity('')} />

                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className='font-weight-bold'> CTA Link 2 - Lifetime Link </label>
                                    <div>
                                        <input type="text" className="form-control" placeholder="Please enter URL" defaultValue={ctaUrl2} onChange={(e) => setCTAUrl2(e.target.value)} required onInvalid={(e) => e.target.setCustomValidity('Please enter a valid URL')}
                                            onInput={(e) => e.target.setCustomValidity('')} />

                                    </div>
                                </div>
                                <div className='form-group col-md-12'>

                                    <label>Upload Past Perfomance Images [Max 20 images] : </label>

                                    <div className="row">

                                        {uploadedUrls.map((url, index) => (
                                            <div key={index} className="col-md mb-2">
                                                <div
                                                    style={{
                                                        border: '1px solid #ccc',
                                                        padding: '10px',
                                                        width: '200px',
                                                        height: '200px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'relative',
                                                        cursor: 'pointer',
                                                        flexDirection: 'column',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <label style={{
                                                        position: 'absolute',
                                                        top: '5px',
                                                        left: '5px',
                                                        background: 'rgba(255, 255, 255, 0.7)',
                                                        padding: '2px 5px',
                                                        borderRadius: '3px',
                                                        color: 'blueviolet', // Change this to your desired color
                                                        fontWeight: 'bold',
                                                    }}>
                                                        {index + 1}
                                                    </label>
                                                    {url ? (
                                                        <>
                                                            <img
                                                                src={url}
                                                                alt="preview"
                                                                style={{ width: '100%', height: '100%' }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <span style={{ fontSize: '24px', fontWeight: 'bold' }}>+</span>
                                                    )}
                                                    <input
                                                        type="file"
                                                        className="custom-file-input"
                                                        id={`inputGroupFile${index + 1}`}
                                                        aria-describedby="inputGroupFileAddon01"
                                                        onChange={(e) => handleImageChange(e, index)}
                                                        style={{
                                                            opacity: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                    </div>

                                </div>

                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}