import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addCategory } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { moduleOptions } from '../../globals/constant';
import Select from 'react-select';



export default function AddCategory() {

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter category").max(128, "Category must be at most 128 character"),
        // type: Yup.string().required('Category type is required').nullable(),
        module: Yup.array().required("Please select modules"),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const [selectedModule, setSelectedModule] = useState(null)

    const navigate = useNavigate();

    function redirect() {
        navigate("/category")
    }

    const onSubmit = (data) => {

        addCategory({ "name": data.name.trimStart().trimEnd(), "type": data.type, "modules": selectedModule }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/category")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-4 col-md-4 col-sm-4'>
                        <h5>Add Category</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="form-group">
                                    <label> Module </label>
                                    <div>

                                        <Controller
                                            name="module"
                                            control={control}
                                            rules={{ required: 'Please select module' }}
                                            render={({ field }) => (
                                                <Select
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    options={moduleOptions}
                                                    value={field.value}
                                                    isMulti={true}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption);
                                                        setSelectedModule(selectedOption);
                                                    }}
                                                    placeholder="Select Modules"
                                                    isSearchable={true}
                                                />
                                            )}
                                        />
                                        <div className="invalid-feedback">{errors.module?.message}</div>

                                    </div>

                                </div>
                                <div className="form-group">
                                    <label> Name </label>
                                    <div>
                                        <input type="text" {...register('name')} className="form-control" placeholder="Please enter category" />
                                        <div className="invalid-feedback">{errors.name?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label> Type </label>
                                    <div>
                                        <input type="radio" id="age1" name="stype1" value="1"  {...register('type')} />&nbsp;Investing
                                        &nbsp;
                                        <input type="radio" id="age2" name="stype1" value="2"  {...register('type')} />&nbsp;Trading

                                        <div className="invalid-feedback">{errors.type?.message}</div>


                                    </div>
                                </div>
                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Add
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
