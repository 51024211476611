import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { editUser, getStrategyById, getTags , editStrategy } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Select from 'react-select';
import S3 from 'react-aws-s3';


const config = {
    bucketName: process.env.REACT_APP_S3_NAME,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    s3Url: process.env.REACT_APP_S3URL,
    dirName: "chart_image",
};
export default function EditStrategy() {

    let { id } = useParams();
    const [strategyDetails, setStrategyDetails] = useState(null)
    const [tagList, setTagList] = useState(null);
    const [selectedTag, setSelectedTag] = useState(null);
    const [riskType, setRiskType] = useState(null);
    const [radioType, setRadioType] = useState(null);
    const [radioType1, setRadioType1] = useState(null);
    const [radioType2, setRadioType2] = useState(null);
    const [bgImage, setBgImage] = useState(null);
    const [icon, setIcon] = useState(null);
    const [category, setCtaegory] = useState(null);
    const [schematic , setSchematic] = useState(null);
    const [category1, setCategory1] = useState(null);
    const [category2, setCategory2] = useState(null);
    const [tradeDirection, setTradeDirection] = useState(0);

    const categories = [
        { label: 'Low', value: '1' },
        { label: 'Medium', value: '2' },
        { label: 'High', value: '3' },
    ]



    useEffect(() => {

        if (id != undefined) {
            getStrategyById({ "strategy_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    getTags({}).then((response) => {
                        response = JSON.parse(response)
                        if (response.code == 200) {
                            setTagList(response.data)
                            setStrategyDetails(res.data)
                            const selectedStr = response.data.filter((option) =>
                                res.data.tags.includes(option.value)
                            );
                            setSelectedTag(selectedStr)
                            const foundObject = categories.find((type) => type.value == res.data.type);
                            setRiskType(foundObject)
                            setRadioType(res.data.type)

                            setRadioType1(res.data.type == '1')
                            setRadioType2(res.data.type == '2')
                            setBgImage(res.data.bg_image)
                            setIcon(res.data.icon)
                            setSchematic(res.data.schematic)
                            setCtaegory(res.data.category)
                            setCategory1(res.data.category == '1')
                            setCategory2(res.data.category == '2')
                            setTradeDirection(res.data.trade_direction)
                        }
                    })
                }
            });
        }

    }, [id])
    const handleTagsChange = (selectedOption) => {
        setSelectedTag(selectedOption);
    }
    const handleRiskTypeChange = (selectedOption) => {

        setRiskType(selectedOption);
    }
    const handleRadioChange = (event) => {
        setRadioType(event.target.value);
        setRadioType1(event.target.value == '1');
        setRadioType2(event.target.value == '2')
    };
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter strategy").max(128, "Strategy must be at most 128 character"),
        title: Yup.string().required("Please enter title").max(128, "Ttile must be at most 128 character"),
        subtitle: Yup.string().required("Please enter subtitle").max(128, "Subtitle must be at most 128 character"),
        description: Yup.string().required("Please enter description").max(155, "Description must be at most 155 character"),
        // stype: Yup.string().required('Strategy type is required').nullable(),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate("/user")
    }
    const handleFileInput = (e) => {
        const files = e.target.files[0];
        var newfile = new Date().getTime() + files.name
        config.dirName = 'bg_image'
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(files, newfile).then(data => {
            if (data.status === 204) {
                setBgImage(data.location.split("/")[4])
                // setImageErr('')

            } else {
                console.log('obj err')
            }
        });
    }
    const handleIconInput = (e) => {
        const files = e.target.files[0];
        var newfile = new Date().getTime() + files.name
        config.dirName = 'icon'
        const ReactS3Client =  new S3(config);
        console.log(files);
        ReactS3Client.uploadFile(files, newfile).then(data => {
            if (data.status === 204) {
                setIcon(data.location.split("/")[4])
                // setImageErr('')

            } else {
                console.log('obj err')
            }
        });
    }
    const handleSchematicInput = (e) => {
        const files = e.target.files[0];
        var newfile = new Date().getTime() + files.name
        config.dirName = 'schematic'
        const ReactS3Client =  new S3(config);
        ReactS3Client.uploadFile(files, newfile).then(data => {
            if (data.status === 204) {
                setSchematic(data.location.split("/")[4])
                // setImageErr('')

            } else {
                console.log('obj err')
            }
        });
    }
   
    const handleCategoryChange = (event) => {
        setCtaegory(event.target.value);
        setCategory1(event.target.value == '1');
        setCategory2(event.target.value == '2')
    };
    const onSubmit = (data) => {

        editStrategy({ "strategy_id": id, "name": data.name.trimStart().trimEnd(), "title": data.title.trimStart().trimEnd(), "subtitle": data.subtitle.trimStart().trimEnd(), "description": data.description.trimStart().trimEnd(), "creator": data.creator, "risk_type": riskType, "tag": selectedTag, "type": parseInt(radioType), "bg_image": bgImage, "icon": icon , "category" : category , "schematic" : schematic , "trade_direction" : tradeDirection , "gradient1" : data.gradient1.trimStart().trimEnd() , "gradient2" : data.gradient2.trimStart().trimEnd()}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/strategy")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }
    const imageUrl = bgImage != null ? `${process.env.REACT_APP_S3URL}/bg_image/${bgImage}` : 'https://www.islgroups.com/images/3.jpg';
    const icon_img = icon != null ? `${process.env.REACT_APP_S3URL}/icon/${icon}`  : 'https://www.islgroups.com/images/3.jpg';
    const schematic_img = schematic != null ? `${process.env.REACT_APP_S3URL}/schematic/${schematic}` : 'https://www.islgroups.com/images/3.jpg';


    if (strategyDetails == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Edit Strategy Details</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >

                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className='ml-3'> Name</label>
                                            <div className='ml-3'>
                                                <input type="text" {...register('name')} className="form-control" defaultValue={strategyDetails.name} placeholder="Please Enter Name" />
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='ml-3'> Title</label>
                                            <div className='ml-3'>
                                                <input type="text" {...register('title')} className="form-control" defaultValue={strategyDetails.title} placeholder="Please Enter Title" />
                                                <div className="invalid-feedback">{errors.title?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className='ml-3'> SubTitle</label>
                                            <div className='ml-3'>
                                                <input type="text" {...register('subtitle')} className="form-control" defaultValue={strategyDetails.subtitle} placeholder="Please Enter Subtitle" />
                                                <div className="invalid-feedback">{errors.subtitle?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='ml-3'> Description </label>
                                            <div className='ml-3'>
                                                <textarea placeholder="Please enter description" className="form-control" defaultValue={strategyDetails.description} rows={4} cols={40} {...register('description')} />
                                                <div className="invalid-feedback">{errors.description?.message}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label  className='ml-3'> Gradient Color 1 </label>
                                            <div className='ml-3'>
                                                <input type="text" {...register('gradient1')} className="form-control" placeholder="Please enter gradient color 1" defaultValue={strategyDetails.gradient1}/>
                                                <div className="invalid-feedback">{errors.gradient1?.message}</div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <label  className='ml-3'>  Gradient Color 2 </label>
                                            <div  className='ml-3'>
                                                <input type="text" {...register('gradient2')} className="form-control" placeholder="Please enter gradient color 2" defaultValue={strategyDetails.gradient2}/>
                                                <div className="invalid-feedback">{errors.gradient2?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>

                                            <label className='ml-3'> Strategy Type</label>
                                            <div className='ml-3'>
                                                <input
                                                    type="radio"
                                                    name="stype"
                                                    onClick={handleRadioChange}
                                                    checked={radioType1}
                                                    value="1"
                                                />&nbsp;Free
                                                &nbsp;
                                                <input
                                                    type="radio"
                                                    name="stype1"
                                                    onClick={handleRadioChange}
                                                    checked={radioType2}
                                                    value="2"
                                                />&nbsp;Premium
                                                <div className="invalid-feedback">{errors.stype?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>

                                            <label className='ml-3'> Strategy Category</label>
                                            <div className='ml-3'>
                                                <input
                                                    type="radio"
                                                    name="stype2"
                                                    onClick={handleCategoryChange}
                                                    checked={category == '1'}
                                                    value="1"
                                                />&nbsp;Investing
                                                &nbsp;
                                                <input
                                                    type="radio"
                                                    name="stype3"
                                                    onClick={handleCategoryChange}
                                                    checked={category == '2'}
                                                    value="2"
                                                />&nbsp;Trading
                                                <div className="invalid-feedback">{errors.category?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>

                                            <label className='ml-3'> Risk Type</label>
                                            <div className='ml-3'>
                                                <Select {...register('trade_category')} value={riskType} required options={categories} menuPortalTarget={document.body} onChange={handleRiskTypeChange}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>

                                            <label className='ml-3'> Tags</label>
                                            <div className='ml-3'>
                                                <Select
                                                    value={selectedTag}
                                                    isMulti
                                                    name="colors"
                                                    options={tagList}
                                                    onChange={handleTagsChange}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className='ml-3'> Background Image </label>
                                            <div className="admin-profile">
                                                <label className="-label" for="file">
                                                    <i className="fas fa-camera"></i>
                                                    <span style={{ marginLeft: "5px" }}>Change Image</span>
                                                </label>
                                                <input id="file" type="file" onChange={handleFileInput} />
                                                <img src={imageUrl} id="output" width="200" />
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <label className='ml-3'> Icon Image </label>
                                            <div className="admin-profile">
                                                <label className="-label" for="file1">
                                                    <i className="fas fa-camera"></i>
                                                    <span style={{ marginLeft: "5px" }}>Change Image</span>
                                                </label>
                                                <input id="file1"  type="file" onChange={handleIconInput} />
                                                <img src={icon_img} id="output1" width="200" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                       
                                        <div className='col-md-6'>
                                            <label className='ml-3'> Schematic Image </label>
                                            <div className="admin-profile">
                                                <label className="-label" for="file3">
                                                    <i className="fas fa-camera"></i>
                                                    <span style={{ marginLeft: "5px" }}>Change Image</span>
                                                </label>
                                                <input id="file3" type="file" onChange={handleSchematicInput} />
                                                <img src={schematic_img} id="output2" width="200" />
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <label> Trade Direction </label>
                                            <div>
                                                <input type="radio" id="age1" name="trade_type" value="1" onClick={(e) => setTradeDirection(e.target.value)} checked={tradeDirection == '1'} {...register('trade_type')} />&nbsp;LONG
                                                &nbsp;
                                                <input type="radio" id="age2" name="trade_type" value="2" onClick={(e) => setTradeDirection(e.target.value)} checked={tradeDirection == '2'} {...register('trade_type')} />&nbsp;SHORT
                                                <div className="invalid-feedback">{errors.stype?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}