/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getPerfomanceAnalyticsData } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { segmentOptions, typeOptions, tradingDirectionOptions } from '../../globals/constant';



export default function PerfomanceAnalytics() {
    const navigate = useNavigate();

    const [view, setView] = useState(0);

    const [activeTab, setActiveTab] = useState(0);
    const [segmentData, setSegmentData] = useState(null);
    const [typeData, setTypeData] = useState(null);
    const [tradeData, setTradeData] = useState(null);

    useEffect(() => {

        getPerfomanceAnalyticsData({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setSegmentData(response.data.segment)
                setTradeData(response.data.trade_direction)
                setTypeData(response.data.type)
            }
        });
    }, []);

    function viewAnalytics(key, value, category) {
        if (category === undefined) {
            navigate(`/view-analytics/${key}/${value}`);
        }
        else {
            navigate(`/view-analytics/${key}/${value}/${category}`);
        }
    }
    return (

        <><div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid'>
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>View Perfomance Analytics</h5>
                        <hr></hr>
                        <ul className="nav nav-pills nav-justified" role="tablist">
                            <li className="nav-item waves-effect waves-light">
                                <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Segment Wise</a>
                            </li>
                            <li className="nav-item waves-effect waves-light">
                                <a className="nav-link" data-toggle="tab" href="#profile-1" role="tab" >Trade Direction Wise</a>
                            </li>
                            <li className="nav-item waves-effect waves-light">
                                <a className="nav-link" data-toggle="tab" href="#messages-1" role="tab" >Signal Category Wise</a>
                            </li>
                            {/* <li className="nav-item waves-effect waves-light">
                                <a className="nav-link" data-toggle="tab" href="#settings-1" role="tab">Settings</a>
                            </li> */}
                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane active p-3" id="home-1" role="tabpanel">
                                <div id="accordion">
                                    {segmentData && (
                                        segmentData.map((tradeItem, index) => (
                                            <div className="card mb-1 mt-2" key={`segment${index}`}>
                                                <div className="card-header p-3" id={`headingsegment${index}`}>
                                                    <h6 className="m-0">
                                                        <a
                                                            href={`#collapsesegment${index}`}
                                                            className={`text-dark ${`segment${index}` !== 'One' ? 'collapsed' : ''}`}
                                                            data-toggle="collapse"
                                                            aria-expanded={`segment${index}` === 'One'}
                                                            aria-controls={`collapsesegment${index}`}
                                                        >
                                                            Segment Name #{tradeItem.label}
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div
                                                    id={`collapsesegment${index}`}
                                                    className={`collapse ${`segment${index}` === 'One' ? 'show' : ''}`}
                                                    aria-labelledby={`headingsegment${index}`}
                                                    data-parent="#accordion"
                                                >
                                                    <div className="card-body">
                                                        <div className="form-group">
                                                            <div className='row'>

                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Total Trades</strong></label><br />
                                                                    <span class="font-15">{tradeItem.total_signals}</span>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong> Closed Trades</strong></label><br />
                                                                    <span class="font-15">{tradeItem.closed_signals}</span>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Open Trades</strong></label><br />
                                                                    <span class="font-15">{tradeItem.open_signals}</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='form-group'>
                                                            <div className='row'>

                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Trades in Profit</strong></label><br />
                                                                    <span class="font-15">{tradeItem.profit_signals}</span>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Trades in Loss</strong></label><br />
                                                                    <span class="font-15">{tradeItem.loss_signals}</span>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Win Rate</strong></label><br />
                                                                    <span class="font-15">{tradeItem.win_rate}%</span>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        {tradeItem.total_signals > 0 ? <> <button type="button" onClick={() => viewAnalytics('segment', tradeItem.label)} className="btn btn-primary waves-effect waves-light m-2">
                                                            View Trades Details
                                                        </button></> : <></>}

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        ))}
                                </div>
                            </div>
                            <div className="tab-pane p-3" id="profile-1" role="tabpanel">
                                <div id="accordion">
                                    {tradeData && (
                                        tradeData.map((tradeItem, index) => (
                                            <div className="card mb-1 mt-2" key={`trade${index}`}>
                                                <div className="card-header p-3" id={`headingtrade${index}`}>
                                                    <h6 className="m-0">
                                                        <a
                                                            href={`#collapsetrade${index}`}
                                                            className={`text-dark ${`trade${index}` !== 'One' ? 'collapsed' : ''}`}
                                                            data-toggle="collapse"
                                                            aria-expanded={`trade${index}` === 'One'}
                                                            aria-controls={`collapsetrade${index}`}
                                                        >
                                                            Trade Direction #{tradeItem.label}
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div
                                                    id={`collapsetrade${index}`}
                                                    className={`collapse ${`trade${index}` === 'One' ? 'show' : ''}`}
                                                    aria-labelledby={`headingtrade${index}`}
                                                    data-parent="#accordion"
                                                >
                                                    <div className="card-body">
                                                        <div className="form-group">
                                                            <div className='row'>

                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Total Trades</strong></label><br />
                                                                    <span class="font-15">{tradeItem.total_signals}</span>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong> Closed Trades</strong></label><br />
                                                                    <span class="font-15">{tradeItem.closed_signals}</span>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Open Trades</strong></label><br />
                                                                    <span class="font-15">{tradeItem.open_signals}</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='form-group'>
                                                            <div className='row'>

                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Trades in Profit</strong></label><br />
                                                                    <span class="font-15">{tradeItem.profit_signals}</span>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Trades in Loss</strong></label><br />
                                                                    <span class="font-15">{tradeItem.loss_signals}</span>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Win Rate</strong></label><br />
                                                                    <span class="font-15">{tradeItem.win_rate}%</span>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        {tradeItem.total_signals > 0 ? <> <button type="button" onClick={() => viewAnalytics('trade_direction', tradeItem.label == "LONG" ? 1 : 2)} className="btn btn-primary waves-effect waves-light m-2">
                                                            View Trades Details
                                                        </button></> : <></>}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        ))}
                                </div>
                            </div>
                            <div className="tab-pane p-3" id="messages-1" role="tabpanel">
                                <div id="accordion">
                                    {typeData && (
                                        typeData.map((tradeItem, index) => (
                                            <div className="card mb-1 mt-2" key={`type${index}`}>
                                                <div className="card-header p-3" id={`headingtype${index}`}>
                                                    <h6 className="m-0">
                                                        <a
                                                            href={`#collapsetype${index}`}
                                                            className={`text-dark ${`type${index}` !== 'One' ? 'collapsed' : ''}`}
                                                            data-toggle="collapse"
                                                            aria-expanded={`type${index}` === 'One'}
                                                            aria-controls={`collapsetype${index}`}
                                                        >
                                                            Category #{tradeItem.label}
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div
                                                    id={`collapsetype${index}`}
                                                    className={`collapse ${`type${index}` === 'One' ? 'show' : ''}`}
                                                    aria-labelledby={`headingtype${index}`}
                                                    data-parent="#accordion"
                                                >
                                                    <div className="card-body">
                                                        <div className="form-group">
                                                            <div className='row'>

                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Total Trades</strong></label><br />
                                                                    <span class="font-15">{tradeItem.total_signals}</span>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong> Closed Trades</strong></label><br />
                                                                    <span class="font-15">{tradeItem.closed_signals}</span>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Open Trades</strong></label><br />
                                                                    <span class="font-15">{tradeItem.open_signals}</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='form-group'>
                                                            <div className='row'>

                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Trades in Profit</strong></label><br />
                                                                    <span class="font-15">{tradeItem.profit_signals}</span>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Trades in Loss</strong></label><br />
                                                                    <span class="font-15">{tradeItem.loss_signals}</span>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label class="form-label"> <strong>Win Rate</strong></label><br />
                                                                    <span class="font-15">{tradeItem.win_rate}%</span>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        {tradeItem.total_signals > 0 && view == 0 ? <> <button type="button" onClick={() => setView(1)} className="btn btn-primary waves-effect waves-light m-2">
                                                            View More
                                                        </button></> : <></>}
                                                        {view == 1 ? <><div className='form-group ml-2 mr-2'>
                                                            <h4>Trade Category : </h4>
                                                            <div>
                                                                <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                                                                    {tradeItem.categories.map((tab) => (
                                                                        <li key={tab.label} className="nav-item">
                                                                            <a
                                                                                className={`nav-link ${activeTab === tab.label ? 'active' : ''}`}
                                                                                data-toggle="tab"
                                                                                href={`#${tab.label}`}
                                                                                role="tab"
                                                                                onClick={() => setActiveTab(tab.label)}
                                                                            >
                                                                                {tab.category_name}
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>

                                                                <div className="tab-content">
                                                                    {tradeItem.categories.map((tab) => (
                                                                        <div key={tab.label} className={`tab-pane p-3 ${activeTab === tab.label ? 'active' : ''}`} id={tab.label} role="tabpanel">
                                                                            <div className="card-body">
                                                                                <div className="form-group">
                                                                                    <div className='row'>

                                                                                        <div className='col-md-4'>
                                                                                            <label class="form-label"> <strong>Total Trades</strong></label><br />
                                                                                            <span class="font-15">{tab.total_signals}</span>
                                                                                        </div>
                                                                                        <div className='col-md-4'>
                                                                                            <label class="form-label"> <strong> Closed Trades</strong></label><br />
                                                                                            <span class="font-15">{tab.closed_signals}</span>
                                                                                        </div>
                                                                                        <div className='col-md-4'>
                                                                                            <label class="form-label"> <strong>Open Trades</strong></label><br />
                                                                                            <span class="font-15">{tab.open_signals}</span>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className='form-group'>
                                                                                    <div className='row'>

                                                                                        <div className='col-md-4'>
                                                                                            <label class="form-label"> <strong>Trades in Profit</strong></label><br />
                                                                                            <span class="font-15">{tab.profit_signals}</span>
                                                                                        </div>
                                                                                        <div className='col-md-4'>
                                                                                            <label class="form-label"> <strong>Trades in Loss</strong></label><br />
                                                                                            <span class="font-15">{tab.loss_signals}</span>
                                                                                        </div>
                                                                                        <div className='col-md-4'>
                                                                                            <label class="form-label"> <strong>Win Rate</strong></label><br />
                                                                                            <span class="font-15">{tab.win_rate}%</span>
                                                                                        </div>

                                                                                    </div>

                                                                                </div>
                                                                                {tab.total_signals > 0 ? <> <button type="button" onClick={() => viewAnalytics('trade_category_id', tab.category_id, tab.category_name)} className="btn btn-primary waves-effect waves-light m-2">
                                                                                    View Trades Details
                                                                                </button></> : <></>}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div> </> : <></>}

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        ))}
                                </div>
                            </div>

                        </div>
                    </div></div></div></div>

            {/* popup model */}
            <div id="show_image_popup">
                <div class="close-btn-area">

                    <button id="close-btn">X</button>
                </div>
                <div id="image-show-area">
                    <img id="large-image" src="" alt="">
                    </img></div>
            </div></>
    )
}