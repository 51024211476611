/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getWatchlistById, getSignalData } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export default function ViewWatchlist() {
    let { id } = useParams();
    const [watchlistDetails, setWatchlistDetails] = useState(null)
    const [bgImage, setBgImage] = useState(null);
    const [icon, setIcon] = useState(null);
    const [schematic, setSchematic] = useState(null);
    const [stocks, setStocks] = useState([]);


    useEffect(() => {
        if (id != undefined) {
            getWatchlistById({ "watchlist_id": id, "is_view": 1 }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setWatchlistDetails(res.data)
                    setSchematic(res.data.schematic)
                    if (res.data.stocks.length > 0) {
                        setStocks(res.data.stocks)
                    }
                }
            });

        }
    }, [id])

    if (watchlistDetails == null) return <> </>
    return (

        <><div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid'>
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>


                        <h5 className="mt-0 header-title">View Watchlist</h5>

                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#home2" role="tab">Basic Details</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#profile3" role="tab">Stocks</a>
                            </li>

                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane active p-3" id="home2" role="tabpanel">
                                <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>WatchList Icon : </label>
                                                {watchlistDetails.watchlist_icon_url != null ?
                                                    <>

                                                        <div className="member-img">
                                                            <img src={watchlistDetails.watchlist_icon_url} className="rounded-square img-thumbnail" alt="profile-image" style={{ width: 200, height: 200 }} />
                                                        </div>
                                                    </> : <>
                                                        <div>Not Uploaded</div>
                                                    </>
                                                }
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Background Image : </label>
                                                {watchlistDetails.bg_image_url != null ?
                                                    <>

                                                        <div className="member-img ">
                                                            <img src={watchlistDetails.bg_image_url} className="rounded-square img-thumbnail" alt="profile-image" style={{ width: 200, height: 200 }} />
                                                        </div>
                                                    </> : <>
                                                        <div>Not Uploaded</div>
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className='row mt-2'>
                                                <div className='col-md-6'>
                                                    <label class="form-label">Name</label><br />
                                                    <span class="font-15">{watchlistDetails.name}</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label class="form-label">Title</label><br />
                                                    <span class="font-15">{watchlistDetails.title}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label class="form-label">Sub Text</label><br />
                                                    <span class="font-15">{watchlistDetails.sub_text}</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label class="form-label">Status</label><br />
                                                    <span class="font-15">{watchlistDetails.is_active == 0 ? "🔴 Inactive" : "🟢 Active"}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                        <div className='col-md-6'>
                                            <label class="form-label">Trade Direction</label><br />
                                            <span class="font-15">{watchlistDetails.trade_direction == 1 ? "LONG" : "SHORT"}</span>
                                        </div>
                                        <div className='col-md-6'>
                                            <label class="form-label">Labels</label><br />
                                            <span class="font-15">{watchlistDetails.label}</span>
                                        </div>
                                    </div></div>

                                </div>
                            </div>

                            <div className="tab-pane p-3" id="profile3" role="tabpanel">
                                <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                    {stocks.length > 0 ? (
                                        <>
                                            <div id="accordion">
                                                {
                                                    stocks.map((tradeItem) => (
                                                        <div className="card mb-1 mt-2" key={tradeItem._id}>
                                                            <div className="card-header p-3" id={`heading${tradeItem._id}`}>
                                                                <h6 className="m-0">
                                                                    <a
                                                                        href={`#collapse${tradeItem._id}`}
                                                                        className={`text-dark ${tradeItem._id !== 'One' ? 'collapsed' : ''}`}
                                                                        data-toggle="collapse"
                                                                        aria-expanded={tradeItem._id === 'One'}
                                                                        aria-controls={`collapse${tradeItem._id}`}
                                                                    >
                                                                        Stock Name #{tradeItem.symbol.scrip_name}
                                                                    </a>
                                                                </h6>
                                                            </div>
                                                            <div
                                                                id={`collapse${tradeItem._id}`}
                                                                className={`collapse ${tradeItem._id === 'One' ? 'show' : ''}`}
                                                                aria-labelledby={`heading${tradeItem._id}`}
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className='form-group'>
                                                                        <div className='row'>

                                                                            <div className='col-md-3'>
                                                                                {tradeItem.chart_image_url != null ?
                                                                                    <>

                                                                                        <div className="member-img">
                                                                                            <img src={tradeItem.chart_image_url} className="rounded-circle" alt="profile-image" style={{ width: 200, height: 200 }} />
                                                                                        </div>
                                                                                    </> : <>
                                                                                        <div>Not Uploaded</div>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            <div className='col-md-9'>
                                                                                <div className="form-group">
                                                                                    <div className='row'>

                                                                                        <div className='col-md-3'>
                                                                                            <label class="form-label"> <strong>LTP</strong></label><br />
                                                                                            <span class="font-15">{tradeItem.ltp}</span>
                                                                                        </div>
                                                                                        <div className='col-md-3'>
                                                                                            <label class="form-label"> <strong> Profit(In %)</strong></label><br />
                                                                                            <span class="font-15">{tradeItem.profit_percentage}</span>
                                                                                        </div>
                                                                                        <div className='col-md-3'>
                                                                                            <label class="form-label"> <strong>Profit(In Rs.)</strong></label><br />
                                                                                            <span class="font-15">{tradeItem.profit_price}</span>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </>
                                    ) : (
                                        <center>
                                            Not added.
                                        </center>
                                    )}
                                </div>
                            </div>

                        </div>







                    </div></div></div></div>

            {/* popup model */}
            <div id="show_image_popup">
                <div class="close-btn-area">

                    <button id="close-btn">X</button>
                </div>
                <div id="image-show-area">
                    <img id="large-image" src="" alt="">
                    </img></div>
            </div></>
    )
}