import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addWatchList, getSymbols } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import S3 from 'react-aws-s3';
import Select from 'react-select';
import { symbol } from 'prop-types';
import { watchListCategoryOptions } from '../../globals/constant';
import { getStockLTP, uploadImageS3 } from '../../common/Common'


export default function AddWatchList() {

    const validationSchema = Yup.object().shape({
        // name: Yup.string().required("Please enter name").max(128, "Name must be at most 128 character"),
        // title: Yup.string().required('Please enter title').max(128, 'Title must be at most 128 characters'),
        // trade_type: Yup.string().required('Trade direction is required').nullable(),

        // sub_text: Yup.string().required('Please enter sub text').max(128, 'Sub text must be at most 128 characters'),
        // icon: Yup.mixed().required('Please upload an icon image'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, handleSubmit, formState, setValue } = useForm(formOptions);
    const { errors } = formState;

    const [symbolList, setSymbolList] = useState(null);
    const [stocks, setStocks] = useState([{
        trade_direction: 1,
         publish_date : new Intl.DateTimeFormat('en-GB', {
            dateStyle: 'short',
            timeStyle: 'medium',
        }).format(new Date(Date.now())),     
        symbol: '',
        ltp: '',
        profit: { percentage: '', price: '' },
        trade_category: '',
        chart_image: '',
        holding_period: '',
        trade_duration: ''
    }]);

    const calculatePercentage = (price, ltp) => {
        if (price && ltp) {
            return ((price - ltp) / ltp) * 100;
        }
        return null;
    };
    const navigate = useNavigate();

    function redirect() {
        navigate('/watchlist');
    }

    useEffect(() => {
        getSymbols({}).then((response) => {
            response = JSON.parse(response);
            if (response.code === 200) {
                setSymbolList(response.data);
            }
        });
    }, []);

    const onSubmit = (data) => {
        addWatchList({
            stocks: stocks.flat(),
        }).then((response) => {
            response = JSON.parse(response);
            if (response.code === 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                });
                setTimeout(() => {
                    navigate('/watchlist');
                }, 2000);
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        });
    };


    const handleAddStock = () => {
        const newStock = {
            symbol: null,
            chart_image: '',
            profit: { price: '', percentage: '' },
            profit_type: '',
            ltp: '',
            trade_category: '',
            holding_days: 0,
            trade_direction: 1,
            publish_date : new Intl.DateTimeFormat('en-GB', {
                dateStyle: 'short',
                timeStyle: 'medium',
            }).format(new Date(Date.now()))
        };
        setStocks((prevStocks) => [...prevStocks, newStock]);
    };


    const handleRemoveStock = (index) => {
        setStocks((prevStocks) => prevStocks.filter((_, i) => i !== index));
    };

    const handleProfitChange = (event, index, type) => {
        const updatedStocks = [...stocks];
        if (type === 'price') {
            const percentage = calculatePercentage(event.target.value, updatedStocks[index].ltp).toFixed(2);
            updatedStocks[index].profit = { percentage: percentage, price: event.target.value }
        } else {
            const price = (updatedStocks[index].ltp * (1 + event.target.value) / 100).toFixed(2);
            updatedStocks[index].profit = { percentage: event.target.value, price: price }
        }
        setStocks(updatedStocks);
    };

    const handleImageInput = async (e, index) => {
        const image = await uploadImageS3({ "dir_name": 'chart_image', 'file': e.target.files[0] })
        setStocks(prevInfoArray => {
            const newArray = [...prevInfoArray];
            newArray[index].chart_image = image;
            return newArray;
        })

    };
    async function handleSymbolChange(index, selectedOption) {
        try {
            const stockValue = await getStockLTP(selectedOption.value);
            if (stockValue) {
                setStocks(prevInfoArray => {
                    const newArray = [...prevInfoArray];
                    newArray[index].symbol = selectedOption;
                    newArray[index].ltp = stockValue.ltp;
                    return newArray;
                });
            } else {
                Swal.close()
                console.log("Errror");
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'LTP Not Found',
                })
            }
        } catch (error) {
            console.error("Error fetching stock value:", error);
        }
    };
    function handleTradeCategoryChange(index, selectedOption) {
        setStocks(prevInfoArray => {
            const newArray = [...prevInfoArray];
            newArray[index].trade_category = selectedOption;
            newArray[index].trade_duration = selectedOption.days;
            return newArray;
        });

    };
    function holdingDaysChange(event, index) {
        setStocks(prevInfoArray => {
            const newArray = [...prevInfoArray];
            newArray[index].holding_days = event.target.value;
            return newArray;
        });

    };

    const handleTradeDirection = (index, event) => {
        const updatedStocks = [...stocks];
        updatedStocks[index].trade_direction = event.target.value;
        setStocks(updatedStocks);
    };

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Add Watchlist</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >

                                {stocks.map((stock, index) => (
                                    <div key={index} className="form-group mt-2" style={{ border: "2px solid black", padding: "10px", position: "relative" }}>
                                        {/* <i className="fas fa-times" style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer", color: "white", backgroundColor: "red", borderRadius: "50%", padding: "5px" }} onClick={() => handleRemoveStock(index)}></i> */}

                                        <div key={stock.id}>
                                            <div className='form-group'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label> Trade Direction </label>
                                                        <div>
                                                            <input type="radio" id="age1" name="trade_type" value="1"  {...register(`stocks[${index}].trade_type`)} onClick={(event) => handleTradeDirection(index, event)} checked={stock.trade_direction == 1} />&nbsp;LONG
                                                            &nbsp;
                                                            <input type="radio" id="age2" name="trade_type" value="2" {...register(`stocks[${index}].trade_type`)} onClick={(event) => handleTradeDirection(index, event)} checked={stock.trade_direction == 2} />&nbsp;SHORT
                                                            <div className="invalid-feedback">{errors.trade_type?.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold"> Publish Date </label>
                                                        <div>
                                                            <input type="text" readOnly value={stock.publish_date} className="form-control" {...register(`stocks[${index}].publish_date`)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label> Stock Name </label>
                                                        <div>

                                                            <Select
                                                                {...register(`stocks[${index}].symbol`)}
                                                                value={stock.symbol}
                                                                onChange={(selectedOption) => handleSymbolChange(index, selectedOption)}
                                                                options={symbolList}
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                            />

                                                            <div className="invalid-feedback">{errors.stocks?.[index]?.name?.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label> LTP </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                {...register(`stocks[${index}].ltp`)}
                                                                className="form-control"
                                                                placeholder="LTP Value"
                                                                value={stock.ltp}
                                                                readOnly
                                                            />
                                                            <div className="invalid-feedback">{errors.stocks?.[index]?.name?.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="row">
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold"> Potential Gain </label>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label htmlFor="percentage">Percentage:</label>
                                                                <div>
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        placeholder="Please enter Numbers"
                                                                        onChange={(event) => handleProfitChange(event, index, 'percentage')}
                                                                        value={stock.profit.percentage}
                                                                        required
                                                                        step={0.01}
                                                                        onInvalid={(e) => {
                                                                            e.target.setCustomValidity('Please enter profit');
                                                                        }}
                                                                        onInput={(e) => e.target.setCustomValidity('')}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <span style={{ marginLeft: '-23px', marginRight: '6px' }}>OR</span>
                                                                <label htmlFor="price">Price:</label>
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    placeholder="Please enter Price"
                                                                    step={0.01}
                                                                    onChange={(event) => handleProfitChange(event, index, 'price')}
                                                                    value={stock.profit.price}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-6'>
                                                        <label> Select Trade Category </label>
                                                        <div>
                                                            <Controller
                                                                name="trade_category"
                                                                control={control}
                                                                rules={{ required: 'Please select trade category' }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        menuPortalTarget={document.body}
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        options={watchListCategoryOptions}
                                                                        value={stock.trade_category}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption);
                                                                            handleTradeCategoryChange(index, selectedOption)
                                                                        }}
                                                                        placeholder="Select Trade Category"
                                                                        isSearchable={true}
                                                                    />
                                                                )}
                                                            />

                                                            {/* <div className="invalid-feedback">{errors.label?.message}</div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label> Upload Chart Image </label>
                                                        <input type="file" className="form-control" id="inputGroupFile01" {...register(`stocks[${index}].chart_image`)} onChange={(event) => handleImageInput(event, index)} />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label> Trade Duration</label>
                                                        <input type="text" className="form-control" min={0} value={stock.trade_duration} readOnly />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label> Holding Period [Days] </label>
                                                        <input type="number" className="form-control" min={0} onChange={(event) => holdingDaysChange(event, index)} readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" className='btn btn-info' onClick={() => handleRemoveStock(index)}>
                                                Remove Stock
                                            </button>
                                        </div>
                                    </div>
                                ))}

                                <center>
                                    <button type="button" className="btn btn-info waves-effect waves-light m-2 btn-custom" onClick={handleAddStock}>
                                        Add Stock
                                    </button></center>
                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Add
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
