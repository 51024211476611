import React, { useEffect, useState, useRef } from 'react'
import { getStrategyList, getSubAdminById, getSymbolList, importSheet, removeSymbol, changeSymbolStatus } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';
import * as Alert from '../../common/Alert'
import S3 from 'react-aws-s3';
import ExcelJS from 'exceljs';
import { excelUpload } from '../../common/Common'
import { itemsPerPage } from '../../globals/constant';


function SymbolList() {

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [file, setFile] = useState(null);
    const [symbolList, setSymbolList] = useState(null)
    const [symbolRemove, setSymbolRemove] = useState(0)
    const [symbolChangeStatus, setSymbolChangeStatus] = useState(0)
    const [symbolView, setSymbolView] = useState(0)
    const [symbolAdd, setSymbolAdd] = useState(0)
    const [symbolEdit, setSymbolEdit] = useState(0)
    const [symbolImport, setSymbolImport] = useState(0)
    const fileInputRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [searchString, setSearchString] = useState(null)
    const [isFound, SetIsFound] = useState(false);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalItems) {
            setCurrentPage(page);
            // setSymbolList([])
            fetchList()
        }
    };
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Calculate total pages
    const lastPageRecords = totalItems % itemsPerPage === 0 ? itemsPerPage : totalItems % itemsPerPage;

    useEffect(() => {
        if (localStorage.getItem("Arole") == "S") {
            setSymbolChangeStatus(1)
            setSymbolRemove(1)
            setSymbolView(1)
            setSymbolAdd(1)
            setSymbolEdit(1)
            setSymbolImport(1)
            fetchList();
        }
        else {
            fetchList();
            getSubAdminById({ "sub_admin_id": localStorage.getItem('Aid') }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setSymbolEdit(res.data.permissions.filter(module => module.class_name === 'symbol' && module.is_edit === 1).length > 0
                    );
                    setSymbolView(res.data.permissions.filter(module => module.class_name === 'symbol' && module.is_view === 1).length > 0
                    );
                    setSymbolRemove(res.data.permissions.filter(module => module.class_name === 'symbol' && module.is_delete === 1).length > 0)
                    setSymbolChangeStatus(res.data.permissions.filter(module => module.class_name === 'symbol' && module.is_change_status === 1).length > 0)
                    setSymbolImport(res.data.permissions.filter(module => module.class_name === 'symbol' && module.is_import_sheet === 1).length > 0)
                }
            });

        }

    }, [])

    // useEffect(() => {
    //     console.log(symbolList, "----------USE EFFECT LIST--------------------");
    // }, [symbolList])

    function handleSearch(e) {
        setSearchString(e.target.value)
        // setSymbolList(null)
        fetchList(e.target.value)
    }
    function fetchList(searchText = null) {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
    
        getSymbolList({ "page": currentPage, "record_count": itemsPerPage, "search": searchText })
           .then((response) => {
                response = JSON.parse(response)
                if (response.code === 200 && response.data && response.data.symbol_list && response.data.symbol_list.length > 0) {
                    SetIsFound(true);
                    setTotalItems(response.data.total_count)
                    setSymbolList(response.data.symbol_list)
    
                } else {
                    SetIsFound(false)
                    setTotalItems(0)
                    setSymbolList([])  // Ensure list is empty when no data is found
                }
            })
           .catch((error) => {
                SetIsFound(false)
                console.error(error)
                Swal.fire('Error', 'Failed to fetch symbol list', 'error')
                setTotalItems(0)
                setSymbolList([])  // Ensure list is empty in case of error
            })
           .finally(() => {
                Swal.close()
            })
    }


    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                paging: false,
                searching: false,
                pageLength: itemsPerPage,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'copy',
                    className: 'btn-mute mb-2'
                }, {
                    extend: 'csv',
                    className: 'btn-mute mb-2'
                }, {
                    extend: 'print',
                    className: 'btn-mute mb-2'
                }],
                "bDestroy": true,
            }
        );
    }, [symbolList])
    const handleShowModal = () => {
        if (symbolImport == 1) {
            setShowModal(true);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const handleExtensionSubmit = async (event) => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        const file = fileInputRef.current.files[0];
        const uploadData = await excelUpload(file)
        const symbolData = uploadData
            // .filter(item => item[2] && item[3] && item[4] && item[6] && item[8] && item[5] && ['EQ', 'BE', 'BZ'].includes(item[5]?.toUpperCase()))
            .map((item, index) => {
                return (

                    {
                        // user_margin : item[2],
                        // dhan_margin : item[4],
                        // leverage : item[5],
                        truedata_symbol_id: item[12],
                        scrip_symbol: item[6],
                        scrip_name: item[7],
                        series: item[10],
                        isin: item[4],
                        exchange: item[1],
                        security_id: item[3],
                        sector: item[13],
                        sub_sector: item[14],
                        category: item[15],
                        market_cap: item[8],
                    }
                )
            })

        importSheet({ 'data': symbolData }).then((res) => {
            res = JSON.parse(res)
            Swal.close();
            if (res.code == 200) {
                setSymbolList(null)
                fetchList();
                Alert.SuccessAlert(res.message)

            } else {
                Alert.ErrorAlert(res.message)
            }
        });
        handleCloseModal()
    };
    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function deletesymbolIdWise(symbolId) {
        if (symbolRemove == 1) {
            Swal.fire({
                title: 'Are you sure you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#02C9D6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    // console.log("symbolId " + symbolId)
                    removeSymbol({ "symbol_id": symbolId }).then((response) => {
                        response = JSON.parse(response)
                        if (response.code == 200) {
                            $('#mangeUser').DataTable().row("#symbolId" + symbolId).remove().draw(false);
                            showMessage(response.message)
                        }
                    });
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    function renderStatusUiText(item) {
        return (
            item.is_active == 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }
    const ViewSymbol = (item) => {
        if (symbolView == 1) {

            navigate(`/symbol/view/${item._id}`);
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    const imagePath = `${process.env.REACT_APP_S3URL}/symbol`;
    const addSymbol = () => {
        if (symbolAdd == 1) {
            navigate(`/symbol/add`);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...xxxxxx',
                text: 'You do not have permission to access',
            })
        }
    }
    const editSymbol = (item) => {
        if (symbolEdit == 1) {
            navigate(`/symbol/edit/${item._id}`);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    // Used for change status
    function changeStatus(symbolId, item, position) {
        if (symbolChangeStatus == 1) {

            const status = (item.is_active == 1) ? "0" : "1"

            changeSymbolStatus({ "symbol_id": symbolId, "is_active": status }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    showMessage(response.message)
                    symbolList[position].is_active = status
                    const data = renderToStaticMarkup(renderStatusUiText(symbolList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                    $('#mangeUser').DataTable().row("#symbolId" + symbolId).cell("#symbolStatus" + symbolId).data(data).draw(false);

                }
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    console.log("LENGTH ::: ", symbolList?.length);
    console.log("FOund ::: ", isFound);
    // if (symbolList === null) return <> </>
    // if(isSearchEnabled == true) return <> 
    //  <div className='content-page mt-5'>
    //             <div className='content'>
    //                 <div className='container-fluid'>
    //                     <div className='cal'>
    //                         No Data found
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>

    // </>
    if (symbolList === null || symbolList.length === 0) {
            return (

                <div className='content-page mt-5'>
        
                    <div className='content'>
        
                        <div className='container-fluid'>
        
                            <div className='cal'>
        
                                No Data found
        
                            </div>
                        </div>
                    </div>
                </div>
            ) 
        
    }
    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <div className='col-md-2 offset-md-5' style={{ marginLeft: '92%' }}>
                                <button className='add_btn btn-custom' onClick={addSymbol}><span className="mdi mdi-plus"></span></button>
                                <button className='add_btn btn-custom' onClick={handleShowModal}><span className="mdi mdi-cloud-upload mdi-18px " ></span></button>
                            </div>

                            <div className="d-flex mt-2" style={{ marginBottom: '-2.5rem' }}>
                                <div className="col-sm-12 col-md-12 p-0" style={{ zIndex: '999' }}>
                                    <div className="reactTables_filter">
                                        <label>Search: <input className="form-control form-control-sm" type="text" placeholder="Search" onChange={handleSearch} />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Scrip Name</th>
                                        <th style={{ textAlign: "center" }}>Scrip Symbol</th>
                                        <th style={{ textAlign: "center" }}>Sector</th>
                                        <th style={{ textAlign: "center" }}>SubSector</th>
                                        <th style={{ textAlign: "center" }}>Status</th>
                                        <th style={{ textAlign: "center" }}>Status Action</th>
                                        <th style={{ textAlign: "center" }}>Action</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Scrip Name</th>
                                        <th style={{ textAlign: "center" }}>Scrip Symbol</th>
                                        <th style={{ textAlign: "center" }}>Sector</th>
                                        <th style={{ textAlign: "center" }}>SubSector</th>
                                        <th style={{ textAlign: "center" }}>Status</th>
                                        <th style={{ textAlign: "center" }}>Status Action</th>4
                                        <th style={{ textAlign: "center" }}>Action</th>

                                    </tr>
                                </tfoot>
                                <tbody>
                                    {

                                        (symbolList == null || symbolList.length === 0) ? (
                                            <></>
                                        ) : (
                                            symbolList.length > 0 && symbolList && symbolList.map((item, index) => {
                                                const id = totalItems - (currentPage - 1) * itemsPerPage - (symbolList.length - 1 - index);
                                                return (
                                                    <tr id={"symbolId" + item._id} >
                                                        <td style={{ textAlign: "center" }}>
                                                            {id > 0 ? id : ''}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {item.scrip_name}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {item.scrip_symbol}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {item.sector_name ?? '-'}
                                                            {/* <img
                                                            src={`${imagePath}/${item.image}`}
                                                            alt={item.name}
                                                            style={{ height: '50px', width: '40px' }}
                                                        /> */}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {item.sub_sector_name ?? '-'}
                                                        </td>
                                                        <td id={"symbolStatus" + item._id} style={{ textAlign: "center" }}>
                                                            {renderStatusUiText(item)}
                                                        </td>
                                                        <td id={"symbolStatus" + item._id} style={{ textAlign: "center" }}>
                                                            <Tooltip title="Change Status">
                                                                <IconButton onClick={() => { changeStatus(item._id, item, index) }}>
                                                                    <FeatherIcon icon="repeat" width="18" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Tooltip title="Edit">
                                                                <IconButton onClick={() => { editSymbol(item) }}>
                                                                    <FeatherIcon icon="edit" width="18" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete">
                                                                <IconButton onClick={() => { deletesymbolIdWise(item._id) }}>
                                                                    <FeatherIcon icon="trash-2" width="18" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="View">
                                                                <IconButton onClick={() => ViewSymbol(item)}>
                                                                    <FeatherIcon icon="eye" width="18" /> {/* Replace with your desired view icon */}
                                                                </IconButton>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    }
                                </tbody>
                            </table>

                            <div className="pagination-container">
                                <nav aria-label="Page navigation">
                                    <ul className="pagination justify-content-end">
                                        {Array.from({ length: 5 }, (_, i) => {
                                            const pageNumber = currentPage + i - 2;
                                            if (pageNumber >= 1 && pageNumber <= totalPages) {
                                                return (
                                                    <li className="page-item" key={pageNumber}>
                                                        <button className="page-link" onClick={() => handlePageChange(pageNumber)}>{pageNumber}</button>
                                                    </li>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </ul>
                                </nav>
                            </div>
                        </div>


                        <Modal show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Import Excel File</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group controlId="excelFile">
                                        <Form.Label>Upload Excel File</Form.Label>
                                        <Form.Control type="file" ref={fileInputRef} name="excelFile" accept=".xlsx" />

                                    </Form.Group>
                                    <Button variant="primary" className="btn btn-primary mt-2 ml-1" onClick={handleExtensionSubmit} type="button">
                                        Submit
                                    </Button>
                                    <a href='/assets/demo/Sample_Database_Stocks.xlsx' className='mt-2 ml-1 btn btn-info' download="Sample_Database_Stocks.xlsx">
                                        Download Demo File
                                    </a>
                                </Form>
                            </Modal.Body>
                        </Modal>

                    </div>
                </div>
            </div>
        </>
    );
}

export default SymbolList;