import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getSymbolDataById, editSymbol, getSectors, getSubSectors } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Select from 'react-select';
import S3 from 'react-aws-s3';


const config = {
    bucketName: process.env.REACT_APP_S3_NAME,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    s3Url: process.env.REACT_APP_S3URL,
    dirName: "symbol",
};
export default function EditSymbol() {

    let { id } = useParams();
    const [symbolDetails, setSymbolDetils] = useState(null)
    const [symbolLogo, setSymbolLogo] = useState(null);


    const [sectorList, setSectorList] = useState(null)
    const [subSectorList, setSubSectorList] = useState(null)
    const [sectorValue, setSectorValue] = useState(null);
    const [subSectorValue, setSubSectorValue] = useState(null);


    function getsubSectorList(sectorId) {
        getSubSectors({ 'sector_id': sectorId }).then((res) => {
            res = JSON.parse(res)
            if (res.code == 200) {
                setSubSectorList(res.data)
            }
        });
    }



    useEffect(() => {
        if (id != undefined) {
            getSymbolDataById({ "symbol_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    getSectors({}).then((resSector) => {
                        resSector = JSON.parse(resSector)
                        if (resSector.code == 200) {
                            setSectorList(resSector.data)
                            setSymbolDetils(res.data)
                            setSymbolLogo(res.data.scrip_logo)
                            const foundObject1 = resSector.data.find((symbol) => symbol.value == res.data.sector);

                            setSectorValue(foundObject1)
                            getSubSectors({ 'sector_id': res.data.sector }).then((resSubSector) => {
                                resSubSector = JSON.parse(resSubSector)
                                if (resSubSector.code == 200) {
                                    setSubSectorList(resSubSector.data)
                                    setSubSectorValue(resSubSector.data.find((symbol) => symbol.value == res.data.sub_sector))

                                }
                            })


                        }
                    });
                }
            });
        }

    }, [id])

    const validationSchema = Yup.object().shape({
        scrip_name: Yup.string().required("Please enter scrip name").max(128, "Scrip name must be at most 128 character"),
        scrip_symbol: Yup.string().required("Please enter scrip symbol").max(128, "Scrip symbol must be at most 128 character"),
        category: Yup.string().required("Please enter category").max(128, "Category must be at most 128 character"),
        // sector: Yup.object().required("Please select sector"),
        // sub_sector: Yup.object().required("Please select subsector"),
        exchange: Yup.string().required("Please enter sub exchange").max(128, "Exchange must be at most 128 character"),
        security_id: Yup.string().required("Please enter security id").max(128, "Security id must be at most 128 character"),
        isin: Yup.string().required("Please enter isin").max(128, "isin must be at most 128 character"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate("/symbol")
    }
    const handleFileInput = (e) => {
        const files = e.target.files[0];
        var newfile = new Date().getTime() + files.name
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(files, newfile).then(data => {
            if (data.status === 204) {
                setSymbolLogo(data.location.split("/")[4])
                // setImageErr('')

            } else {
                console.log('obj err')
            }
        });
    }


    const onSubmit = (data) => {

        editSymbol({ "symbol_id": id, "scrip_name": data.scrip_name.trimStart().trimEnd(), "scrip_symbol": data.scrip_symbol.trimStart().trimEnd(), "scrip_logo": symbolLogo, "category": data.category.trimStart().trimEnd(), "sector": sectorValue, "sub_sector": subSectorValue, "exchange": data.exchange.trimStart().trimEnd(), "security_id": data.security_id.trimStart().trimEnd() , "isin" : data.isin.trimStart().trimEnd() }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/symbol")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    const imageUrl = symbolLogo != null ? `${process.env.REACT_APP_S3URL}/symbol/${symbolLogo}` : 'https://www.islgroups.com/images/3.jpg';

    if (symbolDetails == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Edit Symbol Details</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >

                                <div className="mb-2">
                                    <div className="admin-profile">
                                        <label className="-label" for="file">
                                            <i className="fas fa-camera"></i>
                                            <span style={{ marginLeft: "5px" }}>Change Image</span>
                                        </label>
                                        <input id="file" type="file" onChange={handleFileInput} />
                                        <img src={imageUrl} id="output" width="200" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Scrip Name </label>
                                            <div>
                                                <input type="text" {...register('scrip_name')} defaultValue={symbolDetails.scrip_name} className="form-control" placeholder="Please enter scrip name" />
                                                <div className="invalid-feedback">{errors.scrip_name?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Scrip Symbol </label>
                                            <div>
                                                <input type="text" {...register('scrip_symbol')} defaultValue={symbolDetails.scrip_symbol} className="form-control" placeholder="Please enter scrip symbol" />
                                                <div className="invalid-feedback">{errors.scrip_symbol?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Category </label>
                                            <div>
                                                <input type="text" {...register('category')} className="form-control" placeholder="Please enter category" defaultValue={symbolDetails.category} />
                                                <div className="invalid-feedback">{errors.category?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Sector </label>
                                            <div className='custom-select-container'>
                                                <Controller
                                                    name="content"
                                                    control={control}
                                                    {...register('sector', { required: 'Please select country code' })}
                                                    render={({ field }) => (
                                                        <Select
                                                            options={sectorList}
                                                            value={sectorValue}
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            onChange={(selectedOption) => {
                                                                setSectorValue(selectedOption);
                                                                setSubSectorValue(null);
                                                                getsubSectorList(selectedOption.value)
                                                                field.onChange(selectedOption);
                                                            }}
                                                            placeholder="Select Sector"
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                />

                                                <div className="invalid-feedback">{errors.sector?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Sub Sector </label>
                                            <div>
                                                <div className='custom-select-container'>
                                                    <Controller
                                                        name="content"
                                                        control={control}
                                                        {...register('sub_sector', { required: 'Please select country code' })}
                                                        render={({ field }) => (
                                                            <Select
                                                                options={subSectorList}
                                                                value={subSectorValue}
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                onChange={(selectedOption) => {
                                                                    setSubSectorValue(selectedOption);
                                                                    field.onChange(selectedOption);
                                                                }}
                                                                placeholder="Select SubSector"
                                                                isSearchable={true}
                                                            />
                                                        )}
                                                    />

                                                    <div className="invalid-feedback">{errors.sub_sector?.message}</div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>

                                            <label> Exchange </label>
                                            <div>
                                                <input type="text" {...register('exchange')} className="form-control" placeholder="Please enter exchange" defaultValue={symbolDetails.exchange} />
                                                <div className="invalid-feedback">{errors.exchange?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Security ID </label>
                                            <div>
                                                <input type="text" {...register('security_id')} className="form-control" placeholder="Please enter security id" defaultValue={symbolDetails.security_id} />
                                                <div className="invalid-feedback">{errors.security_id?.message}</div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <label> ISIN </label>
                                            <div>
                                                <input type="text" {...register('isin')} className="form-control" placeholder="Please enter isin" defaultValue={symbolDetails.isin}  />
                                                <div className="invalid-feedback">{errors.isin?.message}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}