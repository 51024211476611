import React, { useEffect, useState } from 'react'
import { getContactUsDetails } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';

function ContactUS() {
    const navigate = useNavigate();
    
    const [contactUsList, setContactUsList] = useState(null)

    useEffect(() => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
            Swal.showLoading()
            }
        })
        getContactUsDetails({}).then((response) => {
            Swal.close()
            response = JSON.parse(response)
            if (response.code == 200) {
                setContactUsList(response.data)
            }
        })
    }, [])

    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 12,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'copy',
                    className: 'btn-mute'
                  },{
                    extend: 'csv',
                    className: 'btn-mute'
                  },{
                    extend: 'print',
                    className: 'btn-mute'
                  }],
                "bDestroy": true,
            }
        );
    }, [contactUsList])

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    // function changeStatus(contactId, item, position) {
        
    //     var status = (item.status === "Active") ? "Inactive" : "Active"
    
    //     changerequestStatus({ "u_id": contactId, "status": status }).then((response) => {
    //         response = JSON.parse(response)
    //         if (response.code == 1) {
    //             showMessage(response.message)
    //             contactUsList[position].status = status
    //             var data = renderToStaticMarkup(renderStatusUiText(contactUsList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
    //             $('#mangeUser').DataTable().row("#contactId" + contactId).cell("#requestStatus" + contactId).data(data).draw(false);

    //         }
    //     }); 
    // }
    
    // function renderStatusUiText(item) {
    //     return (
    //         item.status == "Active" ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
    //     )
    // }
    function sendMail(email){
        navigate("/contactUs/"+email)
    }

    if (contactUsList == null) return <> </>

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                <thead>
                                    <tr>
                                        <th style={{textAlign:"center"}}>ID</th>
                                        <th style={{textAlign:"center"}}>Name</th>
                                        <th style={{textAlign:"center"}}>Email</th>
                                        <th style={{textAlign:"center"}}>Subject</th>
                                        <th style={{textAlign:"center"}}>Description</th>
                                        <th style={{textAlign:"center"}}>Action</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th style={{textAlign:"center"}}>ID</th>
                                        <th style={{textAlign:"center"}}>Name</th>
                                        <th style={{textAlign:"center"}}>Email</th>
                                        <th style={{textAlign:"center"}}>Subject</th>
                                        <th style={{textAlign:"center"}}>Description</th>
                                        <th style={{textAlign:"center"}}>Action</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    {
                                        contactUsList && contactUsList.map((item, index) => {
                                            return (
                                                <tr id={"contactId" + item._id} >
                                                    <td style={{textAlign:"center"}}>
                                                        {index+1}
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                        {item.name}
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                        {item.email}
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                        {item.subject}
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                        {item.description}
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                        <Tooltip title="send">
                                                            <IconButton onClick={()=>{sendMail(item.email)}}>                                        
                                                                <FeatherIcon icon="send" width="18" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUS;