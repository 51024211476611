import React, { useEffect, useRef, useState } from 'react'
import { changeUserStatus, getSubAdminById, getUser, removeUser } from '../../api/apiHandler'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FeatherIcon from "feather-icons-react";
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { algoUserOptions, brokerConnectOptions, DATE_FORMAT, kycOptions, statusOptions, subscriptionTypeOptions } from '../../globals/constant';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from 'moment';
import { exportExcelFile } from '../../common/Common';


const UserList = () => {
    const navigate = useNavigate();
    const debounceTimeout = useRef(null);

    const [userList, setUserList] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null);
    const [userRemove, setUserRemove] = useState(0)
    const [userChangeStatus, setUserChangeStatus] = useState(0)
    const [userEdit, setUserEdit] = useState(0)
    const [userView, setUserView] = useState(0)

    const [userAdd, setUserAdd] = useState(0)
    const [selectKYC, setSelectKYC] = useState(null);
    const [selectStatus, setSelectStatus] = useState(null);
    const [selectSubscriptionType, setSelectSubscriptionType] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const id = localStorage.getItem('Aid');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [divState, setDivState] = useState(null);
    const [brokerConnect, setBrokerConnect] = useState(null);
    const [algoUserStatus, setAlgoUserStatus] = useState(null);
    const [page, setPage] = useState(1);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [dates, setDatesState] = useState({
        startDate: "",
        endDate: ""
    });

    const setDates = (e, { startDate, endDate }) => {
        setDatesState({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD")
        });
    };

    const viewUser = (item) => {
        if (userView == 1) {
            navigate(`/user/view/${item._id}`);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        };
    }

    useEffect(() => {
        if (localStorage.getItem("Arole") == "S") {
            setUserRemove(1)
            setUserChangeStatus(1)
            setUserEdit(1)
            setUserAdd(1)
            setUserView(1)
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            getUser({ "page": page, "limit": process.env.REACT_APP_RECORD_COUNT }).then((response) => {
                console.log('page: ', page);
                Swal.close()

                response = JSON.parse(response)

                if (response.code == 200) {
                    setUserList(response.data.userInfo);
                    setTotalRecordCount(Math.ceil(response.data.totalUsers / process.env.REACT_APP_RECORD_COUNT));
                } else {
                    setUserList([])
                }
            })
        } else {
            Swal.close()
            getUser({ "page": page, "limit": process.env.REACT_APP_RECORD_COUNT }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    setUserList(response.data.userInfo)
                    setTotalRecordCount(Math.ceil(response.data.totalUsers / process.env.REACT_APP_RECORD_COUNT));
                    getSubAdminById({ "sub_admin_id": id }).then((res) => {
                        res = JSON.parse(res)
                        if (res.code == 200) {
                            setPermissions(res.data.permissions)
                            setUserEdit(res.data.permissions.filter(module => module.class_name === 'user' && module.is_edit === 1).length > 0
                            );
                            setUserView(res.data.permissions.filter(module => module.class_name === 'user' && module.is_view === 1).length > 0
                            );
                            setUserRemove(res.data.permissions.filter(module => module.class_name === 'user' && module.is_delete === 1).length > 0)
                            setUserChangeStatus(res.data.permissions.filter(module => module.class_name === 'user' && module.is_change_status === 1).length > 0)
                        }
                    });
                } else {
                    setUserList([])
                }
            })


        }
    }, [])

    useEffect(() => {
        setUserList(null)
        getUser({ "start_date": dates.startDate, "end_date": dates.endDate, "kyc_status": selectKYC != null ? selectKYC.value : selectKYC, "status": selectStatus != null ? selectStatus.value : selectStatus, "subscription_type": selectSubscriptionType != null ? selectSubscriptionType.value : selectSubscriptionType, "broker_connect_status": brokerConnect != null ? brokerConnect.value : brokerConnect, "algo_user_status": algoUserStatus != null ? algoUserStatus.value : algoUserStatus, "page": page, "limit": process.env.REACT_APP_RECORD_COUNT, "search": searchQuery }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setUserList(response.data.userInfo)
                console.log('response.data.totalUsers: ', response.data.totalUsers);
                setTotalRecordCount(Math.ceil(response.data.totalUsers / process.env.REACT_APP_RECORD_COUNT));
            } else {
                setUserList([])
            }
        })
    }, [page, selectKYC, selectStatus, selectSubscriptionType, dates, brokerConnect, algoUserStatus]);

    function renderStatusUiText(item) {
        return (
            item.is_active == 1 ? <span style={{ color: '#29E337' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    const handlePageClick = (event) => {
        setPage(event.selected + 1)
    };

    const handleSearch = (event) => {
        // Clear the existing timeout
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        // Set a new timeout
        debounceTimeout.current = setTimeout(() => {
            setPage(1);
            // Dispatch the action with the updated state
            getUser({ "start_date": dates.startDate, "end_date": dates.endDate, "kyc_status": selectKYC != null ? selectKYC.value : selectKYC, "status": selectStatus != null ? selectStatus.value : selectStatus, "subscription_type": selectSubscriptionType != null ? selectSubscriptionType.value : selectSubscriptionType, "broker_connect_status": brokerConnect != null ? brokerConnect.value : brokerConnect, "algo_user_status": algoUserStatus != null ? algoUserStatus.value : algoUserStatus, "page": page, "limit": process.env.REACT_APP_RECORD_COUNT, "search": event }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    setUserList(response.data.userInfo)
                    setTotalRecordCount(Math.ceil(response.data.totalUsers / process.env.REACT_APP_RECORD_COUNT));
                } else {
                    setUserList([])
                }
            })
        }, 1000);
    }
    const editUser = (item) => {
        if (userEdit == 1) {
            navigate(`/user/edit/${item._id}`);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    function changeStatus(userId, item, position) {
        if (userChangeStatus == 1) {

            const status = (item.is_active == 1) ? "0" : "1"

            changeUserStatus({ "user_id": userId, "is_active": status }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    showMessage(response.message)
                    userList[position].is_active = status
                    // const data = renderToStaticMarkup(renderStatusUiText(userList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                    // $('#mangeUser').DataTable().row("#userId" + userId).cell("#userStatus" + userId).data(data).draw(false);

                }
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    function deleteUserIdWise(userId) {
        if (userRemove == 1) {
            Swal.fire({
                title: 'Are you sure you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#02C9D6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    // console.log("userId " + userId)
                    removeUser({ "user_id": userId }).then((response) => {
                        response = JSON.parse(response)
                        if (response.code == 200) {
                            // $('#mangeUser').DataTable().row("#userId" + userId).remove().draw(false);
                            showMessage(response.message)
                        }
                    });
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    function clearFilter() {

        // Alert.processing()
        setTimeout(() => {
            Swal.close()
            setSelectKYC()
            setSelectStatus()
            setSelectSubscriptionType()
            setBrokerConnect()
            setAlgoUserStatus()
            setDatesState({
                startDate: "",
                endDate: ""
            });
        }, 0)

    }

    const exportExcel = () => {
        let columns = [
            { header: "Id", key: "_id", width: 32, vertical: 'middle', horizontal: 'center', wrapText: true },
            { header: "Name", key: "name", width: 32, horizontal: 'center' },
            { header: "Email", key: "email", width: 20, wrapText: true },
            { header: "Phone Number", key: "phone", width: 20, wrapText: true },
            { header: "Referral Code", key: "referral_code", width: 20, wrapText: true },
            // { header: "Referral Code", key: "referral_code", width: 20, wrapText: true },
            { header: "Age", key: "age", width: 20, wrapText: true },
            { header: "Gender", key: "gender", width: 20, wrapText: true },
            { header: "Experience", key: "experience", width: 20, wrapText: true },
            { header: "Financial Goals", key: "goals", width: 50, wrapText: true },
            { header: "Profession", key: "profession", width: 20, wrapText: true },
            { header: "Stockwiz Usage", key: "sw_usage", width: 50, wrapText: true },
            { header: "Risk Type", key: "risk_type", width: 20, wrapText: true },
            { header: "When Market Goes Up", key: "market_goes_up", width: 40, wrapText: true },
            { header: "When Market Goes Down", key: "market_goes_down", width: 40, wrapText: true },
            { header: "Current Investments", key: "current_investment", width: 50, wrapText: true },
            { header: "Created Date", key: "created_at", width: 20, wrapText: true }
        ];

        const ExportData = userList.map(user => {
            return ({
                _id: user._id,
                name: user.first_name + " " + user.last_name,
                email: user.email,
                phone: user.country_code + " " + user.mobile_number,
                referral_code: user.referral_code,
                age: user.age,
                gender: user.gender,
                experience: user.experience,
                goals: user.goals?.length > 0 ? user.goals.join(' , ') : '-',
                profession: user.profession,
                sw_usage: user.sw_usage?.length > 0 ? user.sw_usage.join(' , ') : '-',
                risk_type: user.risk_type,
                market_goes_up: user.market_goes_up,
                market_goes_down: user.market_goes_down,
                current_investment: user.current_investment?.length > 0 ? user.current_investment.join(' , ') : '-',
                created_at: moment(user?.created_at).format(DATE_FORMAT)
            })
        })

        const fileName = `UserReport-` + moment()

        exportExcelFile(columns, ExportData, fileName)

    }

    // const userListing = () => {
    //     if (searchQuery === "") {
    //         SWEET_LOADER()
    //     }

    //     const paramObj = {
    //         page: page,
    //         record_count: recordCount,
    //         search: searchQuery
    //     }
    //     listingUser(paramObj).then((res) => {
    //         SWEET_LOADER_CLOSE()
    //         var response = JSON.parse(res)
    //         setUserList(response.data.usersData)
    //         setTotalRecordCount(Math.ceil(response.data.total_count / recordCount))
    //     }).catch((error) => {
    //         SWEET_LOADER_CLOSE()
    //         setUserList([])
    //         console.log("catch", error);
    //     })
    // }
    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <p>
                                <a className="btn btn-primary mo-mb-2" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                    Filter
                                </a>

                            </p>
                            <div className={`collapse ${divState !== null ? 'show' : ''}`} id="collapseExample">
                                <div className="card card-body">
                                    <div className='row'>
                                        <div className='col-md'>
                                            <label>KYC Status : </label>

                                            <Select
                                                aria-labelledby="aria-label"
                                                placeholder="Select KYC Status.."
                                                value={selectKYC}
                                                onChange={(e) => { setSelectKYC(e); setDivState('show'); }}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                name="aria-live-color"
                                                options={kycOptions}
                                            />
                                        </div>
                                        <div className='col-md'>
                                            <label>Status : </label>

                                            <Select
                                                aria-labelledby="aria-label"
                                                placeholder="Select Status.."
                                                value={selectStatus}
                                                onChange={(e) => { setSelectStatus(e); setDivState('show'); }}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                name="aria-live-color"
                                                options={statusOptions}
                                            />
                                        </div>
                                        <div className='col-md'>
                                            <label>Subscription Type : </label>

                                            <Select
                                                aria-labelledby="aria-label"
                                                placeholder="Subscription Type.."
                                                value={selectSubscriptionType}
                                                onChange={(e) => { setSelectSubscriptionType(e); setDivState('show'); }}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                name="aria-live-color"
                                                options={subscriptionTypeOptions}
                                            />
                                        </div>
                                        <div className='col-md'>
                                            <label>Broker Connect Status : </label>

                                            <Select
                                                aria-labelledby="aria-label"
                                                placeholder="Select Broker Status.."
                                                value={brokerConnect}
                                                onChange={(e) => { setBrokerConnect(e); setDivState('show'); }}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                name="aria-live-color"
                                                options={brokerConnectOptions}
                                            />
                                        </div>
                                        <div className='col-md'>
                                            <label>Algo User Status : </label>

                                            <Select
                                                aria-labelledby="aria-label"
                                                placeholder="Select Status.."
                                                value={algoUserStatus}
                                                onChange={(e) => { setAlgoUserStatus(e); setDivState('show'); }}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                name="aria-live-color"
                                                options={algoUserOptions}
                                            />
                                        </div>
                                        <div className='col-md'>
                                            <label>Select Date Range : </label>

                                            <DateRangePicker
                                                onApply={setDates}
                                                initialSettings={{ startDate: moment().subtract(6, "days").format('MM/DD/YYYY'), endDate: moment().format('MM/DD/YYYY') }}
                                            >
                                                <input
                                                    type="text"
                                                    value={dates.startDate !== '' ? moment(dates.startDate).format('MMMM DD, YYYY') + " - " + moment(dates.endDate).format('MMMM DD, YYYY') : 'Select Time'}
                                                    className="form-control"
                                                    onChange={() => { console.log(`changes`); }}
                                                />
                                            </DateRangePicker>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <div className="form-inline">
                                                <button href="" className="btn btn-warning waves-effect btn-file" onClick={clearFilter} >
                                                    <i className="mdi mdi-filter-remove-outline"></i> <span><b>Clear Filter</b></span>
                                                </button>

                                                <button className="btn btn-success waves-effect btn-file mx-1" onClick={exportExcel}>
                                                    <i className="mdi mdi-cloud-download me-1 font-14"></i><span><b> Export </b></span>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row justify-content-between mb-2 float-right">
                                        <div className="col-auto">
                                            {/* <form className="search-bar position-relative mb-sm-0 mb-2"> */}
                                                <input type="text" className="form-control" placeholder="Search..." onChange={(e) => { handleSearch(e.target.value); setSearchQuery(e.target.value); }} />
                                                {/* <span className="mdi mdi-magnify"></span> */}
                                            {/* </form> */}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="text-md-end">
                                                {/* <button type="button" className="btn btn-custom waves-effect waves-light mb-2 me-2"><i className="mdi mdi-basket me-1"></i> Add User</button> */}
                                                {/* <button type="button" className="btn btn-success waves-effect waves-light mb-2 me-1"><i className="mdi mdi-cog"></i></button> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-centered table-nowrap table-borderless table-hover mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th style={{ textAlign: "center" }}>ID</th>
                                                    <th style={{ textAlign: "center" }}>Name</th>
                                                    <th style={{ textAlign: "center" }}>Email</th>
                                                    <th style={{ textAlign: "center" }}>Phone Number</th>
                                                    <th style={{ textAlign: "center" }}>Referral Code</th>
                                                    <th style={{ textAlign: "center" }}>KYC Status</th>
                                                    <th style={{ textAlign: "center" }}>Subscription Type</th>
                                                    <th style={{ textAlign: "center" }}>Broker Connect Status</th>
                                                    <th style={{ textAlign: "center" }}>Created Date</th>
                                                    <th style={{ textAlign: "center" }}>Status</th>
                                                    <th style={{ textAlign: "center" }}>Status Action</th>
                                                    <th style={{ textAlign: "center" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userList && userList.length > 0 ?
                                                    (
                                                        userList && userList?.map((item, index) => {
                                                            return (
                                                                <tr id={"userId" + item._id} >
                                                                    <td className="row-number" style={{ textAlign: "center" }}>
                                                                        <span className="fw-semibold">{index + 1 + ((page - 1) * process.env.REACT_APP_RECORD_COUNT)}</span>
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {item.first_name + " " + item.last_name}
                                                                    </td>

                                                                    <td style={{ textAlign: "center" }}>
                                                                        {item.email}
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {item.country_code + " " + item.mobile_number}
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {item.referral_code}
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {item.kyc_status == 0 ? "Pending" : "Done"}
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {item.subscription_type == 1 ? "Free" : "Paid"}
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {item.is_connect_broker === 1 ? "Yes" : "No"}
                                                                    </td>

                                                                    <td style={{ textAlign: "center" }}>
                                                                        {item.created_at}
                                                                    </td>
                                                                    <td id={"userStatus" + item._id} style={{ textAlign: "center" }}>
                                                                        {renderStatusUiText(item)}
                                                                    </td>
                                                                    <td id={"userStatus" + item._id} style={{ textAlign: "center" }}>
                                                                        <Tooltip title="Change Status">
                                                                            <IconButton onClick={() => { changeStatus(item._id, item, index) }}>
                                                                                <FeatherIcon icon="repeat" width="18" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        <Tooltip title="Edit">
                                                                            <IconButton onClick={() => { editUser(item) }}>
                                                                                <FeatherIcon icon="edit" width="18" />
                                                                            </IconButton>
                                                                        </Tooltip>

                                                                        <Tooltip title="Delete">
                                                                            <IconButton onClick={() => { deleteUserIdWise(item._id) }}>
                                                                                <FeatherIcon icon="trash-2" width="18" />
                                                                            </IconButton>
                                                                        </Tooltip>

                                                                        <Tooltip title="View">
                                                                            <IconButton onClick={() => viewUser(item)}>
                                                                                <FeatherIcon icon="eye" width="18" /> {/* Replace with your desired view icon */}
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    ) :
                                                    (
                                                        // <tr>
                                                        //     <td>
                                                        //         <h4 className="page-title">No user data found</h4>
                                                        //     </td>
                                                        // </tr>
                                                        <></>
                                                    )
                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                    <ul className="pagination pagination-rounded justify-content-end my-2">
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=" > "
                                            onPageChange={handlePageClick}
                                            // pageRangeDisplayed={pageCount}
                                            pageCount={totalRecordCount}
                                            previousLabel=" < "
                                            renderOnZeroPageCount={null}
                                            containerClassName="pagination"
                                            pageLinkClassName="page-link"
                                            previousLinkClassName="page-link"
                                            nextLinkClassName="page-link"
                                            disabledClassName="disabled"
                                            activeClassName="active"
                                            activeLinkClassName="active-link"
                                        />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div>
            </div> */}


        </>
    )
}

export default UserList