/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getThemeById, getSignalData } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export default function ViewTheme() {
    let { id } = useParams();
    const [themeDetails, setThemeDetails] = useState(null)
    const [bgImage, setBgImage] = useState(null);
    const [icon, setIcon] = useState(null);
    const [schematic, setSchematic] = useState(null);
    const [subBaskets, setSubBaskets] = useState([]);


    useEffect(() => {
        if (id != undefined) {
            getThemeById({ "theme_id": id, "is_view": 1 }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setThemeDetails(res.data)
                    setBgImage(res.data.bg_image)
                    setIcon(res.data.icon)
                    setSchematic(res.data.schematic)

                }
            });

        }
    }, [id])

    if (themeDetails == null) return <> </>
    return (

        <><div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid'>
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>


                        <h5 className="mt-0 header-title">View Theme</h5>

                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#home2" role="tab">Basic Details</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#profile3" role="tab">Key Highlights Images</a>
                            </li>

                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane active p-3" id="home2" role="tabpanel">
                                <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                {themeDetails.basket_icon != null ?
                                                    <>

                                                        <div className="member-img">
                                                            <img src={themeDetails.theme_icon_url} className="rounded-circle" alt="profile-image" style={{ width: 200, height: 200 }} />
                                                        </div>
                                                    </> : <>
                                                        <div>Not Uploaded</div>
                                                    </>
                                                }
                                            </div>


                                            <div className='col-md-9'>
                                                <div className="form-group">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label class="form-label">Theme Name</label><br />
                                                            <span class="font-15">{themeDetails.name}</span>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <label class="form-label">About / Bio</label><br />
                                                            <span class="font-15">{themeDetails.about}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label class="form-label">Status</label><br />
                                                            <span class="font-15">{themeDetails.is_active == 0 ? "🔴 Inactive" : "🟢 Active"}</span>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <label class="form-label">Labels</label><br />
                                                            <span class="font-15">{themeDetails.label}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <label class="form-label"><b>Stock Allocations : </b></label><br />
                                                            <ul>
                                                                {themeDetails.stock_allocations.map((allocation, index) => (
                                                                    <li key={index}>{`${index + 1}. ${allocation.stock.scrip_name}: ${allocation.percentage}%`}</li>
                                                                ))}
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="tab-pane p-3" id="profile3" role="tabpanel">
                                <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                    {themeDetails.upload_images.length > 0 ? (
                                        <>
                                            <div id="accordion" className="d-flex flex-row flex-wrap">
                                                {
                                                    themeDetails.upload_images.map((image, index) => (
                                                        <div className='col-md' key={index}>
                                                            <span className="loc-caption">
                                                                <div className="mb-3">
                                                                    <div className="profile-pic">
                                                                        <img className='img-thumbnail' id="output" src={image} width="200" />
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                        </>
                                    ) : (
                                        <center>
                                            Not Uploaded.
                                        </center>
                                    )}
                                </div>
                            </div>

                        </div>







                    </div></div></div></div>

            {/* popup model */}
            <div id="show_image_popup">
                <div class="close-btn-area">

                    <button id="close-btn">X</button>
                </div>
                <div id="image-show-area">
                    <img id="large-image" src="" alt="">
                    </img></div>
            </div></>
    )
}