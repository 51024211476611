import Swal from "sweetalert2";

export function ErrorAlert(data, msg) {
  Swal.fire({
    position: "top-end",
    icon: "error",
    title: data || msg,
    toast: true,
    showConfirmButton: false,
    timer: 1500,
  });
}

//success

export function SuccessAlert(data, msg) {
  Swal.fire({
    position: "top-end",
    icon: "success",
    title: data || msg,
    toast: true,
    showConfirmButton: false,
    timer: 1500,
  });
}


export function SimpleAlert(data, msg) {
  Swal.fire({
    position: "top-end",
    icon: "alert",
    title: data || msg,
    toast: true,
    showConfirmButton: false,
    timer: 1500,
  });
}

export function confirmChangeStatus() {
  Swal.fire({
    title: 'Are you sure you want to delete?',
    text: "You won't be able to revert this!",
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#02C9D6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {

    if (result.isConfirmed) {
      // console.log("userId " + userId)
      console.log(`Confirm true`)
      return true
    } else {
      console.log(`Confirm false`)
      return false
    }

  })
}


export function processing() {
  Swal.fire({
    title: 'Please wait...',
    didOpen: () => {
      Swal.showLoading()
    }
  })
}