/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getStrategyById, getSignalData } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export default function ViewStrategy() {
    let { id } = useParams();
    const [StrategyDetails, setStrategyDetails] = useState(null)
    const [bgImage, setBgImage] = useState(null);
    const [icon, setIcon] = useState(null);
    const [schematic, setSchematic] = useState(null);
    const [trades, setTrades] = useState([]);


    useEffect(() => {
        if (id != undefined) {
            getStrategyById({ "strategy_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setStrategyDetails(res.data)
                    setBgImage(res.data.bg_image)
                    setIcon(res.data.icon)
                    setSchematic(res.data.schematic)
                    if (res.data.trades.length > 0) {

                        getSignalData({ "signal_ids": res.data.trades }).then((tradesData) => {
                            tradesData = JSON.parse(tradesData)
                            console.log(tradesData);
                            if (tradesData.code == 200) {
                                setTrades(tradesData.data)
                            }
                        })
                    }
                }
            });

        }
    }, [id])


    if (StrategyDetails == null) return <> </>
    return (

        <><div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid'>
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>


                        <h5 className="mt-0 header-title">View Startegy</h5>

                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#home2" role="tab">Basic Details</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#profile2" role="tab">Graphic Details</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#profile3" role="tab">Active Trades</a>
                            </li>

                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane active p-3" id="home2" role="tabpanel">
                                <div className='cal col-xs-12 col-md-12 col-sm-12'>

                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label class="form-label">Strategy Name</label><br />
                                                <span class="font-15">{StrategyDetails.name}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label class="form-label">Strategy Title</label><br />
                                                <span class="font-15">{StrategyDetails.title}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label class="form-label">Strategy Sub Title</label><br />
                                                <span class="font-15">{StrategyDetails.subtitle}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label class="form-label">Strategy Description</label><br />
                                                <span class="font-15">{StrategyDetails.description}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label class="form-label">Tags</label><br />
                                                <span class="font-15">{StrategyDetails.tag_names}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label class="form-label">Risk Type</label><br />
                                                <span class="font-15">{StrategyDetails.risk_type}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label class="form-label">Strategy Type</label><br />
                                                <span class="font-15">{StrategyDetails.type == 1 ? "Free" : "Premium"}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label class="form-label">Strategy Category</label><br />
                                                <span class="font-15">{StrategyDetails.category == 1 ? "Investing" : "Trading"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label class="form-label">Creator</label><br />
                                                <span class="font-15">{StrategyDetails.creator}</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <label class="form-label"> Status </label><br />
                                                <span class="font-15">{StrategyDetails.is_active == 0 ? "🔴 Inactive" : "🟢 Active"}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label class="form-label">Trade Direction</label><br />
                                                <span class="font-15">{StrategyDetails.trade_direction == 1 ? "LONG" : "SHORT"}</span>
                                            </div>
                                        </div></div>
                                </div>
                            </div>
                            <div className="tab-pane p-3" id="profile2" role="tabpanel">
                                <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label class="form-label"> Background Image </label><br />
                                                {StrategyDetails.bg_image_url != null ? <>
                                                    <span class="loc-caption">
                                                        <div className="mb-3">
                                                            <div className="profile-pic">

                                                                <img className='img-thumbnail' src={StrategyDetails.bg_image_url} id="output" width="200" />
                                                            </div>
                                                        </div>
                                                    </span>
                                                </> : <>
                                                    <div>Not Uploaded</div>
                                                </>}

                                            </div>
                                            <div className='col-md-4'>
                                                <label class="form-label"> Icon </label><br />
                                                {StrategyDetails.icon_url != null ? <>
                                                    <span class="loc-caption">
                                                        <div className="mb-3">
                                                            <div className="profile-pic">

                                                                <img className='img-thumbnail' src={StrategyDetails.icon_url} id="output" width="200" />
                                                            </div>
                                                        </div>
                                                    </span>
                                                </> : <>
                                                    <div>Not Uploaded</div>
                                                </>}
                                            </div>
                                            <div className='col-md-4'>
                                                <label class="form-label"> Schematic Image </label><br />
                                                {StrategyDetails.schematic_img_url != null ? <>
                                                    <span class="loc-caption">
                                                        <div className="mb-3">
                                                            <div className="profile-pic">

                                                                <img src={StrategyDetails.schematic_img_url} className='img-thumbnail' id="output" width="200" />
                                                            </div>
                                                        </div>
                                                    </span>
                                                </> : <>
                                                    <div>Not Uploaded</div>
                                                </>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane p-3" id="profile3" role="tabpanel">
                                <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                    {trades.length > 0 ? (
                                        <>
                                            <div id="accordion">
                                                {
                                                    trades.map((tradeItem) => (
                                                        <div className="card mb-1 mt-2" key={tradeItem._id}>
                                                            <div className="card-header p-3" id={`heading${tradeItem._id}`}>
                                                                <h6 className="m-0">
                                                                    <a
                                                                        href={`#collapse${tradeItem._id}`}
                                                                        className={`text-dark ${tradeItem._id !== 'One' ? 'collapsed' : ''}`}
                                                                        data-toggle="collapse"
                                                                        aria-expanded={tradeItem._id === 'One'}
                                                                        aria-controls={`collapse${tradeItem._id}`}
                                                                    >
                                                                        Trade Name #{tradeItem.name}
                                                                    </a>
                                                                </h6>
                                                            </div>
                                                            <div
                                                                id={`collapse${tradeItem._id}`}
                                                                className={`collapse ${tradeItem._id === 'One' ? 'show' : ''}`}
                                                                aria-labelledby={`heading${tradeItem._id}`}
                                                                data-parent="#accordion"
                                                            >
                                                                <div className="card-body">
                                                                    <div className="form-group">
                                                                        <div className='row'>

                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> <strong>Category</strong></label><br />
                                                                                <span class="font-15">{tradeItem.category}</span>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> <strong> Type</strong></label><br />
                                                                                <span class="font-15">{tradeItem.type == 1 ? "Free" : "Premium"}</span>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> <strong>Trade Category</strong></label><br />
                                                                                <span class="font-15">{tradeItem.trade_category}</span>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> <strong>Symbol</strong></label><br />
                                                                                <span class="font-15">{tradeItem.symbol}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <div className='row'>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> <strong>Entry Price</strong></label><br />
                                                                                <span class="font-15">{tradeItem.entry_price}</span>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label">Stop Loss / Risk</label><br />
                                                                                <span class="font-15">{tradeItem.risk} {tradeItem.risk_type == "Price" ? "Rs" : "%"}</span>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label">Target Price</label><br />
                                                                                <span class="font-15">{tradeItem.target_price} {tradeItem.target_price_type == "Price" ? "Rs" : "%"}</span>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label">TradeLog Risk</label><br />
                                                                                <span class="font-15">{tradeItem.tradelog_risk != null ? <>
                                                                                    {tradeItem.tradelog_risk} {tradeItem.tradelog_risk_type == "Price" ? "Rs" : "%"}
                                                                                </> : <>{'-'}</>}</span>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <div className='row'>

                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> <strong>Publish Date</strong></label><br />
                                                                                <span class="font-15">{tradeItem.publish_date}</span>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> <strong> New Idea </strong></label><br />
                                                                                <span class="font-15">{tradeItem.is_new_idea == 1 ? "Yes" : "No"}</span>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> <strong> Display on Homepage </strong></label><br />
                                                                                <span class="font-15">{tradeItem.is_display_on_homepage == 1 ? "Yes" : "No"}</span>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> <strong> Save To Draft </strong></label><br />
                                                                                <span class="font-15">{tradeItem.is_draft == 1 ? "Yes" : "No"}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <div className='row'>

                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> Buying Zone (In %) </label><br />
                                                                                <span class="font-15">{tradeItem.buy_percentage}</span>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> Selling Zone (In %) </label><br />
                                                                                <span class="font-15">{tradeItem.sell_percentage}</span>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> <strong> Display on Homepage </strong></label><br />
                                                                                <span class="font-15">{tradeItem.is_display_on_homepage == 1 ? "Yes" : "No"}</span>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label class="form-label"> <strong> Save To Draft </strong></label><br />
                                                                                <span class="font-15">{tradeItem.is_draft == 1 ? "Yes" : "No"}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </>
                                    ) : (
                                        <center>
                                            No Active Trades
                                        </center>
                                    )}
                                </div>
                            </div>

                        </div>







                    </div></div></div></div>

            {/* popup model */}
            <div id="show_image_popup">
                <div class="close-btn-area">

                    <button id="close-btn">X</button>
                </div>
                <div id="image-show-area">
                    <img id="large-image" src="" alt="">
                    </img></div>
            </div></>
    )
}