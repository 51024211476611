import { axiosClient } from "./apiClient";

export function login(data){
    return axiosClient.post('auth/login', data);
}
export function changePassword(data){
    return axiosClient.post('auth/changePassword', data);
}
export function forgotPassword(data){
    return axiosClient.post('auth/forgotPassword', data);
}
export function resetPassword(data){
    return axiosClient.post('auth/resetPassword', data);
}
export function getAdmin(data){
    return axiosClient.get('auth/getProfile', data);
}
export function editProfile(data){
    return axiosClient.post('auth/editProfile', data);
}
export function logout(data){
    return axiosClient.post('auth/logout', data);
}
export function getManagePage(data){
    return axiosClient.post('page/getManagePage', data);
}
export function editManagePage(data){
    return axiosClient.post('page/editPageContent', data);
}
export function getFaq(data){
    return axiosClient.post('page/faqList', data);
}
export function changeFaqStatus(data){
    return axiosClient.post('page/faqChangeStatus', data);
}
export function removeFaq(data){
    return axiosClient.post('page/removeFaq', data);
}
export function addFaq(data){
    return axiosClient.post('page/addFaq', data);
}
export function editFaq(data){
    return axiosClient.post('page/editFaq', data);
}
export function getActiveFaq(data){
    return axiosClient.post('page/getActiveFaq', data);
}
export function getContactUsDetails(data){
    return axiosClient.get('page/contactList', data);
}
export function contactUs(data){
    return axiosClient.post('page/contactUs', data);
}
export function getStrategyList(data){
    return axiosClient.post('strategy/getStrategyList', data);
}
export function getActiveRole(data){
    return axiosClient.post('user/getActiveRole', data);
}
export function changeRoleStatus(data){
    return axiosClient.post('user/changeStatus', data);
}
export function removeUser(data){
    return axiosClient.post('user/removeUser', data);
}
export function addStrategy(data){
    return axiosClient.post('strategy/addStrategy', data);
}
export function addSignal(data){
    return axiosClient.post('signal/addSignal', data);
}
export function editRole(data){
    return axiosClient.post('user/editRole', data);
}
export function getUser(data){
    return axiosClient.post('user/getUserList', data);
}
export function getUserById(data){
    return axiosClient.post('user/getUserById', data);
}
export function changeUserStatus(data){
    return axiosClient.post('user/changeStatus', data);
}
export function addUser(data){
    return axiosClient.post('user/addUser', data);
}
export function getCountryList(data){
    return axiosClient.post('user/getCountryList', data);
}
export function editUser(data){
    return axiosClient.post('user/editUser', data);
}
export function dashboard(data){
    return axiosClient.post('auth/getDashboard', data);
}
export function getSubAdmin(data){
    return axiosClient.post('subadmin/getSubAdminList', data);
}
export function changeSubAdminStatus(data){
    return axiosClient.post('subadmin/changeStatus', data);
}
export function addSubAdmin(data){
    return axiosClient.post('subadmin/addSubAdmin', data);
}
export function editSubAdmin(data){
    return axiosClient.post('subadmin/editSubAdmin', data);
}
export function getSubAdminById(data){
    return axiosClient.post('subadmin/getSubAdminById', data);
}
export function getPermissions(data){
    return axiosClient.post('subadmin/getPermissions', data);
}
export function getModuleList(data){
    return axiosClient.post('subadmin/getModuleList', data);
}
export function checkPermission(data){
    return axiosClient.post('subadmin/checkPermission', data);
}
export function removeSubAdmin(data){
    return axiosClient.post('subadmin/removeSubAdmin', data);
}
export function getStrategies(data){
    return axiosClient.post('signal/getStrategies', data);
}
export function getSignalList(data){
    return axiosClient.post('signal/getSignalList', data);
}
export function removeSignal(data){
    return axiosClient.post('signal/removeSignal', data);
}
export function changesignalStatus(data){
    return axiosClient.post('signal/changeStatus', data);
}
export function getSignalById(data) {
    return axiosClient.post('signal/getSignalById', data);
}
export function getUserReferralData(data) {
    return axiosClient.post('user/getUserReferralData', data);
}
export function editSignal(data){
    return axiosClient.post('signal/editSignal', data);
}
export function extendSubscription(data){
    return axiosClient.post('user/extendSubscription', data);
}
export function getSymbolList(data) {
    return axiosClient.post('symbol/getSymbolList', data);
}
export function addSymbol(data){
    return axiosClient.post('symbol/addSymbol', data);
}
export function getSymbols(data){
    return axiosClient.get('signal/getSymbols',data);
}
export function getCategories(data){
    return axiosClient.post('signal/getCategories',data);
}
export function addCategory(data){
    return axiosClient.post('category/addCategory', data);
}
export function getCategoryList(data){
    return axiosClient.post('category/getCategoryList',data);
}
export function importSheet(data){
    return axiosClient.post('symbol/importSheet',data);
}
export function addTag(data){
    return axiosClient.post('tag/addTag', data);
}
export function getTagList(data){
    return axiosClient.post('tag/getTagList',data);
}
export function getTags(data){
    return axiosClient.post('strategy/getTags',data);
}
export function getStrategyById(data){
    return axiosClient.post('strategy/getStrategyById',data);
}
export function getSignalData(data){
    return axiosClient.post('strategy/getSignalData',data);
}
export function changeStartegyStatus(data){
    return axiosClient.post('strategy/changeStatus',data);
}
export function removeStrategy(data){
    return axiosClient.post('strategy/removeStrategy',data);
}
export function editStrategy(data){
    return axiosClient.post('strategy/editStrategy',data);
}
export function changeSymbolStatus(data){
    return axiosClient.post('symbol/changeStatus',data);
}
export function removeSymbol(data){
    return axiosClient.post('symbol/removeSymbol',data);
}

export function getSymbolDataById(data){
    return axiosClient.post('symbol/getSymbolDataById',data);
}
export function editSymbol(data){
    return axiosClient.post('symbol/editSymbol',data);

}
export function changeTagStatus(data){
    return axiosClient.post('tag/changeStatus',data);
}
export function removeTag(data){
    return axiosClient.post('tag/removeTag',data);
}

export function getTagById(data){
    return axiosClient.post('tag/getTagById',data);
}
export function editTag(data){
    return axiosClient.post('tag/editTag',data);
}
export function changeCategoryStatus(data){
    return axiosClient.post('category/changeStatus',data);
}
export function removeCategory(data){
    return axiosClient.post('category/removeCategory',data);
}
export function getCategoryById(data){
    return axiosClient.post('category/getCategoryById',data);
}
export function editCategory(data){
    return axiosClient.post('category/editCategory',data);
}
export function changeBasketStatus(data){
    return axiosClient.post('basket/changeStatus',data);
}
export function removeBasket(data){
    return axiosClient.post('basket/removeBasket',data);
}
export function getBasketById(data){
    return axiosClient.post('basket/getBasketById',data);
}
export function editBasket(data){
    return axiosClient.post('basket/editBasket',data);
}
export function addBasket(data){
    return axiosClient.post('basket/addBasket',data);
}
export function getBasketList(data){
    return axiosClient.post('basket/getBasketList',data);
}
export function addLastTradePrice(data){
    return axiosClient.post('signal/addLastTradePrice',data);
}

export function addWatchList(data){
    return axiosClient.post('watchlist/addWatchList',data);
}
export function getWatchList(data){
    return axiosClient.post('watchlist/getWatchList',data);
}
export function changeWatchListStatus(data){
    return axiosClient.post('watchlist/changeStatus',data);
}
export function removeWatchList(data){
    return axiosClient.post('watchlist/removeWatchList',data);
}
export function editWatchList(data){
    return axiosClient.post('watchlist/editWatchList',data);
}
export function getWatchlistById(data){
    return axiosClient.post('watchlist/getWatchlistById',data);
}
export function changeLabelStatus(data){
    return axiosClient.post('label/changeStatus',data);
}
export function removeLabel(data){
    return axiosClient.post('label/removeLabel',data);
}

export function getLabelById(data){
    return axiosClient.post('label/getLabelById',data);
}
export function editLabel(data){
    return axiosClient.post('label/editLabel',data);
}
export function getLabelList(data){
    return axiosClient.post('label/getLabelList',data);
}
export function addLabel(data){
    return axiosClient.post('label/addLabel',data);
}
export function getSectorList(data) {
    return axiosClient.post('symbol/getSectorList', data);
}
export function getSubSectorList(data) {
    return axiosClient.post('symbol/getSubSectorList', data);
}
export function getAdminNames(data) {
    return axiosClient.post('signal/getAdminNames', data);
}
export function getPerfomanceAnalyticsData(data) {
    return axiosClient.post('signal/getPerfomanceAnalyticsData', data);
}
export function getAnalyticsData(data) {
    return axiosClient.post('signal/getAnalyticsData', data);
}
export function getNotificationList(data) {
    return axiosClient.post('notification/getNotificationList', data);
}
export function editWebinar(data) {
    return axiosClient.post('webinar/editWebinar', data);
}
export function getWebinarDetails(data) {
    return axiosClient.post('webinar/getWebinarDetails', data);
}
export function addTheme(data) {
    return axiosClient.post('theme/addTheme', data);
}
export function getThemeList(data) {
    return axiosClient.post('theme/getThemeList', data);
}
export function changeThemeStatus(data){
    return axiosClient.post('theme/changeStatus',data);
}
export function removeTheme(data){
    return axiosClient.post('theme/removeTheme',data);
}
export function getThemeById(data){
    return axiosClient.post('theme/getThemeById',data);
}
export function editTheme(data){
    return axiosClient.post('theme/editTheme',data);
}
export function getSubSectors(data) {
    return axiosClient.post('symbol/getSubSectors', data);
}
export function getSectors(data) {
    return axiosClient.post('symbol/getSectors', data);
}
export function importSectorSheet(data) {
    return axiosClient.post('symbol/importSectorSheet', data);
}
export function importMarketSheet(data){
    return axiosClient.post('market/importMarketSheet',data);
}
export function getMarketList(data){
    return axiosClient.post('market/getMarketList',data);
}
export function addMarketCategory(data){
    return axiosClient.post('market/addMarketCategory', data);
}
export function getMarketCategoryList(data){
    return axiosClient.post('market/getMarketCategoryList',data);
}
export function changeMarketCategoryStatus(data){
    return axiosClient.post('market/changeStatus',data);
}
export function removeMarketCategory(data){
    return axiosClient.post('market/removeMarketCategory',data);
}
export function getMarketCategoryById(data){
    return axiosClient.post('market/getMarketCategoryById',data);
}
export function editMarketCategory(data){
    return axiosClient.post('market/editMarketCategory',data);
}
export function getLTP(data){
    return axiosClient.post('watchlist/getLTP',data);
}
export function getFnOList(data){
    return axiosClient.post('fno/getFnOList',data);
}
export function getFnOSymbols(data){
    return axiosClient.post('fno/getFnOSymbols',data);
}
export function getSymbolExpiryList(data){
    return axiosClient.post('fno/getSymbolExpiryList',data);
}
export function getSymbolMasterList(data){
    return axiosClient.post('fno/getSymbolMasterList',data);
}
export function getOptionsSymbolList(data){
    return axiosClient.post('fno/getOptionsSymbolList',data);
}
export function changeBannedStatus(data){
    return axiosClient.post('fno/changeBannedStatus',data);
}
export function removeFno(data){
    return axiosClient.post('fno/removeFno',data);
}
export function addFno(data){
    return axiosClient.post('fno/addFno',data);
}
export function editFno(data){
    return axiosClient.post('fno/editFno',data);
}
export function getFnoById(data){
    return axiosClient.post('fno/getFnoById',data);
}
export function editWebsite(data) {
    return axiosClient.post('webinar/editWebsiteSettings', data);
}
export function getWebsiteDetails(data) {
    return axiosClient.post('webinar/getWebsiteDetails', data);
}
export function addHistoricalSignal(data) {
    return axiosClient.post('signal/addHistoricalSignal', data);
}
export function editHistoricalSignal(data) {
    return axiosClient.post('signal/editHistoricalSignal', data);
}
export function getSwMemberList(data) {
    return axiosClient.post('user/getSwMemberList', data);
}
export function importSWMembersData(data) {
    return axiosClient.post('user/importSWMembersData', data);
}
export function closeSignal(data) {
    return axiosClient.post('signal/closeSignal', data);
}
export function editAppVersion(data) {
    return axiosClient.post('webinar/editAppVersion', data);
}
export function getAppVersionDetails(data) {
    return axiosClient.post('webinar/getAppVersionDetails', data);
}
export function getKycCompletedUsers(data) {
    return axiosClient.get('user/getKycCompletedUsers', data);
}