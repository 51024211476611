import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dashboard } from '../../api/apiHandler';
import { Helmet } from 'react-helmet';
import Sidebar from '../../components/sidebar/Sidebar';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';

function Dashboard() {

    const navigate = useNavigate();
    const [userCount, setUserCount] = useState("-")
    const [signalCount, setSignalCount] = useState("-")
    const [symbolCount, setSymbolCount] = useState("-")
    const [strategyCount,setStrategyCount] = useState("-")
    const [categorCount,setCategorCount] = useState("-")
    const [tagCount,setTagCount] = useState("-")
    const [basketCount,setBasketCount] = useState("-")
    const [stockCount,setStockCount] = useState("-")
    const [watchlistCount,setWatchListCount] = useState("-")
    const [PastPerfomanceCount,setPastPerfomanceCount] = useState("-")



    useEffect(() => {
        dashboard({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setUserCount(response.data.user_count)
                setSignalCount(response.data.signal_count)
                setSymbolCount(response.data.symbol_count)
                setStrategyCount(response.data.strategy_count)
                setCategorCount(response.data.category_count)
                setTagCount(response.data.tag_count)
                setBasketCount(response.data.basket_count)
                setStockCount(response.data.stock_database_count)
                setWatchListCount(response.data.watchlist_count)
                setPastPerfomanceCount(response.data.past_perfomance_count)
            }
        })
    }, [])

    function userPage() {
        navigate("/user")
    }

    function signalPage() {
        navigate("/signal")
    }

    function symbolPage() {
        navigate("/symbol")
    }

    function strategyPage() {
        navigate("/strategy")
    }

    function categoryPage() {
        navigate("/category")
    }

    function tagPage() {
        navigate("/tag")
    }
   

    return (
        <>
            <main>
                <div className='content-page'>
                    <div className='content'>
                        <div className='container-fluid'>
                            <div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="page-title-box">
                                            <h4 className="page-title">Dashboard</h4>
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item active">
                                                    Welcome to StockWiz Dashboard
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" onClick={userPage}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-account float-right"></i>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className="text-white">
                                                        <h6 className="text-uppercase mb-3">Total Users</h6>
                                                        <h4 className="mb-4">{userCount}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" onClick={strategyPage}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-rotate-right-variant float-right"></i>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className="text-white">
                                                        <h6 className="text-uppercase mb-3">Total Startegies</h6>
                                                        <h4 className="mb-4">{strategyCount}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" onClick={signalPage}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-signal float-right"></i>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className="text-white">
                                                        <h6 className="text-uppercase mb-3">Total Signals</h6>
                                                        <h4 className="mb-4">{signalCount}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-3">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" onClick={symbolPage}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-shape-plus float-right"></i>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className="text-white">
                                                        <h6 className="text-uppercase mb-3">Total Symbols</h6>
                                                        <h4 className="mb-4">{symbolCount}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" onClick={categoryPage}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-layers float-right"></i>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className="text-white">
                                                        <h6 className="text-uppercase mb-3">Total Categories</h6>
                                                        <h4 className="mb-4">{categorCount}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-3">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" onClick={tagPage}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-tag-multiple float-right"></i>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className="text-white">
                                                        <h6 className="text-uppercase mb-3">Total Tags</h6>
                                                        <h4 className="mb-4">{tagCount}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-3">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" onClick={()=> navigate("/basket")}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-basket float-right"></i>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className="text-white">
                                                        <h6 className="text-uppercase mb-3">Total Baskets</h6>
                                                        <h4 className="mb-4">{basketCount}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" onClick={()=> navigate("/stock_database")}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-database float-right"></i>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className="text-white">
                                                        <h6 className="text-uppercase mb-3">Total StockDatabase</h6>
                                                        <h4 className="mb-4">{stockCount}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" onClick={()=> navigate("/watchlist")}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-eye float-right"></i>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className="text-white">
                                                        <h6 className="text-uppercase mb-3">Total WatchList</h6>
                                                        <h4 className="mb-4">{watchlistCount}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="card mini-stat bg-primary">
                                            <div className="card-body mini-stat-img" onClick={()=> navigate("/past_perfomance")}>
                                                <div className="mini-stat-icon">
                                                    <i className="mdi mdi-speedometer float-right"></i>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className="text-white">
                                                        <h6 className="text-uppercase mb-3">Total PastPerfomance</h6>
                                                        <h4 className="mb-4">{PastPerfomanceCount}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default Dashboard;