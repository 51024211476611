/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getSignalById } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export default function ViewTradeLog() {
    let { id } = useParams();
    const [signalDetails, setSignalDetails] = useState(null)
    const [chartImage, setChartImage] = useState(null);
    const [reportPDF, setReportPDF] = useState(null);

    useEffect(() => {
        console.log();
        if (id != undefined) {
            getSignalById({ "signal_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setSignalDetails(res.data)
                    setChartImage(res.data.chart_image)
                    setReportPDF(res.data.report_pdf)
                }
            });

        }
    }, [id])
    const image = chartImage != null ? `${process.env.REACT_APP_S3URL}/chart_image/${chartImage}` : 'https://www.islgroups.com/images/3.jpg';
    const pdf = reportPDF != null ? `${process.env.REACT_APP_S3URL}/report_pdf/${reportPDF}` : 'https://stockwiz-dev.s3.ap-south-1.amazonaws.com/report_pdf/1696314207896150.pdf';
    if (signalDetails == null) return <> </>
    return (

        <><div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid'>
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>View Tradelog</h5>
                        <hr></hr>
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label class="form-label">Tradelog Name</label><br />
                                    <span class="font-15">{signalDetails.name}</span>
                                </div>
                                <div className='col-md-6'>
                                    <label class="form-label">Tradelog Category</label><br />
                                    <span class="font-15">{signalDetails.category}</span>
                                </div>

                            </div>
                        </div>
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label class="form-label">Tradelog Type</label><br />
                                    <span class="font-15">{signalDetails.type == 1 ? "Free" : "Premium"}</span>
                                </div>
                                <div className='col-md-6'>
                                    <label class="form-label">Entry Price</label><br />
                                    <span class="font-15">{signalDetails.entry_price}</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label class="form-label">Stop Loss / Risk</label><br />
                                    <span class="font-15">{signalDetails.risk} {signalDetails.risk_type == "Price" ? "Rs" : "%"}</span>
                                </div>
                                <div className='col-md-6'>
                                    <label class="form-label">Target Price</label><br />
                                    <span class="font-15">{signalDetails.target_price} {signalDetails.target_price_type == "Price" ? "Rs" : "%"}</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label class="form-label">Trade Category</label><br />
                                    <span class="font-15">{signalDetails.trade_category}</span>
                                </div>
                                <div className='col-md-6'>
                                    <label class="form-label">Symbol</label><br />
                                    <span class="font-15">{signalDetails.symbol}</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label class="form-label">TradeLog Risk</label><br />
                                    <span class="font-15">{signalDetails.tradelog_risk} {signalDetails.tradelog_risk_type == "Price" ? "Rs" : "%"}</span>
                                </div>
                                <div className='col-md-6'>
                                    <label class="form-label"> Strategies </label><br />
                                    <span class="font-15">{signalDetails.strategies}</span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label class="form-label"> Buying Zone (In %) </label><br />
                                    <span class="font-15">{signalDetails.buy_percentage}</span>
                                </div>
                                <div className='col-md-6'>
                                    <label class="form-label"> Selling Zone (In %) </label><br />
                                    <span class="font-15">{signalDetails.sell_percentage}</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label class="form-label"> Segment </label><br />
                                    <span class="font-15">{signalDetails.segment}</span>
                                </div>
                                <div className='col-md-6'>
                                    <label class="form-label">Trade Direction</label><br />
                                    <span class="font-15">{signalDetails.trade_direction == 1 ? "LONG" : "SHORT"}</span>
                                </div>
                            </div>
                        </div>
                    </div></div></div></div>

            {/* popup model */}
            <div id="show_image_popup">
                <div class="close-btn-area">

                    <button id="close-btn">X</button>
                </div>
                <div id="image-show-area">
                    <img id="large-image" src="" alt="">
                    </img></div>
            </div></>
    )
}