import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addTag, getUser } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Select from 'react-select';


export default function AddNotification() {

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter tag").max(128, "Tag must be at most 128 character")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [userList, setUserList] = useState(null)

    const navigate = useNavigate();

    function redirect() {
        navigate("/notification")
    }
    useEffect(() => {



        getUser({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setUserList(response.data)
            } else {
                setUserList([])
            }
        })


    }, [])

    const onSubmit = (data) => {

        addTag({ "name": data.name.trimStart().trimEnd() }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/tag")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }
    const handleSelectChange = (newValue) => {
        setSelectedOptions(newValue);
        setSelectAllChecked(newValue.length === userList.length);
    };

    const handleCheckboxChange = () => {

        const userOptions = userList && userList.map((item) => ({ value: item._id, label: item.first_name + " " + item.last_name }))

        // If "Select All" is checked, clear the selection; otherwise, select all options
        const newSelectedOptions = selectAllChecked ? [] : userOptions;

        setSelectedOptions(newSelectedOptions);

        // Toggle the "Select All" checkbox state
        setSelectAllChecked((prev) => !prev);
    };
    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Add Notification</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <label htmlFor="name" className="form-label">Title</label>
                                        <input type="text" id="title" className="form-control" placeholder="Title" {...register('title')} />
                                        <div className="forerror">
                                            <span className="validation-error text-justify">{errors.title?.message}</span>
                                        </div>
                                    </div>


                                    <div className="col-lg-12 mb-3">
                                        <label htmlFor="example-textarea" className="form-label">Message</label>
                                        <textarea className="form-control" id="example-textarea" placeholder="Message"  {...register('content')} rows="5"></textarea>
                                        <div className="forerror">
                                            <span className="validation-error text-justify">{errors.content?.message}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">

                                    <div className="col-lg-12">
                                        <label htmlFor="userlist" className="form-label">Select User</label>

                                        <Select
                                            aria-labelledby="aria-label"
                                            placeholder="Select User"
                                            value={selectedOptions} // Set the selected options
                                            onChange={handleSelectChange}
                                            options={userList && userList.map((item) => {
                                                return ({
                                                    value: item._id,
                                                    label: item.first_name + " " + item.last_name,
                                                })
                                            })}
                                            // isSearchable={true} // Enables search functionality
                                            isMulti
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        />
                                        <div className="forerror">
                                            <span className="validation-error text-justify">{errors.users?.message}</span>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 mb-3">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" name="selectall" id="selectall"
                                                checked={selectAllChecked}
                                                onChange={handleCheckboxChange} />
                                            <label className="form-check-label" htmlFor="checkbox-signin">Select All Users</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-6  offset-md-5">
                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Add
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>
                                    </div>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
