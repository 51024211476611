import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSymbolList } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2'




export default function StockList() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [file, setFile] = useState(null);
    const [symbolList, setSymbolList] = useState(null)
    const [symbolRemove, setSymbolRemove] = useState(0)
    const [symbolChangeStatus, setSymbolChangeStatus] = useState(0)
    const [symbolView, setSymbolView] = useState(0)
    const [symbolAdd, setSymbolAdd] = useState(0)
    const [symbolEdit, setSymbolEdit] = useState(0)
    const [symbolImport, setSymbolImport] = useState(0)
    const fileInputRef = useRef(null);


    useEffect(() => {
        getSymbolList({ "sub_sector_id": id }).then((response) => {
            Swal.close()
            response = JSON.parse(response)
            
            if (response.code == 200) {
                setSymbolList(response.data)
            } else {
                setSymbolList([])
            }
        })

    }, [id])
    console.log(symbolList,"========");
    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 5,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'copy',
                    className: 'btn-mute'
                }, {
                    extend: 'csv',
                    className: 'btn-mute'
                }, {
                    extend: 'print',
                    className: 'btn-mute'
                }],
                "bDestroy": true,
            }
        );
    }, [symbolList])

    if (symbolList == null) return <> </>
    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>

                            {/* Back Button */}
                            <button className="button mb-2" onClick={() => navigate(-1)}>
                                <div className="button-box">
                                    <span className="button-elem">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 40">
                                            <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"></path>
                                        </svg>
                                    </span>
                                    <span className="button-elem">
                                        <svg viewBox="0 0 46 40">
                                            <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"></path>
                                        </svg>
                                    </span>
                                </div>
                            </button>

                            <h5> Stock List </h5>
                            <hr></hr>
                            <table id="mangeUser" className="table table-hover dataTable js-exportable">
                            <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Scrip Name</th>
                                        <th style={{ textAlign: "center" }}>Scrip Symbol</th>
                                        <th style={{ textAlign: "center" }}>Sector</th>
                                        <th style={{ textAlign: "center" }}>SubSector</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Scrip Name</th>
                                        <th style={{ textAlign: "center" }}>Scrip Symbol</th>
                                        <th style={{ textAlign: "center" }}>Sector</th>
                                        <th style={{ textAlign: "center" }}>SubSector</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    {
                                        symbolList && symbolList.symbol_list.map((item, index) => {
                                            return (
                                                <tr id={"symbolId" + item._id} >
                                                    <td style={{ textAlign: "center" }}>
                                                        {index + 1}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.scrip_name}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.scrip_symbol}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.sector_name}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.sub_sector_name}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

