import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addFno } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export default function AddFnO() {

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter name").max(128, "Name must be at most 128 character"),
        symbol: Yup.string().required("Please enter symbol").max(128, "Symbol must be at most 128 character")

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate("/tag")
    }

    const onSubmit = (data) => {

        addFno({ "name": data.name.trimStart().trimEnd() ,"symbol": data.symbol.trimStart().trimEnd() }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/fno")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-4 col-md-4 col-sm-4'>
                        <h5>Add Future Option Symbol </h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="form-group">
                                    <label> Name </label>
                                    <div>
                                        <input type="text" {...register('name')} className="form-control" placeholder="Please enter name" />
                                        <div className="invalid-feedback">{errors.name?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label> Symbol </label>
                                    <div>
                                        <input type="text" {...register('symbol')} className="form-control" placeholder="Please enter symbol" />
                                        <div className="invalid-feedback">{errors.symbol?.message}</div>
                                    </div>
                                </div>
                               
                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Add
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
