
import ExcelJS from 'exceljs';
import { getLTP, getOptionsSymbolList, getSymbolDataById, getSymbolMasterList, login } from '../api/apiHandler';
import S3 from 'react-aws-s3';

export function exportExcelFile(column, Data, fileName) {

    const workbook = new ExcelJS.Workbook();
    const workSheet = workbook.addWorksheet("Report-Data");
    workSheet.columns = column

    const headerRow = workSheet.getRow(1);

    //For header
    headerRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFC0C0C0' }, // Light gray color for the header background
        };
        cell.alignment = { horizontal: 'center' };
    });

    //adding the data ro sheet
    Data.forEach(element => {
        workSheet.addRow(element)
    });

    // Center align the cells
    workSheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.eachCell({ includeEmpty: true }, function (cell) {
            cell.alignment = { horizontal: 'center' };
        });
    });


    workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(url);
    });

}

export function excelUpload(file) {

    const workbook = new ExcelJS.Workbook();
    const reader = new FileReader();


    return new Promise((resolve, reject) => {
        reader.onload = async (e) => {
            const arrayBuffer = e.target.result;
            const data = new Uint8Array(arrayBuffer);

            try {
                await workbook.xlsx.load(data);

                // Assuming the first sheet is the one you want to read
                const sheet = workbook.getWorksheet(1);
                const dataArray = [];

                // Iterate through rows and cells
                sheet.eachRow((row, rowNumber) => {
                    if (rowNumber !== 1) {
                        dataArray.push(row.values)
                    }
                });

                resolve(dataArray);
            } catch (error) {
                reject(new Error('Error reading Excel file:', error.message));
            }
        };

        reader.readAsArrayBuffer(file);
    });
}



export function removeSessionStorageData() {
    sessionStorage.clear()
}
export function getStockLTP(id) {
    return new Promise((resolve, reject) => {
        getSymbolDataById({ "symbol_id": id }).then((symbolRes) => {
            symbolRes = JSON.parse(symbolRes);
            if (symbolRes.code == 200) {
                getLTP({ "symbol": symbolRes.data.scrip_symbol, "main_symbol": symbolRes.data.scrip_symbol }).then((response) => {
                    response = JSON.parse(response);
                    if (response.code == 200) {
                        resolve({ "ltp": response.data.ltp, "symbol": symbolRes.data.scrip_symbol, "scrip_logo": response.data.scrip_logo }); // Resolve the promise with LTP value
                    } else {
                        resolve(null); // Resolve with null if LTP is not available
                    }
                }).catch((error) => {
                    reject(error); // Reject if there's an error in fetching LTP
                });
            } else {
                resolve(null); // Resolve with null if symbol data is not available
            }
        }).catch((error) => {
            reject(error); // Reject if there's an error in fetching symbol data
        });
    });
}

export function getFNOStockLTP(symbol, mainSymbol) {
    return new Promise((resolve, reject) => {
        console.log("New Trade : ",symbol);
        getLTP({ "symbol": symbol, 'main_symbol': mainSymbol }).then((response) => {
            response = JSON.parse(response);
            if (response.code == 200) {
                resolve({ "ltp": response.data.ltp, "symbol": symbol, "scrip_logo": response.data.scrip_logo }); // Resolve the promise with LTP value
            } else {
                resolve(null); // Resolve with null if LTP is not available
            }
        }).catch((error) => {
            reject(error); // Reject if there's an error in fetching LTP
        });
    });
}

export function getSymbolTrades(symbol) {
    return new Promise((resolve, reject) => {
        getSymbolMasterList({ "symbol": symbol }).then((response) => {
            response = JSON.parse(response);
            if (response.code == 200) {
                resolve(response.data); // Resolve the promise with LTP value
            } else {
                resolve(null); // Resolve with null if LTP is not available
            }
        }).catch((error) => {
            reject(error); // Reject if there's an error in fetching LTP
        });
    });
}


export function getOptionsTrades(symbol, segment, tradeType, expiry) {
    return new Promise((resolve, reject) => {
        getOptionsSymbolList({ "symbol": symbol, "segment": segment, "trade_type": tradeType, "expiry_date": expiry }).then((response) => {
            response = JSON.parse(response);
            // console.log("OPTION TRADES ::: ",response);
            if (response.code == 200) {
                resolve(response.data); // Resolve the promise with LTP value
            } else {
                resolve(null); // Resolve with null if LTP is not available
            }
        }).catch((error) => {
            reject(error); // Reject if there's an error in fetching LTP
        });
    });
}

export function setSessionStorageData(data) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    sessionStorage.setItem("AdminTimezone", timeZone);
    sessionStorage.setItem("AdminLogin", true);
    sessionStorage.setItem("AdminToken", data.token);
    sessionStorage.setItem("AdminName", data.name);
    sessionStorage.setItem("AdminEmail", data.email);
    sessionStorage.setItem("AdminID", data._id);
    sessionStorage.setItem("AdminImage", data.profile_image);
    sessionStorage.setItem("AdminData", data)

}


export function uploadImageS3(params) {
    return new Promise((resolve, reject) => {
        console.log(" params :: ", params);
        const config = {
            bucketName: process.env.REACT_APP_S3_NAME,
            region: process.env.REACT_APP_S3_REGION,
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
            s3Url: process.env.REACT_APP_S3URL,
            dirName: params.dir_name,
        };


        const ReactS3Client = new S3(config);


        const files = params.file;

        var newfile = new Date().getTime() + files.name
        console.log(newfile);
        ReactS3Client.uploadFile(files, newfile).then(data => {
            console.log(data.status, "-----------------");
            if (data.status === 204) {
                resolve(data.location.split("/")[4]);
            } else {
                console.log('obj err')
                reject(null);
            }
        });
    });
}


// export const dateTimeConvert = (ConvertDate, Formate) => {
//     // sessionStorage.setItem("AdminTimezone", timeZone)
//     const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//     const newDate = moment(ConvertDate).tz(timeZone).format(Formate)
//     // console.log(`Old Date = ${ConvertDate} AND New Date ${newDate}`);
//     return newDate;
// }