import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Button, Modal, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { excelUpload } from '../../common/Common';
import { getSwMemberList, importSWMembersData } from '../../api/apiHandler';
import * as Alert from '../../common/Alert';


function SWmemberList() {
    const debounceTimeout = useRef(null);

    const [userList, setUserList] = useState(null)
    const fileInputRef = useRef(null);
    const [showModal, setShowModal] = useState(false)
    const [page, setPage] = useState(1);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handlePageClick = (event) => {
        console.log('event: ', event);
        setPage(event.selected + 1)
    };

    const handleExtensionSubmit = async (event) => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        const file = fileInputRef.current.files[0];
        const uploadData = await excelUpload(file)
        const symbolData = uploadData
            .filter(item => item[2] && item[3] && item[4])
            .map((item, index) => {
                return (
                    {
                        full_name: item[2],
                        country_code: item[3],
                        mobile_number: item[4]
                    }
                )
            })

        importSWMembersData({ 'data': symbolData }).then((res) => {
            res = JSON.parse(res)
            Swal.close();
            if (res.code == 200) {
                setUserList(null)
                fetchList();
                Alert.SuccessAlert(res.message)

            } else {
                Alert.ErrorAlert(res.message)
            }
        });
        handleCloseModal()
    };

    function fetchList() {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        getSwMemberList({ "page": page, "limit": process.env.REACT_APP_RECORD_COUNT, "search": searchQuery }).then((response) => {
            Swal.close()

            response = JSON.parse(response)

            if (response.code == 200) {
                console.log("page", page);
                setUserList(response.data.memberList);
                setTotalRecordCount(Math.ceil(response.data.totalMembers / process.env.REACT_APP_RECORD_COUNT));
            } else {
                setUserList([])
            }
        })
    }

    useEffect(() => {
        fetchList()
    }, [page])

    const handleSearch = (event) => {
        // Clear the existing timeout
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        // Set a new timeout
        debounceTimeout.current = setTimeout(() => {
            setPage(1);
            // Dispatch the action with the updated state
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            getSwMemberList({ "page": page, "limit": process.env.REACT_APP_RECORD_COUNT, "search": event }).then((response) => {
                Swal.close()

                response = JSON.parse(response)

                console.log('totalMembers: ', response.data.totalMembers);
                if (response.code == 200) {
                    console.log("page", page);
                    setUserList(response.data.memberList);
                    setTotalRecordCount(Math.ceil(response.data.totalMembers / process.env.REACT_APP_RECORD_COUNT));
                } else {
                    setUserList([]);
                    setTotalRecordCount(0);
                }
            })
        }, 1000);
    }

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <div className='col-md-2 offset-md-5' style={{ marginLeft: '92%' }}>
                                <button className='add_btn btn-custom' onClick={handleShowModal}><span className="mdi mdi-cloud-upload mdi-18px " ></span></button>
                            </div>
                            <div className="row justify-content-between mb-2 float-right">
                                <div className="col-auto">
                                        <input type="text" className="form-control mt-2" placeholder="Search..." onChange={(e) => { handleSearch(e.target.value); setSearchQuery(e.target.value); }} />
                                        {/* <span className="mdi mdi-magnify"></span> */}
                                </div>
                                <div className="col-md-6">
                                    <div className="text-md-end">
                                        {/* <button type="button" className="btn btn-custom waves-effect waves-light mb-2 me-2"><i className="mdi mdi-basket me-1"></i> Add User</button> */}
                                        {/* <button type="button" className="btn btn-success waves-effect waves-light mb-2 me-1"><i className="mdi mdi-cog"></i></button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap table-borderless table-hover mb-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th style={{ textAlign: "center" }}>ID</th>
                                            <th style={{ textAlign: "center" }}>Full Name</th>
                                            <th style={{ textAlign: "center" }}>Country Code</th>
                                            <th style={{ textAlign: "center" }}>Mobile Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userList && userList.map((item, index) => {
                                                return (
                                                    <tr id={"userId" + item._id} >
                                                        <td style={{ textAlign: "center" }}>
                                                            {index + 1 + ((page - 1) * process.env.REACT_APP_RECORD_COUNT)}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {item.full_name}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {item.country_code}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {item.mobile_number}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination pagination-rounded justify-content-end my-2">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=" > "
                                    onPageChange={handlePageClick}
                                    // pageRangeDisplayed={pageCount}
                                    pageCount={totalRecordCount}
                                    previousLabel=" < "
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination"
                                    pageLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    disabledClassName="disabled"
                                    activeClassName="active"
                                    activeLinkClassName="active-link"
                                />
                            </ul>
                            <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Import Excel File</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group controlId="excelFile">
                                            <Form.Label>Upload Excel File</Form.Label>
                                            <Form.Control type="file" ref={fileInputRef} name="excelFile" accept=".xlsx" />

                                        </Form.Group>
                                        <Button variant="primary" className="btn btn-primary mt-2 ml-1" onClick={handleExtensionSubmit} type="button">
                                            Submit
                                        </Button>
                                        <a href='/assets/demo/Sample_Database_Stocks.xlsx' className='mt-2 ml-1 btn btn-info' download="Sample_Database_Stocks.xlsx">
                                            Download Demo File
                                        </a>
                                    </Form>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SWmemberList;