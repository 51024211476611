import React, { useEffect, useState } from 'react'
import { getFaq,changeFaqStatus, removeFaq , getSubAdminById} from '../../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';

function FaqList() {
    const navigate = useNavigate();
    
    const [faqList, setFaqList] = useState(null)
    const [faqAdd,setFaqAdd]=useState(0)
    const [faqEdit,setFaqEdit] = useState(0)
    const [faqRemove,setFaqRemove]=useState(0)
    const [faqChangeStatus,setFaqChangeStatus]=useState(0)

    useEffect(() => {

        if (localStorage.getItem("Arole") == "S") {  
        setFaqAdd(1)
            setFaqEdit(1)
            setFaqRemove(1)
            setFaqChangeStatus(1)
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
            Swal.showLoading()
            }
        })
        getFaq({}).then((response) => {
            Swal.close()
            response = JSON.parse(response)
            if (response.code == 200) {
                setFaqList(response.data)
            }else{
                setFaqList([])
            }
        })
        }
        else{
            getFaq({}).then((response) => {
                Swal.close()
                response = JSON.parse(response)
                if (response.code == 200) {
                    setFaqList(response.data)
                    getSubAdminById({ "sub_admin_id": localStorage.getItem('Aid') }).then((res) => {
                        res = JSON.parse(res)
                        if (res.code == 200) {
                            setFaqAdd(res.data.permissions.filter(module => module.class_name === 'FAQ' && module.is_create === 1).length > 0)
                            setFaqEdit(res.data.permissions.filter(module => module.class_name === 'FAQ' && module.is_edit === 1).length > 0)
                            setFaqRemove(res.data.permissions.filter(module => module.class_name === 'FAQ' && module.is_delete === 1).length > 0)
                            setFaqChangeStatus(res.data.permissions.filter(module => module.class_name === 'FAQ' && module.is_change_status === 1).length > 0)
                        }
                    });
                }else{
                    setFaqList([])
                }
            })
        }
    }, [])

    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'copy',
                    className: 'btn-mute'
                  },{
                    extend: 'csv',
                    className: 'btn-mute'
                  },{
                    extend: 'print',
                    className: 'btn-mute'
                  }],
                "bDestroy": true,
            }
        );
    }, [faqList])

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function changeStatus(faqId, item, position) {
        if(faqChangeStatus == 1){

            const status = (item.is_active == "1") ? "0" : "1"
        
            changeFaqStatus({ "faq_id": faqId, "is_active": status }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    showMessage(response.message)
                    faqList[position].is_active = status
                    const data = renderToStaticMarkup(renderStatusUiText(faqList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                    $('#mangeUser').DataTable().row("#faqId" + faqId).cell("#faqStatus" + faqId).data(data).draw(false);
    
                }
            }); 
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    
    function renderStatusUiText(item) {
        return (
            item.is_active == "1" ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    function deleteUserIdWise(faqId) {
        if(faqRemove == 1){

            Swal.fire({
                title: 'Are you sure you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#02C9D6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    // console.log("faqId " + faqId)
                    removeFaq({ "faq_id": faqId, "status": "Deleted" }).then((response) => {
                        response = JSON.parse(response)
                        if (response.code == 200) {
                            $('#mangeUser').DataTable().row("#faqId" + faqId).remove().draw(false);
                            showMessage(response.message)
                        }
                    });
                }
            })
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    const editFaq = (item) => {
        if(faqEdit == 1){

            navigate(`/faq/edit/${item._id}`);
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    const addFaq = () => {
        if(faqAdd == 1){

            navigate(`/faq/add`);
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    if (faqList == null) return <> </>

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                        <button className='add btn-custom' onClick={addFaq}><span className="mdi mdi-plus"></span></button>
                            <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                <thead>
                                    
                                    <tr>
                                        <th style={{textAlign:"center"}}>ID</th>
                                        <th style={{textAlign:"center"}}>Question</th>
                                        <th style={{textAlign:"center"}}>Answer</th>
                                        <th style={{textAlign:"center"}}>Status</th>
                                        <th style={{textAlign:"center"}}>Status Action</th>
                                        <th style={{textAlign:"center"}}>Action</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th style={{textAlign:"center"}}>ID</th>
                                        <th style={{textAlign:"center"}}>Question</th>
                                        <th style={{textAlign:"center"}}>Answer</th>
                                        <th style={{textAlign:"center"}}>Status</th>
                                        <th style={{textAlign:"center"}}>Status Action</th>
                                        <th style={{textAlign:"center"}}>Action</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    {
                                        faqList && faqList.map((item, index) => {
                                            return (
                                                <tr id={"faqId" + item._id} >
                                                    <td style={{textAlign:"center"}}>
                                                        {index+1}
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                        {item.question}
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                        {item.answer}
                                                    </td>
                                                    <td id={"faqStatus" + item._id} style={{textAlign:"center"}}>
                                                        {renderStatusUiText(item)}
                                                    </td>
                                                    <td id={"faqStatus"+item._id} style={{textAlign:"center"}}>
                                                        <Tooltip title="Change Status">
                                                            <IconButton onClick={()=>{changeStatus(item._id,item,index)}}>                                        
                                                                <FeatherIcon icon="repeat" width="18" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                                                                
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={() => { editFaq(item) }}>
                                                                <FeatherIcon icon="edit" width="18" />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={()=>{deleteUserIdWise(item._id)}}>                                         
                                                                <FeatherIcon icon="trash-2" width="18" />
                                                            </IconButton>
                                                        </Tooltip>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FaqList;