import React, { useEffect, useState } from 'react'
import { getWatchList, getSubAdminById, editTag, removeWatchList, changeWatchListStatus } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import { watchListCategoryOptions } from '../../globals/constant';

function WatchList() {
    const navigate = useNavigate();

    const [watchlistList, setWatchListList] = useState(null)
    const [watchlistAdd, setWatchListAdd] = useState(0)
    const [watchlistView, setWatchListView] = useState(0)


    useEffect(() => {
        if (localStorage.getItem("Arole") == "S") {
            setWatchListAdd(1)
           
            setWatchListView(1)
            getWatchList({}).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    setWatchListList(response.data)
                } else {
                    setWatchListList([])
                }
            })
        }
        else {
            getWatchList({}).then((response) => {

                response = JSON.parse(response)
                if (response.code == 200) {
                    setWatchListList(response.data)
                    getSubAdminById({ "sub_admin_id": localStorage.getItem('Aid') }).then((res) => {
                        res = JSON.parse(res)
                        if (res.code == 200) {
                            setWatchListAdd(res.data.permissions.filter(module => module.class_name === 'watchlist' && module.is_create === 1).length > 0)
                         
                            setWatchListView(res.data.permissions.filter(module => module.class_name === 'watchlist' && module.is_view === 1).length > 0)
                        }
                    });
                } else {
                    setWatchListList([])
                }
            })
        }

    }, [])

    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 5,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'copy',
                    className: 'btn-mute'
                }, {
                    extend: 'csv',
                    className: 'btn-mute'
                }, {
                    extend: 'print',
                    className: 'btn-mute'
                }],
                "bDestroy": true,
            }
        );
    }, [watchlistList])



    const addWatchList = () => {
        if (watchlistAdd == 1) {
            navigate(`/watchlist/add`);
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    const viewWatchlist = (item) => {
        if (watchlistView == 1) {
            navigate(`/watchlist_stocks/${item.label}`);
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
   

    
    if (watchlistList == null) return <> </>
    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <button className='add btn-custom' onClick={addWatchList}><span className="mdi mdi-plus"></span></button>
                            <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Trade Category</th>
                                        <th style={{ textAlign: "center" }}>Action</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Trade Category</th>
                                        <th style={{ textAlign: "center" }}>Action</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    {
                                        watchListCategoryOptions.map((item, index) => {
                                            return (
                                                <tr id={"watchlistId" + item._id} >
                                                    <td style={{ textAlign: "center" }}>
                                                        {index + 1}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.label}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                       
                                                        <Tooltip title="View">
                                                            <IconButton onClick={() => viewWatchlist(item)}>
                                                                <FeatherIcon icon="eye" width="18" /> {/* Replace with your desired view icon */}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            )
                                          
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WatchList;