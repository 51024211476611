import React, { useEffect, useState } from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { editFaq,getFaq } from '../../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export default function EditFaq() {

    let { id } = useParams();    
    const [faqDetails, setFaqDetails] = useState(null)

    useEffect(() => {
        console.log("ID : ",id);
        if(id != undefined){
            getFaq({"faq_id":id}).then((res)=>{
                res = JSON.parse(res)
                if(res.code == 200){
                    setFaqDetails(res.data[0])
                }
            }); 
        }      
    }, [id])

    const validationSchema = Yup.object().shape({
        question: Yup.string().required("Please enter question"),
        answer: Yup.string().required("Please enter answer"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect(){
        navigate("/faq")
    }
     
    const onSubmit = (data) => {
       
        editFaq({"faq_id":id,"question":data.question.trimStart().trimEnd(),"answer":data.answer.trimStart().trimEnd()}).then((response)=>{
            response = JSON.parse(response)
            if(response.code == 200){
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(()=>{
                    navigate("/faq")
                },2000)
            }else{
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    if ( faqDetails == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-8 col-md-8 col-sm-8'>
                        <h5>Edit FAQ's Details</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="form-group">
                                    <label> Question </label>
                                    <div>
                                        <input type="text" {...register('question')} className="form-control" defaultValue={faqDetails.question}  placeholder="Please Enter Question" />
                                        <div className="invalid-feedback">{errors.question?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label> Answer </label>
                                    <div>
                                        <input type="text" {...register('answer')} className="form-control" defaultValue={faqDetails.answer}  placeholder="Please Enter Answer" />
                                        <div className="invalid-feedback">{errors.answer?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    
                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>
                                    
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}