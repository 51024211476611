/* eslint-disable eqeqeq */
import React, { useEffect, useState, userRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addSignal, getCategories, getStrategies, getSymbols } from '../../api/apiHandler';
import { segmentOptions } from '../../globals/constant';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Select from 'react-select';
import S3 from 'react-aws-s3';

window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
    bucketName: process.env.REACT_APP_S3_NAME,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    s3Url: process.env.REACT_APP_S3URL,
    dirName: "chart_image",
};
const configPDF = {
    bucketName: process.env.REACT_APP_S3_NAME,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    s3Url: process.env.REACT_APP_S3URL,
    dirName: "report_pdf",
};
const ReactS3ClientPDF = new S3(configPDF);
const ReactS3Client = new S3(config);

export default function AddTradeLog() {

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter tradelog name").max(128, "Name must be at most 128 character"),
        trade_category: Yup.object().required("Please select trade category"),
        symbols: Yup.object().required("Please select symbol"),
        segment: Yup.object().required("Please select tradelog segment"),
        strategy: Yup.array().required("Please select strategies"),
        buying_zone: Yup.string().required("Please enter buy percentage").matches('^[0-9]', 'Only numbers are allowed for this field'),
        selling_zone: Yup.string().required("Please enter sell percentage").matches('^[0-9]', 'Only numbers are allowed for this field'),
        // chart_image: Yup.string().required("Please upload chart image"),
        stype: Yup.string().required('Tradelog type is required').nullable(),
        trade_type: Yup.string().required('Trade direction is required').nullable(),
        ep: Yup.array().min(1).of(Yup.string().required('Entry price is required')).required('Entry price is required').nullable(),
        tp: Yup.array().min(1).of(Yup.string().required('Target price is required')).required('Target price is required').nullable(),
        rs: Yup.array().min(1).of(Yup.string().required('Risk is required')).required('Risk is required').nullable(),
        tlrs: Yup.array().min(1).of(Yup.string().required('Tradelog Risk is required')).required('Tradelog risk is required').nullable(),

    });
    const formOptions = { mode: "onChange", resolver: yupResolver(validationSchema) };
    const { control, register, setValue, trigger, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const [type, setType] = useState(false)
    const [entryprice, setEntryprice] = useState(false)
    const [risk, setRisk] = useState(false)
    const [percentage, setPercentage] = useState(0);
    const [imageErr, setImageErr] = useState('');
    const [reportErr, setReportErr] = useState('');

    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);

    const [category1, setCategory1] = useState(false);
    const [category2, setCategory2] = useState(false);

    const [risk1, setRisk1] = useState(false);
    const [risk2, setRisk2] = useState(false);
    const [tradeDirection, setTradeDirection] = useState(0);

    const [target1, setTarget1] = useState(false);
    const [target2, setTarget2] = useState(false);
    const [targetPrice, setTargetPrice] = useState(false)

    const [strategyList, setStrategyList] = useState(null)
    const [currentDate, setCurrentDate] = useState('');
    const [newIdeaOn, setNewIdeaOn] = useState(true);
    const [displayOnHomePage, setDisplayOnHomePage] = useState(false);
    // Set startegy List for multiselect
    const [symbolList, setSymbolList] = useState(null)
    const [isDraft, setIsDraft] = useState(false);
    const [radioType, setRadioType] = useState(null);
    const [symbol, setSymbol] = useState(null);
    const [strategy, setStrategy] = useState(null);
    const [selectCategory, setSelectCategory] = useState(null);
    const [chartImage, seCharctImage] = useState(null);
    const [reportPDF, setReportPDF] = useState(null);
    const [isClickOnEdit, setIsClickOnEdit] = useState(0);
    const [tpValue, setTpValue] = useState(null);
    const [epValue, setEpValue] = useState(null);
    const [buyPriceCriteria1, setBuyPriceCriteria1] = useState('');
    const [buyPriceCriteria2, setBuyPriceCriteria2] = useState('');

    const [sellPriceCriteria1, setSellPriceCriteria1] = useState('');
    const [sellPriceCriteria2, setSellPriceCriteria2] = useState('');
    const [categories, setCategpries] = useState(null);
    const [sellingZone, setSellingZone] = useState(null);
    const [buyingZone, setBuyingZone] = useState(null);

    const [LTP, setLTP] = useState(17.5);
    const [TLRisk, setTLRisk] = useState(null);
    const [TLRisk1, setTLRisk1] = useState(false);
    const [TLRisk2, setTLRisk2] = useState(false);
    const [createSignal, setCreateSignal] = useState(0);
    const [addToStrategies, setAddToStrategies] = useState(0);
    const [segment, setSegment] = useState(null);



    const t = type != false ? type : '';
    useEffect(() => {
        getSymbols({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setSymbolList(response.data)
            }
        })
    }, [])
    const navigate = useNavigate();
    function onClickEdit() {
        setIsClickOnEdit(1);

        setEntryprice(false)
        setRisk(false)
        setPercentage(false)
        setImageErr('')
        setReportErr('');
        setCheckbox1(false)
        setCheckbox2(false)
        setRisk1(false)
        setRisk2(false)
        setTarget1(false)
        setTarget2(false)
        setTargetPrice(false)
        setNewIdeaOn(true)
        setDisplayOnHomePage(0)
        setIsDraft(0)
        setRadioType(false)
        setSymbol(null)
        setStrategy(null)
        setSelectCategory(null)
        seCharctImage(null)
        setReportPDF(null)
        setSellingZone(null)
        setSellPriceCriteria2('')
        setSellPriceCriteria1('')

    }

    function redirect() {
        navigate("/tradelog")
    }
    const handlePercentageChange = (event) => {
        const newValue = event.target.value;
        setPercentage(newValue);
        setTpValue(event.target.value);

    };

    const handleSellingZoneChange = (e) => {
        const enteredValue = e.target.value;
        console.log(epValue);
        const tp = parseFloat(epValue) + parseFloat(epValue * (tpValue / 100));
        const price1 = targetPrice == 'Price' ? tpValue : tp;
        const sp = targetPrice == 'Price' ? tpValue : tp;
        const price2 = sp - (sp * ((enteredValue) / 100));
        setSellingZone(enteredValue);
        setSellPriceCriteria1(price1);
        setSellPriceCriteria2(price2);

    };


    const handleBuyingZoneChange = (e) => {
        const enteredValue = e.target.value;
        const price2 = epValue - (epValue * ((enteredValue) / 100));
        setBuyingZone(enteredValue);
        setBuyPriceCriteria1(epValue);
        setBuyPriceCriteria2(price2);

    };

    // For Entry Price [ CMP and Custom Price ]

    const handleEntryPrice1Change = (event) => {
        const value = event.target.value;
        setEntryprice(value)
        setCheckbox1(true);
        setCheckbox2(false);
        setEpValue(LTP)
    };

    const handleEntryprice2Change = (event) => {
        const value = event.target.value;
        setEntryprice(value)
        setCheckbox1(false);
        setCheckbox2(true);
    };

    // For Signal Categoey [ Investing and trading]

    const handleSignalCategory1Change = (event) => {
        const value = event.target.value;
        setType(value)
        setCategory1(true);
        setCategory2(false);
        setIsClickOnEdit(0)
        getCategoryList("1")


    };

    const handleSignalCategory2Change = (event) => {
        const value = event.target.value;
        setType(value)
        setCategory1(false);
        setCategory2(true);
        setIsClickOnEdit(0)
        getCategoryList("2")

    };


    // For Stoploss / Risk [Percentage and Static Price]

    const handleRisk1Change = (event) => {
        const value = event.target.value;
        setRisk(value)
        setRisk1(true);
        setRisk2(false);
    };

    const handleEpValueChange = (event) => {
        setEpValue(event.target.value);
    };

    const handleTpValueChange = (event) => {
        setTpValue(event.target.value);
    }

    const handleRisk2Change = (event) => {
        const value = event.target.value;
        setRisk(value)
        setRisk1(false);
        setRisk2(true);
    };
    const handleTLRisk1Change = (event) => {
        const value = event.target.value;
        setTLRisk(value)
        setTLRisk1(true);
        setTLRisk2(false);
    };
    const handleTLRisk2Change = (event) => {
        const value = event.target.value;
        setTLRisk(value)
        setTLRisk1(false);
        setTLRisk2(true);
    };
    // For Target Price [ Percentage and Static Price ]

    const handleTargetPrice1Change = (event) => {
        const value = event.target.value;
        setTargetPrice(value)
        setTarget1(true);
        setTarget2(false);
    };

    const handleTargetPrice2Change = (event) => {
        const value = event.target.value;
        setTargetPrice(value)
        setTarget1(false);
        setTarget2(true);
    };


    // Set startegy List for multiselect

    useEffect(() => {
        getStrategies({}).then((response) => {
            response = JSON.parse(response)
            
            if (response.code == 200) {
                setStrategyList(response.data)
            }
        })
    }, [])



    // Initialize the state with an empty string


    // For show current date as publish date
    useEffect(() => {
        const today = new Date();
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        const formattedDate = today.toLocaleDateString(undefined, options);
        // Update the state with the current date and day
        setCurrentDate(formattedDate);
    }, []);

    // For Signal type [Free and Premium]
    const handleRadioChange = (event) => {
        setRadioType(event.target.value);
    };

    // Handle Symbol Change 

    const handleSymbolChange = (selectedOption) => {

        setSymbol(selectedOption);
        //  trigger(['symbol']) 

    }
    // For Strategies [Multi Select]

    const handleStrategyChange = (selectedOption) => {

        setStrategy(selectedOption);
    }

    // Trade Category Change 

    const handleSelectCategoryChange = (selectedOption) => {
        ;
        setSelectCategory(selectedOption);
    }

    function getCategoryList(type) {
        getCategories({ "type": type, "module": 3 }).then((response) => {
            response = JSON.parse(response)
            
            if (response.code == 200) {
                setCategpries(response.data)
            }
        })
    }


    const onSubmit = (data) => {
        // if (reportPDF == null) {
        //     setReportErr('Please upload report pdf')
        // }
        // else {
        addSignal({ "is_trade_log": 1, "name": data.name, "category": type, "type": radioType, "symbol": symbol, "entry_price_type": entryprice, "entry_price": epValue, "risk_type": risk, "risk": data.rvalue, "target_price_type": targetPrice, "target_price": tpValue, "strategies": strategy, "trade_category": selectCategory, "buy_percentage": buyingZone, "sell_percentage": sellingZone, "is_draft": isDraft, "tradelog_risk_type": TLRisk, "tradelog_risk": data.tlrvalue, "segment": segment, "is_create_signal": createSignal, "publish_date": currentDate, "add_to_strategy": addToStrategies, "trade_direction": tradeDirection }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/tradelog")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
        // }
    }

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Add {t} Tradelog</h5>
                        <hr></hr>
                        {
                            <><meta charset="UTF-8"></meta>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {type == false ? <>
                                        <label>Select Tradelog Cateory: </label>
                                        <div class="button-items">
                                            <button type="button" class="btn btn-primary waves-effect waves-light" value="Investing" onClick={handleSignalCategory1Change}>Investing</button><button type="button" value="Trading" class="btn btn-secondary waves-effect" onClick={handleSignalCategory2Change}>Trading</button></div>
                                    </> :
                                        <>
                                            <div className="form-group">

                                                {isClickOnEdit == 0 ? <>  <div className='row'> <button type='button' onClick={onClickEdit} className='btn btn-info waves-effect waves-light' style={{ marginLeft: 'auto' }}><i class="fa fa-edit"></i> Edit Tradelog Category</button> </div></> :
                                                    <>
                                                        <label>Select Tradelog Cateory: </label>
                                                        <div class="button-items">
                                                            <button type="button" class="btn btn-primary waves-effect waves-light" value="Investing" onClick={handleSignalCategory1Change}>Investing</button><button type="button" value="Trading" class="btn btn-secondary waves-effect" onClick={handleSignalCategory2Change}>Trading</button></div>
                                                    </>}



                                            </div>
                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label> Tradelog Name </label>
                                                        <div>
                                                            <input type="text" placeholder='Please enter name' className="form-control" {...register('name')} />
                                                            <div className="invalid-feedback">{errors.name?.message}</div>

                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label htmlFor="mySelect2">Symbol </label>
                                                        <div className='custom-select-container'>

                                                            <Controller
                                                                name="content"
                                                                control={control}
                                                                {...register('symbols', { required: 'Please select country code' })}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        options={symbolList}
                                                                        value={symbol}
                                                                        menuPortalTarget={document.body}
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        onChange={(selectedOption) => {
                                                                            handleSymbolChange(selectedOption);
                                                                            field.onChange(selectedOption);
                                                                        }}
                                                                        placeholder="Select Symbol"
                                                                        isSearchable={true}
                                                                    />
                                                                )}
                                                            />



                                                            <div className="invalid-feedback">{errors.symbols?.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label> Entry Price </label>
                                                        <div>
                                                            <input type="checkbox" value="CMP" checked={checkbox1} onClick={handleEntryPrice1Change} name="c1" {...register('ep')} />&nbsp;CMP &nbsp;&nbsp;
                                                            <input type="checkbox" value="Custom" checked={checkbox2} onClick={handleEntryprice2Change} name="c2" {...register('ep')} />&nbsp;Custom Price
                                                            <div className="invalid-feedback">{errors.ep?.message}</div>
                                                            {entryprice == 'Custom' ? <>
                                                                <input className="form-control form-control" name="t1" type="text" placeholder="Please enter Price" onChange={handleEpValueChange} required />
                                                                <div className="invalid-feedback"></div>
                                                            </> : entryprice == 'CMP' ? (
                                                                <>
                                                                    <input className="form-control" name="t2" type="text" defaultValue={LTP} readOnly />
                                                                    <div className="invalid-feedback"></div>
                                                                </>
                                                            ) :
                                                                <></>}
                                                            <div className="invalid-feedback">{errors.epvalue?.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label> Trade Category </label>
                                                        <div>
                                                            <Controller
                                                                name="trade_category"
                                                                control={control}
                                                                rules={{ required: 'Please select trade category' }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        options={categories}
                                                                        value={field.value}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption);
                                                                            handleSelectCategoryChange(selectedOption);
                                                                        }}
                                                                        placeholder="Select Trade Category"
                                                                        isSearchable={true}
                                                                    />
                                                                )}
                                                            />
                                                            <div className="invalid-feedback">{errors.trade_category?.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label> Target Price </label>
                                                        <div>

                                                            <input type="checkbox" value="Percentage" checked={target1} onClick={handleTargetPrice1Change} name="c1" {...register('tp')} />&nbsp;Percentage &nbsp;&nbsp;
                                                            <input type="checkbox" value="Price" checked={target2} onClick={handleTargetPrice2Change} name="c2" {...register('tp')} />&nbsp;Static Price
                                                            <div className="invalid-feedback">{errors.tp?.message}</div>

                                                            {targetPrice == 'Percentage' ? <>
                                                                <div>
                                                                    <input className="form-control form-control" id="siret" type="number" onChange={handlePercentageChange} placeholder="Please enter Percentage" />
                                                                </div>

                                                            </> : targetPrice == 'Price' ? (
                                                                <>

                                                                    <input className="form-control form-control" id="siret" type="text" placeholder="Please enter Price" onChange={handleTpValueChange} />
                                                                    <div className="invalid-feedback"></div>
                                                                </>
                                                            ) :
                                                                <></>}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label> Strategies </label>
                                                        <div>
                                                            <Controller
                                                                name="strategy"
                                                                control={control}
                                                                rules={{ required: 'Please select strategy' }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        menuPortalTarget={document.body}
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        options={strategyList}
                                                                        value={field.value}
                                                                        isMulti={true}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption);
                                                                            handleStrategyChange(selectedOption);
                                                                        }}
                                                                        placeholder="Select Strategies"
                                                                        isSearchable={true}
                                                                    />
                                                                )}
                                                            />


                                                            <div className="invalid-feedback">{errors.strategies?.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label> Stop Loss or Risk </label>
                                                        <div>

                                                            <input type="checkbox" value="Percentage" checked={risk1} onClick={handleRisk1Change} name="c1" {...register('rs')} />&nbsp;Percentage &nbsp;&nbsp;
                                                            <input type="checkbox" value="Price" checked={risk2} onClick={handleRisk2Change} name="c2" {...register('rs')} />&nbsp;Static Price
                                                            <div className="invalid-feedback">{errors.rs?.message}</div>


                                                            {risk == 'Percentage' ? <>
                                                                <input className="form-control" type="number" placeholder="Please enter Percentage" onChange={handlePercentageChange} {...register('rvalue')} />
                                                            </> : risk == 'Price' ? (
                                                                <>
                                                                    <input className="form-control" id="siret" type="text" placeholder="Please enter Price" {...register('rvalue')} />
                                                                    <div className="invalid-feedback"></div>
                                                                </>
                                                            ) :
                                                                <></>}
                                                        </div>

                                                    </div>

                                                    <div className='col-md-6'>
                                                        <label> Tradelog Type </label>
                                                        <div>
                                                            <input type="radio" id="age1" name="stype" value="1" onClick={handleRadioChange} {...register('stype')} />&nbsp;Free
                                                            &nbsp;
                                                            <input type="radio" id="age2" name="stype" value="2" onClick={handleRadioChange} {...register('stype')} />&nbsp;Premium
                                                            <div className="invalid-feedback">{errors.stype?.message}</div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label> Risk Amount </label>
                                                        <div>

                                                            <input type="checkbox" value="Percentage" checked={TLRisk1} onClick={handleTLRisk1Change} name="c1" {...register('tlrs')} />&nbsp;Percentage &nbsp;&nbsp;
                                                            <input type="checkbox" value="Price" checked={TLRisk2} onClick={handleTLRisk2Change} name="c2" {...register('tlrs')} />&nbsp;Static Price
                                                            <div className="invalid-feedback">{errors.tlrs?.message}</div>


                                                            {TLRisk == 'Percentage' ? <>
                                                                <input className="form-control" type="number" placeholder="Please enter Percentage" onChange={handlePercentageChange} {...register('tlrvalue')} />
                                                            </> : TLRisk == 'Price' ? (
                                                                <>
                                                                    <input className="form-control" id="siret" type="text" placeholder="Please enter Price" {...register('tlrvalue')} />
                                                                    <div className="invalid-feedback"></div>
                                                                </>
                                                            ) :
                                                                <></>}
                                                        </div>

                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label> Tradelog Segment </label>
                                                        <div>
                                                            <Controller
                                                                name="segment"
                                                                control={control}
                                                                rules={{ required: 'Please select segment' }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        options={segmentOptions}
                                                                        value={field.value}
                                                                        menuPortalTarget={document.body}
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption);
                                                                            setSegment(selectedOption)
                                                                        }}
                                                                        placeholder="Select Segment"
                                                                        isSearchable={true}
                                                                    />
                                                                )}
                                                            />

                                                            <div className="invalid-feedback">{errors.segment?.message}</div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label> Buying Zone (In %) </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    handleBuyingZoneChange(e);
                                                                    setValue('buying_zone', e.target.value);
                                                                }}
                                                                value={buyingZone}
                                                                placeholder="Please enter value"
                                                            />

                                                        </div>
                                                        {buyPriceCriteria2
                                                            !== '' && buyPriceCriteria1 !== '' && (
                                                                <div>
                                                                    Price Range: {'\u20A8'}{buyPriceCriteria2} - {'\u20A8'}{buyPriceCriteria1}
                                                                </div>
                                                            )}
                                                        <div className="invalid-feedback">{errors.buying_zone?.message}</div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label> Selling Zone (In %) </label>
                                                        <div>
                                                            <input type="text" className="form-control" value={sellingZone} onChange={(e) => {
                                                                handleSellingZoneChange(e);
                                                                setValue('selling_zone', e.target.value);
                                                            }} placeholder="Please enter value" />
                                                        </div>
                                                        {sellPriceCriteria2 !== '' && sellPriceCriteria1 !== '' && (
                                                            <div>
                                                                Price Range: {'\u20A8'}{sellPriceCriteria2} - {'\u20A8'}{sellPriceCriteria1}
                                                            </div>

                                                        )}
                                                        <div className="invalid-feedback">{errors.selling_zone?.message}</div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='row'>

                                                    <div className='col-md-6'>
                                                        <label htmlFor="switch4">  Create Signal ? </label>
                                                        <div>
                                                            <input type="checkbox" id="switch3" switch="none" checked={createSignal} onChange={(e) => setCreateSignal(e.target.checked ? 1 : 0)} />
                                                            <label id="agentStatus" for="switch3" class="data_lable" data-on-label="Yes" data-off-label="No"></label>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label htmlFor="switch4">  Add To Startegies </label>
                                                        <div>
                                                            <input type="checkbox" id="switch4" switch="none" checked={addToStrategies} onChange={(event) => setAddToStrategies(event.target.checked ? 1 : 0)} />
                                                            <label id="agentStatus" for="switch4" class="data_lable" data-on-label="Yes" data-off-label="No"></label>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='row'>

                                                    <div className='col-md-6'>
                                                        <label htmlFor="switch4"> Trade Direction </label>
                                                        <div>
                                                            <input type="radio" id="age1" name="trade_type" value="1" onClick={(e) => setTradeDirection(e.target.value)}  {...register('trade_type')} />&nbsp;LONG
                                                            &nbsp;
                                                            <input type="radio" id="age2" name="trade_type" value="2" onClick={(e) => setTradeDirection(e.target.value)} {...register('trade_type')} />&nbsp;SHORT
                                                        </div>
                                                        <div className="invalid-feedback">{errors.trade_type?.message}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">

                                                <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                                    Add
                                                </button>
                                                <button className="btn btn-danger btn-custom-cancel" onClick={redirect}> Cancel </button>

                                            </div>
                                        </>}
                                </form></>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}
