import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

function Header() {
    const navigate = useNavigate();

    function logout(){
        navigate("/logout");
    }

    function changePassword(){
        navigate("/changePassword");
    }

    function editProfile(){
        navigate("/editProfile");
    }
    const image = localStorage.getItem("AprofileImage") != null ? `${process.env.REACT_APP_S3URL}/admin_profile/${localStorage.getItem("AprofileImage")}` : `${process.env.PUBLIC_URL}/assets/images/userLogo.png`
  return (
    <div className='wrapper'>
      <div className="topbar">
                <div className="topbar-left">
                    <Link to="/" className="logo">
                        <span>
                            <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="" height="40" />
                        </span>
                        {/* <i>
                            <img src={process.env.PUBLIC_URL+"/assets/images/logo-sm.png"} alt="" height="34" />
                        </i> */}
                    </Link>
                </div>
                <nav className="navbar-custom">

                    <ul className="navbar-right d-flex list-inline float-right mb-0">

                        <li className="dropdown notification-list">
                            <div className="dropdown notification-list nav-pro-img">
                                <button className="btn btn-link dropdown-toggle nav-link arrow-none waves-effect nav-user" data-toggle="dropdown"   aria-haspopup="false" aria-expanded="false">
                                    <img src={image} alt="user" className="rounded-circle" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                                    
                                    <button className="btn btn-link dropdown-item" onClick={editProfile}><i className="mdi mdi-account-circle m-r-5"></i> Profile</button>
                                    <button className="btn btn-link dropdown-item" onClick={changePassword}><i className="mdi mdi-lock m-r-5"></i> Change Password</button>
                                    <div className="dropdown-divider"></div>
                                    <button className="btn btn-link dropdown-item text-danger"  onClick={logout}><i className="mdi mdi-power text-danger"></i> Logout</button>
                                </div>                                                                    
                            </div>
                        </li>

                    </ul>
{/* 
                    <ul className="list-inline menu-left mb-0">
                        <li className="">
                            <button className="button-menu-mobile open-left waves-effect">
                                <i className="mdi mdi-menu"></i>
                            </button>
                        </li>
                    </ul> */}

                </nav>
            </div>
    </div>
  )
}

export default Header;