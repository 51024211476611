import React, { useEffect, useState } from 'react'
import { contactUs } from '../../api/apiHandler';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useParams,useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export default function Contact() {

    let { id } = useParams(); 

    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        subject: Yup.string()
            .required('Subject is required'),
        message: Yup.string()
            .required('Message is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    function redirect(){
        navigate("/contact")
    }   

    const onSubmit = (data) => {
        var newData = {
            "email":id,
            "subject":data.subject,
            "message":data.message
        }
        console.log("Data",newData)
        contactUs(newData).then((res) => {
            var response = JSON.parse(res)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(()=>{
                    navigate("/contact")
                },2000)
            }
            else {
                Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });
    }

  return (
    <div className='content-page mt-5'>
        <div className='content'>
            <div className='container-fluid' >
                <div className='cal col-xs-8 col-md-8 col-sm-8'>
                    <h5>Send Mail</h5>
                    <hr></hr>
                    {
                        <form onSubmit={handleSubmit(onSubmit)} >
                            
                            <div className="form-group">
                                <label className='ml-3'> Subject</label>
                                <div className='ml-3'>
                                    <input type="text" {...register('subject')} className="form-control" placeholder="Please enter subject" />
                                    <div className="invalid-feedback">{errors.subject?.message}</div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className='ml-3'> Message</label>
                                <div className='ml-3'>
                                    <input type="text" {...register('message')} className="form-control" placeholder="Please enter message " />
                                    <div className="invalid-feedback">{errors.message?.message}</div>
                                </div>
                            </div>
                            <div className="form-group ml-3">
                                
                                <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                    Add
                                </button>
                                <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>
                                
                            </div>
                        </form>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
