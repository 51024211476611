import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { editUser, getUser, getCountryList, getActiveRole, getUserById, login } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export default function EditUser() {

    let { id } = useParams();
    const [userDetails, setUserDetails] = useState(null)
    const [type, setType] = useState(null);
    const [countryList, setCountryList] = useState(null)
    const [roleList, setRoleList] = useState(null)

    useEffect(() => {

        if (id != undefined) {
            getUserById({ "user_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setUserDetails(res.data)
                    setType(res.data.subscription_type)
                }
            });
        }

    }, [id])

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required("Please enter first name").matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field').max(128, "Name must be at most 128 character"),
        last_name: Yup.string().required("Please enter last name").matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field').max(128, "Name must be at most 128 character"),
        email: Yup.string().email("Please enter valid email").required("Please enter email"),
        // country_code: Yup.string().required("Please select country code"),
        mobile_number: Yup.string().required("Please enter phone number").matches('^[0-9]', 'Only numbers are allowed for this field').min(8, "Phone must be at least 8 numbers").max(14, "Phone must be at most 14 numbers"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate("/user")
    }

    const onSubmit = (data) => {
        editUser({ "user_id": id, "first_name": data.first_name.trimStart().trimEnd(), "last_name": data.last_name.trimStart().trimEnd(), "email": data.email, "country_code": data.country_code, "mobile_number": data.mobile_number , "subscription_type" : type }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/user")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }
    if (userDetails == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-8 col-md-8 col-sm-8'>
                        <h5>Edit User Details</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >

                                <div className="form-group">
                                    <label className='ml-3'>First Name</label>
                                    <div className='ml-3'>
                                        <input type="text" {...register('first_name')} className="form-control" defaultValue={userDetails.first_name} placeholder="Please Enter Name" />
                                        <div className="invalid-feedback">{errors.first_name?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className='ml-3'> Last Name</label>
                                    <div className='ml-3'>
                                        <input type="text" {...register('last_name')} className="form-control" defaultValue={userDetails.last_name} placeholder="Please Enter Name" />
                                        <div className="invalid-feedback">{errors.last_name?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className='ml-3'> Email</label>
                                    <div className='ml-3'>
                                        <input type="text" {...register('email')} className="form-control" defaultValue={userDetails.email} placeholder="Please Enter Email Address" />
                                        <div className="invalid-feedback">{errors.email?.message}</div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className='ml-3'>  Country Code </label>
                                    <div className="ml-3">
                                        <input type="text" {...register('country_code')} className="form-control" defaultValue="+91" readOnly />
                                        <div className="invalid-feedback">{errors.country_code?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className='ml-3'> Phone Number</label>
                                    <div className='ml-3'>
                                        <input type="text" {...register('mobile_number')} className="form-control" defaultValue={userDetails.mobile_number} placeholder="Please enter phone number" />
                                        <div className="invalid-feedback">{errors.mobile_number?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className='ml-3'>Subscription Type</label>
                                    <div className='ml-3'>
                                        <div className="radio-group">
                                            <input type="radio" id="free" name="subscriptionType" value="free" checked={type == 1} onChange={() => setType(1)} />
                                            <label htmlFor="free">Free</label>
                                            <input className='ml-1' type="radio" id="premium" name="subscriptionType" value="premium" checked={type == 2} onChange={() => setType(2)} />
                                            <label htmlFor="premium">Premium</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">

                                    <button type="submit" className="btn btn-custom btn-primary waves-effect waves-light m-2">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}