import React, { useEffect, useState } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getActiveFaq } from '../../api/apiHandler';
import Swal from 'sweetalert2'


function UserFaq() {
    
    const [faqList, setFaqList] = useState(null)

    useEffect(() => {
        
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        getActiveFaq({}).then((response) => {
            Swal.close()
            response = JSON.parse(response)
            console.log("--->",response.data)
            if (response.code == 200) {
                setFaqList(response.data)
            }else{
                setFaqList([])
            }
        })
    }, [])

    if (faqList == null) return <> </>

    return (
        <div className="wrapper-page">
            <h1 className='text-center'>FAQ's</h1>
            {
                faqList && faqList.map((item, index) => {
                    return (
                        <div className="card ">
                            <div className="card-body">
                                <div>
                                    <span style={{fontSize:20}}> Question :-  </span>
                                    <span style={{fontSize:17}}>{item.question}</span>
                                </div>
                                <div className='mt-3'>
                                    <span style={{fontSize:20}}> Answer :-</span>
                                    <span style={{fontSize:17}}> {item.answer}</span>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}


export default UserFaq;