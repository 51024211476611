import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { editProfile, getAdmin, getCountryList } from '../../api/apiHandler';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import S3 from 'react-aws-s3';


const config = {
    bucketName: process.env.REACT_APP_S3_NAME,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    s3Url: process.env.REACT_APP_S3URL,
    dirName: "admin_profile",
};
const ReactS3Client = new S3(config);

export default function Profile() {
    const [profileImage, setProfileImage] = useState(null);
    const [adminDetails, setAdminDetails] = useState(null)
    useEffect(() => {


        getAdmin({}).then((res) => {
            res = JSON.parse(res)
            if (res.code == 200) {
                setAdminDetails(res.data)
                setProfileImage(res.data.profile_image)
            }
        });
    }, [])


    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter name").matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field').max(128, "Name must be at most 128 character"),
        email: Yup.string().email().required("Please enter email"),
        // country_code: Yup.string().required("Please select country code"),
        phone: Yup.string().required("Please enter phone number").matches('^[0-9]', 'Only numbers are allowed for this field').min(8, "Phone must be at least 8 numbers").max(14, "Phone must be at most 14 numbers"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate("/")
    }
    const image = adminDetails != null ? adminDetails.profile_image : null;
    //s3 file upload - Chart image
    const handleFileInput = (e) => {
        //setProfileUrl()
        const files = e.target.files[0];
        var newfile = new Date().getTime() + files.name

        ReactS3Client.uploadFile(files, newfile).then(data => {
            if (data.status === 204) {
                setProfileImage(data.location.split("/")[4])
            } else {
                console.log('obj err')
            }
        });
    }
    const imageUrl = profileImage != null ? `${process.env.REACT_APP_S3URL}/admin_profile/${profileImage}` : 'https://www.islgroups.com/images/3.jpg';
    const onSubmit = (data) => {
        editProfile({ "name": data.name.trimStart().trimEnd(), "email": data.email, "country_code": data.country_code, "phone": data.phone, "profile_image": profileImage }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    if (adminDetails == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <ToastContainer />
                    <div className='cal col-xs-4 col-md-4 col-sm-4'>
                        <h5>Edit Profile</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="mb-3">
                                    <div className="admin-profile">
                                        <label className="-label" for="file">
                                            <i className="fas fa-camera"></i>
                                            <span style={{ marginLeft: "5px" }}>Change Image</span>
                                        </label>
                                        <input id="file" type="file" onChange={handleFileInput} />
                                        <img src={imageUrl} id="output" width="200" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label> Name</label>
                                    <div>
                                        <input type="text" {...register('name')} className="form-control" defaultValue={adminDetails.name} placeholder="Please enter name" />
                                        <div className="invalid-feedback">{errors.name?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label> Email</label>
                                    <div>
                                        <input type="text" {...register('email')} className="form-control" defaultValue={adminDetails.email} placeholder="Please enter email address" />
                                        <div className="invalid-feedback">{errors.email?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>  Country Code </label>
                                    <input type="text" {...register('country_code')} className="form-control" defaultValue="+91" readOnly />
                                    <div className="invalid-feedback">{errors.country_code?.message}</div>
                                </div>
                                <div className="form-group">
                                    <label> Phone Number</label>
                                    <div>
                                        <input type="text" {...register('phone')} className="form-control" defaultValue={adminDetails.phone} placeholder="Please enter phone number" />
                                        <div className="invalid-feedback">{errors.phone?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}