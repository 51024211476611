import React, { useEffect, useState } from 'react'
import { getWatchList, getSubAdminById, removeWatchList, changeWatchListStatus } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useParams, useNavigate } from 'react-router-dom';
import { watchListCategoryOptions } from '../../globals/constant';

function WatchlistStocks() {
    const navigate = useNavigate();
    let { category } = useParams();

    const [watchlistList, setWatchListList] = useState(null)
    const [watchlistAdd, setWatchListAdd] = useState(0)
    const [watchlistEdit, setWatchListEdit] = useState(0)
    const [watchlistRemove, setWatchListRemove] = useState(0)
    const [watchlistView, setWatchListView] = useState(0)
    const [watchlistChangeStatus, setWatchListChangeStatus] = useState(0)

    useEffect(() => {
        getWatchList({ "trade_category": category }).then((response) => {
            Swal.close()
            response = JSON.parse(response)

            if (response.code == 200) {
                setWatchListList(response.data)
            } else {
                setWatchListList([])
            }
        })

    }, [category])
    useEffect(() => {
        if (localStorage.getItem("Arole") == "S") {
            setWatchListEdit(1)
            setWatchListRemove(1)
            setWatchListChangeStatus(1)
        }
        else {
            getSubAdminById({ "sub_admin_id": localStorage.getItem('Aid') }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setWatchListEdit(res.data.permissions.filter(module => module.class_name === 'watchlist' && module.is_edit === 1).length > 0)
                    setWatchListRemove(res.data.permissions.filter(module => module.class_name === 'watchlist' && module.is_delete === 1).length > 0)
                    setWatchListChangeStatus(res.data.permissions.filter(module => module.class_name === 'watchlist' && module.is_change_status === 1).length > 0)
                }
            });
        }
    }, [])

    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'copy',
                    className: 'btn-mute'
                }, {
                    extend: 'csv',
                    className: 'btn-mute'
                }, {
                    extend: 'print',
                    className: 'btn-mute'
                }],
                "bDestroy": true,
            }
        );
    }, [watchlistList])

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }


    function renderStatusUiText(item) {
        return (
            item.is_active == 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }
    const editWatchlist = (item) => {
        if (watchlistEdit == 1) {

            navigate(`/watchlist/edit/${item._id}`);
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
   
    function changeStatus(watchlistId, item, position) {
        if (watchlistChangeStatus == 1) {

            const status = (item.is_active == 1) ? "0" : "1"

            changeWatchListStatus({ "watchlist_id": watchlistId, "is_active": status }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    showMessage(response.message)
                    watchlistList[position].is_active = status
                    const data = renderToStaticMarkup(renderStatusUiText(watchlistList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                    $('#mangeUser').DataTable().row("#watchlistId" + watchlistId).cell("#watchlistStatus" + watchlistId).data(data).draw(false);

                }
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    function deleteWatchlist(watchlistId) {
        if (watchlistRemove == 1) {
            Swal.fire({
                title: 'Are you sure you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#02C9D6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    removeWatchList({ "watchlist_id": watchlistId }).then((response) => {
                        response = JSON.parse(response)
                        if (response.code == 200) {
                            $('#mangeUser').DataTable().row("#watchlistId" + watchlistId).remove().draw(false);
                            showMessage(response.message)
                        }
                    });
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    if (watchlistList == null) return <> </>
    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            {/* <button className='add btn-custom' onClick={addWatchList}><span className="mdi mdi-plus"></span></button> */}
                            {/* Back Button */}
                            <button className="button mb-2" onClick={() => navigate(-1)}>
                                <div className="button-box">
                                    <span className="button-elem">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 40">
                                            <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"></path>
                                        </svg>
                                    </span>
                                    <span className="button-elem">
                                        <svg viewBox="0 0 46 40">
                                            <path d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z"></path>
                                        </svg>
                                    </span>
                                </div>
                            </button>

                            <h5> Stock List Of {category}</h5>
                            <hr></hr>
                            <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Admin Name</th>
                                        <th style={{ textAlign: "center" }}>Stock Name</th>
                                        <th style={{ textAlign: "center" }}>LTP</th>
                                        <th style={{ textAlign: "center" }}>Trade Direction</th>
                                        <th style={{ textAlign: "center" }}>Holding Days</th>
                                        <th style={{ textAlign: "center" }}>Publish Date</th>
                                        {/* <th style={{ textAlign: "center" }}>Potential Gain</th> */}
                                        <th style={{ textAlign: "center" }}>Status</th>
                                        <th style={{ textAlign: "center" }}>Status Action</th>
                                        <th style={{ textAlign: "center" }}>Action</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Admin Name</th>
                                        <th style={{ textAlign: "center" }}>Stock Name</th>
                                        <th style={{ textAlign: "center" }}>LTP</th>
                                        <th style={{ textAlign: "center" }}>Trade Direction</th>
                                        <th style={{ textAlign: "center" }}>Holding Days</th>
                                        <th style={{ textAlign: "center" }}>Publish Date</th>
                                        {/* <th style={{ textAlign: "center" }}>Potential Gain</th> */}
                                        <th style={{ textAlign: "center" }}>Status</th>
                                        <th style={{ textAlign: "center" }}>Status Action</th>
                                        <th style={{ textAlign: "center" }}>Action</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    {
                                        watchlistList && watchlistList.map((item, index) => {
                                            return (
                                                <tr id={"watchlistId" + item._id} >
                                                    <td style={{ textAlign: "center" }}>
                                                        {index + 1}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.userName}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.scrip_name}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.ltp}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.trade_direction === 1 ? "LONG" : "SHORT"}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.holding_days}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.publish_date}
                                                    </td>
                                                    {/* <td style={{ textAlign: "center" }}>
                                                        {item.profit}
                                                    </td> */}
                                                    <td id={"watchlistStatus" + item._id} style={{ textAlign: "center" }}>
                                                        {renderStatusUiText(item)}
                                                    </td>
                                                    <td id={"userStatus" + item._id} style={{ textAlign: "center" }}>
                                                        <Tooltip title="Change Status">
                                                            <IconButton onClick={() => { changeStatus(item._id, item, index) }}>
                                                                <FeatherIcon icon="repeat" width="18" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => { deleteWatchlist(item._id) }}>
                                                                <FeatherIcon icon="trash-2" width="18" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={() => { editWatchlist(item) }}>
                                                                <FeatherIcon icon="edit" width="18" />
                                                            </IconButton>
                                                        </Tooltip>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WatchlistStocks;