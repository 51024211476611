import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getWebinarDetails, editWebinar, getSubAdminById } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



export default function EditWebinar() {

    const [edit, setEdit] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedHWDate, setSelectedHWDate] = useState(null);
    const [formattedHWDate, setFormattedHWDate] = useState(null);
    const [formattedinsaneDate , setFormattedInsaneDate] = useState(null)
    const [formattedDate, setFormattedDate] = useState(null);
    const [url, setUrl] = useState(null);
    const [hindiUrl, setHindiUrl] = useState(null);
    const [webUrl, setWebUrl] = useState('');
    const [workshopUrl, setWorkshopUrl] = useState('');
    const [seminarUrl, setSeminarUrl] = useState('');
    const [smcUrl, setSmcUrl] = useState('');
    const [insaneDate, setInsaneDate] = useState(null);

    useEffect(() => {
        // getTagById({ "tag_id": id }).then((res) => {
        //     res = JSON.parse(res)
        //     if (res.code == 200) {
        //         setTagDetails(res.data)
        //     }
        // });
        if (localStorage.getItem("Arole") == "S") {
            setEdit(1)
            getAPIData();
        }
        else {

            getSubAdminById({ "sub_admin_id": localStorage.getItem('Aid') }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {

                    setEdit(res.data.permissions.filter(module => module.class_name === 'webinar' && module.is_edit === 1).length > 0
                    );
                }
            });
        }

    }, [])

    function getAPIData() {
        getWebinarDetails({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setSelectedDate(new Date(response.data.webinar_date_time))
                setUrl(response.data.url)
                setHindiUrl(response.data.hindi_webinar_url)
                setSelectedHWDate(new Date(response.data.hindi_webinar_date_time))
                setWebUrl(response.data.web_url)
                setWorkshopUrl(response.data.workshop_url)
                setSeminarUrl(response.data.seminar_url)
                setSmcUrl(response.data.smc_url);
                setInsaneDate(new Date(response.data.insane_youtube_date_time))
            }
        });
    }
    useEffect(() => {
        if (selectedDate != null) {

            const dateTime = selectedDate.toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            }).replace(/(\d+)(st|nd|rd|th)/, '$1$2');
            setFormattedDate(dateTime);
        }
        if (selectedHWDate != null) {
            const hwDateTime = selectedHWDate.toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            }).replace(/(\d+)(st|nd|rd|th)/, '$1$2');
            setFormattedHWDate(hwDateTime);
        }
        if (insaneDate != null) {
            const iDateTime = insaneDate.toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            }).replace(/(\d+)(st|nd|rd|th)/, '$1$2');
            setFormattedInsaneDate(iDateTime);
        }
    }, [selectedDate, selectedHWDate , insaneDate])

    const validationSchema = Yup.object().shape({
        // name: Yup.string().required("Please enter tag").max(128, "Tag must be at most 128 character"),
        // url: Yup.string().required('URL is required').nullable(),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate("/webinar")
    }

    const onSubmit = (data) => {
        if (edit == 1) {
            editWebinar({ "webinar_date_time": formattedDate, "hindi_webinar_date_time": formattedHWDate, "url": url.trimStart().trimEnd(), "hindi_webinar_url": hindiUrl.trimStart().trimEnd(), "web_url": webUrl.trimStart().trimEnd(), "workshop_url": workshopUrl.trimStart().trimEnd(), "insane_youtube_date_time": formattedinsaneDate, "seminar_url": seminarUrl, "smc_url": smcUrl }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setTimeout(() => {
                        navigate("/webinar")
                    }, 2000)
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }

    }


    const handleDateChange = (date) => {
        setSelectedDate(date);
    }


    // if (tagDetails == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Webinar Settings Details</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className='col-md-6'>

                                    <div className="form-group">
                                        <label className='font-weight-bold'> Select English Webinar Date and Time </label>
                                        <div>
                                            <DatePicker
                                                // selected={selectedDate}
                                                onChange={handleDateChange}
                                                showTimeSelect
                                                placeholderText="Select Date Time"
                                                dateFormat="MMM d, yyyy h:mm aa"
                                                {...(selectedDate !== null ? { selected: selectedDate } : {})}
                                                className="form-control"
                                                required onInvalid={(e) => e.target.setCustomValidity('Please enter a valid Date')}
                                                onInput={(e) => e.target.setCustomValidity('')} />
                                            {/* <div className="invalid-feedback">{errors.name?.message}</div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className='font-weight-bold'> English Webinar URL [Webinar2024 Page] </label>
                                    <div>
                                        <input type="text" {...register('url')} className="form-control" placeholder="Please enter URL" defaultValue={url} onChange={(e) => setUrl(e.target.value)} required onInvalid={(e) => e.target.setCustomValidity('Please enter a valid URL')}
                                            onInput={(e) => e.target.setCustomValidity('')} />

                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className='font-weight-bold'> Workshop URL [Workshop Page] </label>
                                    <div>
                                        <input type="text" className="form-control" placeholder="Please enter URL" defaultValue={workshopUrl} onChange={(e) => setWorkshopUrl(e.target.value)} required onInvalid={(e) => e.target.setCustomValidity('Please enter a valid URL')}
                                            onInput={(e) => e.target.setCustomValidity('')} />

                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className='font-weight-bold'> Insane Youtube URL [Seminar Page] </label>
                                    <div>
                                        <input type="text" className="form-control" placeholder="Please enter URL" defaultValue={seminarUrl} onChange={(e) => setSeminarUrl(e.target.value)} required onInvalid={(e) => e.target.setCustomValidity('Please enter a valid URL')}
                                            onInput={(e) => e.target.setCustomValidity('')} />

                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className='font-weight-bold'> Select Hindi Webinar Date and Time </label>
                                    <div>
                                        <DatePicker
                                            // selected={selectedDate}
                                            onChange={(date) => setSelectedHWDate(date)}
                                            showTimeSelect
                                            placeholderText="Select Date Time"
                                            dateFormat="MMM d, yyyy h:mm aa"
                                            {...(selectedHWDate !== null ? { selected: selectedHWDate } : {})}
                                            className="form-control"
                                            required onInvalid={(e) => e.target.setCustomValidity('Please enter a valid Date')}
                                            onInput={(e) => e.target.setCustomValidity('')} />
                                        {/* <div className="invalid-feedback">{errors.name?.message}</div> */}
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className='font-weight-bold'> Hindi Webinar URL [Strategy Page] </label>
                                    <div>
                                        <input type="text" className="form-control" placeholder="Please enter URL" defaultValue={hindiUrl} onChange={(e) => setHindiUrl(e.target.value)} required onInvalid={(e) => e.target.setCustomValidity('Please enter a valid URL')}
                                            onInput={(e) => e.target.setCustomValidity('')} />

                                    </div>
                                </div>

                                <div className="form-group col-md-6">
                                    <label className='font-weight-bold'> Web URL [Web Page] </label>
                                    <div>
                                        <input type="text" className="form-control" placeholder="Please enter URL" defaultValue={webUrl} onChange={(e) => setWebUrl(e.target.value)} required onInvalid={(e) => e.target.setCustomValidity('Please enter a valid URL')}
                                            onInput={(e) => e.target.setCustomValidity('')}
                                        />

                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className='font-weight-bold'> Insane Youtube URL [SMC Page] </label>
                                    <div>
                                        <input type="text" className="form-control" placeholder="Please enter URL" defaultValue={smcUrl} onChange={(e) => setSmcUrl(e.target.value)} required onInvalid={(e) => e.target.setCustomValidity('Please enter a valid URL')}
                                            onInput={(e) => e.target.setCustomValidity('')}
                                        />

                                    </div>
                                </div>
                                {/* <div className='col-md-6'>

                                    <div className="form-group">
                                        <label className='font-weight-bold'> Select Insane Youtube Date and Time </label>
                                        <div>
                                            <DatePicker
                                                // selected={selectedDate}
                                                onChange={(date) => setInsaneDate(date)}
                                                showTimeSelect
                                                placeholderText="Select Date Time"
                                                dateFormat="MMM d, yyyy h:mm aa"
                                                {...(insaneDate !== null ? { selected: insaneDate } : {})}
                                                className="form-control"
                                                required onInvalid={(e) => e.target.setCustomValidity('Please enter a valid Date')}
                                                onInput={(e) => e.target.setCustomValidity('')} />
                                        </div>
                                    </div>
                                </div> */}
                               

                                
                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}