import React, { useEffect, useState } from 'react'
import { getSubAdmin,changeSubAdminStatus,checkPermission, removeSubAdmin} from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2'
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';

function SubAdminList() {
    const navigate = useNavigate();
    
    const [subAdminList, setSubAdminList] = useState(null)
    const [subAdminRemove,setSubAdminRemove]=useState(0)
    const [subAdminChangeStatus,setSubAdminChangeStatus]=useState(0)
    const [subAdminEdit,setSubAdminEdit]=useState(0)
    const [subAdminAdd,setSubAdminAdd]=useState(0)
    const [subAdminView,setSubAdminView]=useState(0)

    useEffect(() => {
        // if(localStorage.getItem("Arole") == "admin"){
            setSubAdminRemove(1)
            setSubAdminChangeStatus(1)
            setSubAdminEdit(1)
            setSubAdminAdd(1)
            setSubAdminView(1)
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                Swal.showLoading()
                }
            })
            getSubAdmin({}).then((response) => {
                Swal.close()
                response = JSON.parse(response)
                if (response.code == 200) {
                    setSubAdminList(response.data)
                }else{
                    setSubAdminList([])
                }
            })
        // }else{
        //     checkPermission({"admin_id":localStorage.getItem("Aid")}).then((response) => {
        //         response = JSON.parse(response) 
        //         response.data.map((item,key) => {
        //             if(item == 16){      
        //                 Swal.fire({
        //                     title: 'Please wait...',
        //                     didOpen: () => {
        //                     Swal.showLoading()
        //                     }
        //                 })
        //                 getSubAdmin({}).then((response) => {
        //                     Swal.close()
        //                     response = JSON.parse(response)
        //                     if (response.code == 1) {
        //                         setSubAdminList(response.data)
        //                     }else{
        //                         setSubAdminList([])
        //                     }
        //                 })
        //             }
        //             if(item == 17){
        //                 setSubAdminAdd(1)
        //                 if(subAdminList == null){
        //                     setSubAdminList([])
        //                 }
        //             }
        //             if(item == 18){
        //                 setSubAdminEdit(1)
        //             }
        //             if(item == 19){
        //                 setSubAdminRemove(1)
        //             }
        //             if(item == 20){
        //                 setSubAdminChangeStatus(1)
        //             }
                    
        //         })
        //     })
        // }
    }, [])

    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 5,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'copy',
                    className: 'btn-mute'
                  },{
                    extend: 'csv',
                    className: 'btn-mute'
                  },{
                    extend: 'print',
                    className: 'btn-mute'
                  }],
                "bDestroy": true,
            }
        );
    }, [subAdminList])

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function changeStatus(userId, item, position) {
            var status = (item.is_active == "1") ? "0" : "1"
    
            changeSubAdminStatus({ "sub_admin_id": userId, "is_active": status }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    showMessage(response.message)
                    subAdminList[position].is_active = status
                    var data = renderToStaticMarkup(renderStatusUiText(subAdminList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                    $('#mangeUser').DataTable().row("#userId" + userId).cell("#userStatus" + userId).data(data).draw(false);

                }
            }); 
        
    }
    
    function renderStatusUiText(item) {
        return (
            item.is_active == "1" ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    function deleteUserIdWise(userId) {
        if(subAdminRemove == 1){
            Swal.fire({
                title: 'Are you sure you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#02C9D6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    // console.log("userId " + userId)
                    removeSubAdmin({ "sub_admin_id": userId }).then((response) => {
                        response = JSON.parse(response)
                        if (response.code == 200) {
                            $('#mangeUser').DataTable().row("#userId" + userId).remove().draw(false);
                            showMessage(response.message)
                        }
                    });
                }
            })
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    const editSubAdmin = (item) => {
        if(subAdminEdit == 1){
            navigate(`/subadmin/edit/${item._id}`);
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    const addSubAdmin = () => {

        if(subAdminAdd == 1){
            navigate(`/subadmin/add`);
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }
    const viewUser = (item) => {
        if (subAdminView == 1) {
            navigate(`/subadmin/view/${item._id}`);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    if (subAdminList == null) return <> </>

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                        <button className='add btn-custom' onClick={addSubAdmin}><span className="mdi mdi-plus"></span></button>
                            <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                <thead>
                                    <tr>
                                        <th style={{textAlign:"center"}}>ID</th>
                                        <th style={{textAlign:"center"}}>Name</th>
                                        <th style={{textAlign:"center"}}>Email</th>
                                        <th style={{textAlign:"center"}}>Phone Number</th>
                                        <th style={{textAlign:"center"}}>Status</th>
                                        <th style={{textAlign:"center"}}>Status Action</th>
                                        <th style={{textAlign:"center"}}>Action</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th style={{textAlign:"center"}}>ID</th>
                                        <th style={{textAlign:"center"}}>Name</th>
                                        <th style={{textAlign:"center"}}>Email</th>
                                        <th style={{textAlign:"center"}}>Phone Number</th>
                                        <th style={{textAlign:"center"}}>Status</th>
                                        <th style={{textAlign:"center"}}>Status Action</th>
                                        <th style={{textAlign:"center"}}>Action</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    {
                                        subAdminList && subAdminList.map((item, index) => {
                                            return (
                                                <tr id={"userId" + item._id} >
                                                    <td style={{textAlign:"center"}}>
                                                        {index + 1}
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                        {item.name}
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                        {item.email}
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                        { "+"+item.country_code+" "+item.phone}
                                                    </td>
                                                    <td id={"userStatus" + item._id} style={{textAlign:"center"}}>
                                                        {renderStatusUiText(item)}
                                                    </td>
                                                    <td id={"userStatus"+item._id} style={{textAlign:"center"}}>
                                                        <Tooltip title="Change Status">
                                                            <IconButton onClick={()=>{changeStatus(item._id,item,index)}}>                                        
                                                                <FeatherIcon icon="repeat" width="18" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                    <td style={{textAlign:"center"}}>
                                                                                                
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={() => { editSubAdmin(item) }}>
                                                                <FeatherIcon icon="edit" width="18" />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={()=>{deleteUserIdWise(item._id)}}>                                         
                                                                <FeatherIcon icon="trash-2" width="18" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="View">
                                                            <IconButton onClick={() => viewUser(item)}>
                                                                <FeatherIcon icon="eye" width="18" /> {/* Replace with your desired view icon */}
                                                            </IconButton>
                                                        </Tooltip>    
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubAdminList;