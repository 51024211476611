
import React from 'react'

function Footer() {
  return (
    <div className=''>
      <footer className="footer">
    © {new Date().getFullYear()} STOCKWIZ
</footer> 
    </div>
  )
}

export default Footer;