import React from 'react';
<link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap" rel="stylesheet"></link>

const StockCard = (props) => {
  return (
    <div style={{ width: '500px', height: '300px', position: 'relative' }}>
      <div
        style={{
          width: '500px',
          height: '300px',
          left: '0px',
          top: '10px',
          position: 'absolute',
          // boxShadow: '15.8px 15.8px 15.8px',
          borderRadius: '18px',
          border: '2px #29E337 solid',
          // filter: 'blur(15.8px)',
          background: '#010903',
        }}
      ></div>
      <div
        style={{
          width: '500px',
          height: '300px',
          left: '0px',
          top: '10px',
          position: 'absolute',
          background:
            'radial-gradient(40.33% 57.16% at 67.12% 20.41%, rgba(41, 227, 55, 0.41) 0%, rgba(41, 227, 55, 0) 100%), linear-gradient(239deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.11) 100%)',
          borderRadius: '18px',
          overflow: 'hidden',
          border: '1px #29E337 solid',
          backdropFilter: 'blur(80px)',
        }}
      >
        <div
          style={{
            left: '16px',
            top: '24px',
            position: 'absolute',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '10px',
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              width: '50px',
              height: '50px',
              paddingLeft: '6px',
              paddingRight: '6px',
              paddingTop: '10px',
              paddingBottom: '10px',
              background: 'white',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
              borderRadius: '30px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <img
              style={{ width: '40px', height: '46px' }}
              src={props.scrip_logo}
              alt="logo"
            />
          </div>
          <div
            style={{
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: '6px',
              display: 'inline-flex',
            }}
          >
            <div
              style={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '10px',
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  width: '280px',
                  height: '19px',
                  color: 'white',
                  fontSize: '14px',
                   fontFamily: 'Inter, sans-serif',
                  fontWeight: '600',
                  wordWrap: 'break-word',
                }}
              >
                {/* RELIANCE */}
                {props.scrip_symbol ?? 'RELIANCE'}
              </div>
              <div
                style={{
                  paddingLeft: '6px',
                  paddingRight: '6px',
                  paddingTop: '2px',
                  paddingBottom: '2px',
                  background:
                    'linear-gradient(79deg, #6B6B6B 0%, rgba(11.52, 0.64, 38.25, 0) 100%)',
                  borderRadius: '16px',
                  border: '0.50px white solid',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '2px',
                  display: 'flex',
                }}
              >
                <img
                  style={{ width: '10px', height: '10px' }}
                  src={process.env.PUBLIC_URL + "/assets/images/icon.svg"}
                  alt="icon"
                />
                <div
                  style={{
                    color: 'white',
                    fontSize: '10px',
                     fontFamily: 'Inter, sans-serif',
                    fontWeight: '500',
                    wordWrap: 'break-word',
                  }}
                >
                  NSE
                </div>
              </div>
              <div
                style={{
                  width: '2px',
                  height: '2px',
                  background: 'rgba(255, 255, 255, 0.30)',
                  borderRadius: '9999px',
                }}
              ></div>
              <div
                style={{
                  paddingLeft: '6px',
                  paddingRight: '6px',
                  paddingTop: '2px',
                  paddingBottom: '2px',
                  background: 'linear-gradient(105deg, #FFCB59 0%, #EBA200 100%)',
                  boxShadow: '0px 0px 30px rgba(253, 200, 82, 0.80)',
                  borderRadius: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    color: 'black',
                    fontSize: '10px',
                     fontFamily: 'Inter, sans-serif',
                    fontWeight: '600',
                    wordWrap: 'break-word',
                  }}
                >
                  New
                </div>
              </div>
            </div>
            <div
              style={{
                color: 'rgba(255, 255, 255, 0.80)',
                fontSize: '14px',
                 fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                wordWrap: 'break-word',
              }}
            >
              {props.scrip_name ?? 'Reliance Industries Ltd'}
            </div>
            <div
              style={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '4px',
                display: 'inline-flex',
              }}
            >
              <div
                style={{
                  color: 'rgba(255, 255, 255, 0.50)',
                  fontSize: '12px',
                   fontFamily: 'Inter, sans-serif',
                  fontWeight: '400',
                  lineHeight: '14px',
                  wordWrap: 'break-word',
                }}
              >
                By: StockWiz Technologies
              </div>
              <div><img src={process.env.PUBLIC_URL + "/assets/images/Frame.svg"} alt="checkmark" /></div>
            </div>
          </div>
        </div>
        <div
          style={{
            left: '186px',
            top: '112px',
            position: 'absolute',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            gap: '4px',
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              color: 'white',
              fontSize: '12px',
               fontFamily: 'Inter, sans-serif',
              fontWeight: '500',
              wordWrap: 'break-word',
            }}
          >
            <b>Entry Price : </b> {props.ltp}
          </div>
        </div>
        <div style={{
          position: 'absolute',
          left: '22.89px',
          top: '143px',
          display: 'inline-flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '9.36px'
        }}>
          <div style={{
            width: '395px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10.40px'
          }}>
            <div style={{
              flex: '1 1 0',
              display: 'inline-flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '4.16px'
            }}>
              <div style={{
                color: 'rgba(255, 255, 255, 0.60)',
                fontSize: '12.48px',
                 fontFamily: 'Inter, sans-serif',
                fontWeight: 400,
                wordWrap: 'break-word'
              }}>Estimated Gains</div>
              <div style={{
                display: 'inline-flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start'
              }}>
                <div style={{
                  color: '#29E337',
                  fontSize: '14.56px',
                   fontFamily: 'Inter, sans-serif',
                  fontWeight: 600,
                  lineHeight: '20.81px',
                  wordWrap: 'break-word'
                }}>{props.percentage_gain ? `+${props.percentage_gain.toFixed(2)}%` : '+34.55%'}
                </div>
              </div>
            </div>
            <div style={{
              width: '41.61px',
              height: '0px',
              transform: 'rotate(90deg)',
              transformOrigin: '0 0',
              border: '1.04px rgba(255, 255, 255, 0.05) solid',
              marginLeft: '38px',
              marginTop: '-36px',
            }}></div>
            <div style={{
              flex: '1 1 0',
              display: 'inline-flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '4.16px'
            }}>
              <div style={{
                color: 'rgba(255, 255, 255, 0.60)',
                fontSize: '12.48px',
                 fontFamily: 'Inter, sans-serif',
                fontWeight: 400,
                wordWrap: 'break-word'
              }}>Status</div>
              <div style={{
                color: '#29E337',
                fontSize: '14.56px',
                 fontFamily: 'Inter, sans-serif',
                fontWeight: 600,
                lineHeight: '20.81px',
                wordWrap: 'break-word'
              }}>In Buying Range</div>
            </div>
          </div>
          <div style={{
            width: '265.29px',
            display: 'inline-flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '24.97px'
          }}>
            <div style={{
              flex: '1 1 0',
              height: '0px',
              border: '1.04px rgba(255, 255, 255, 0.05) solid'
            }}></div>
            <div style={{
              flex: '1 1 0',
              height: '0px',
              border: '1.04px rgba(255, 255, 255, 0.05) solid'
            }}></div>
          </div>
          <div style={{
            width: '395px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10.40px'
          }}>
            <div style={{
              flex: '1 1 0',
              display: 'inline-flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '4.16px'
            }}>
              <div style={{
                color: 'rgba(255, 255, 255, 0.60)',
                fontSize: '12.48px',
                 fontFamily: 'Inter, sans-serif',
                fontWeight: 400,
                wordWrap: 'break-word'
              }}>{props.label1.label}</div>
              <div style={{
                display: 'inline-flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start'
              }}>
                <div style={{
                  color: 'white',
                  fontSize: '14.56px',
                   fontFamily: 'Inter, sans-serif',
                  fontWeight: 600,
                  lineHeight: '20.81px',
                  wordWrap: 'break-word'
                }}>{`₹ ${props.label1.value ?? '-'}`}</div>
              </div>
            </div>
            <div style={{
              width: '41.61px',
              height: '0px',
              transform: 'rotate(90deg)',
              transformOrigin: '0 0',
              border: '1.04px rgba(255, 255, 255, 0.05) solid',
              marginLeft: '40px',
              marginTop: '-20px',
            }}></div>
            <div style={{
              flex: '1 1 0',
              display: 'inline-flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '4.16px'
            }}>
              <div style={{
                color: 'rgba(255, 255, 255, 0.60)',
                fontSize: '12.48px',
                 fontFamily: 'Inter, sans-serif',
                fontWeight: 400,
                wordWrap: 'break-word'
              }}>{props.label2.label}</div>
              <div style={{
                display: 'inline-flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start'
              }}>
                <div style={{
                  color: 'white',
                  fontSize: '14.56px',
                   fontFamily: 'Inter, sans-serif',
                  fontWeight: 600,
                  lineHeight: '20.81px',
                  wordWrap: 'break-word'
                }}>₹{props.label2.value ?? '-'}</div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
     
      <div style={{ height: '20.81px', paddingLeft: '10.40px', paddingRight: '10.40px', paddingTop: '2.08px', paddingBottom: '2.08px', left: '16.65px', top: '0px', position: 'absolute', background: 'linear-gradient(180deg, #FB0871 0%, #FF72AF 100%)', boxShadow: '0px 0px 20.806915283203125px rgba(255, 113.69, 174.75, 0.60)', borderRadius: '9.36px', justifyContent: 'center', alignItems: 'center', gap: '10.40px', display: 'inline-flex' }}>
        <div style={{ color: 'white', fontSize: '10.40px',  fontFamily: 'Inter, sans-serif', fontWeight: '500', wordWrap: 'break-word' }}>{props.trade_category ?? 'Intraday'}</div>
      </div>
      <div style={{ height: '20.81px', width : '128px', paddingLeft: '10.40px', paddingRight: '10.40px', paddingTop: '2.08px', paddingBottom: '2.08px', left: '356.23px', top: '0px', position: 'absolute', background: 'linear-gradient(180deg, #29E337 0%, #76FF80 100%), linear-gradient(180deg, #29D8E3 0%, #43F4FF 0%, #32B5FF 100%)', boxShadow: '0px 0px 20.806915283203125px rgba(88, 255, 41, 0.40)', borderRadius: '6.24px', justifyContent: 'center', alignItems: 'center', gap: '10.40px', display: 'inline-flex' }}>
        <div style={{ color: '#0E0B1A', fontSize: '10.40px',  fontFamily: 'Inter, sans-serif', fontWeight: '500', wordWrap: 'break-word' }}>{props.segment ?? 'Short Term'}</div>
      </div>
    </div>
   
  );
};
export default StockCard;
