import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getSymbols, addBasket, getCategories, getSectors } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Select from 'react-select';
import S3 from 'react-aws-s3';
import { labelOptions } from '../../globals/constant';

const config = {
    bucketName: process.env.REACT_APP_S3_NAME,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    s3Url: process.env.REACT_APP_S3URL,
};

export default function AddBasket() {

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Please enter title").max(128, "Title must be at most 128 character"),
        about: Yup.string().required("Please enter about").max(250, "About must be at most 250 character"),
        // stock: Yup.object().required("Please enter stock"),
        category: Yup.object().required("Please enter category"),
        label: Yup.array().required("Please select labels"),
        gradient1: Yup.string().required("Please enter gradient color"),
        gradient2: Yup.string().required("Please enter gradient color"),
        sector: Yup.object().required("Please enter sector"),
        // stock_percentage: Yup.string().required("Please enter stock percentage").matches('^[0-9]', 'Only numbers are allowed for this field')
        // title: Yup.string().required("Please enter tag").max(128, "Tag must be at most 128 character"),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();
    const [symbolList, setSymbolList] = useState(null)
    const [categoryList, setCategoryList] = useState(null)
    const [category, setCategory] = useState(null);
    const [basketIcon, setBasketIcon] = useState(null);
    const [basketPDF, setBasketPDF] = useState(null);
    const [infoArray, setInfoArray] = useState([]);
    const [stockAllocations, setStockAllocations] = useState([{ stock: [], percentage: '0', quantity: 0 }]);
    const [totalPercentage, setTotalPercentage] = useState(0);
    const [label, setLabel] = useState(null);
    const [Err, setErr] = useState('');
    const [sectorList, setSectorList] = useState(null)
    const [sector, setSector] = useState(null)

    function redirect() {
        navigate("/basket")
    }
    useEffect(() => {
        getSymbols({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setSymbolList(response.data)
                getCategories({ "module": 4 }).then((categoryData) => {
                    categoryData = JSON.parse(categoryData)
                    if (categoryData.code == 200) {
                        setCategoryList(categoryData.data)
                        getSectors({}).then((sectorData) => {
                            sectorData = JSON.parse(sectorData)
                            if (sectorData.code == 200) {
                                setSectorList(sectorData.data)
                            }
                        })
                    }
                })
            }
        })
    }, [])
    const onSubmit = (data) => {
      
        if (stockAllocations.length == 1 && stockAllocations[0].percentage == 0 && stockAllocations[0].quantity == 0) {

            setErr('Please enter atleast 1 stock details')
        }
        else {
            addBasket({ "title": data.title.trimStart().trimEnd(), "about": data.about.trimStart().trimEnd(), "stock_allocations": stockAllocations, "basket_icon": basketIcon, "report_pdf": basketPDF, "stock_percentage": data.stock_percentage, "sub_baskets": infoArray, "category_id": category, "labels": label, "gradient1": data.gradient1.trimStart().trimEnd(), "gradient2": data.gradient2.trimStart().trimEnd() , "sector" : sector }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setTimeout(() => {
                        navigate("/basket")
                    }, 2000)
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        }
    }


    const handleAddStockAllocation = () => {
        if (totalPercentage + parseFloat(stockAllocations[stockAllocations.length - 1]?.percentage) > 100) {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You cannot add more stocks. Total percentage exceeds 100%',
            })
            return;
        }
        setStockAllocations([...stockAllocations, { stock: [], percentage: '0', quantity: '0' }]);
    };

    const handleRemoveStockAllocation = (index) => {
        const updatedAllocations = [...stockAllocations];
        updatedAllocations.splice(index, 1);
        setStockAllocations(updatedAllocations);
        setTotalPercentage(totalPercentage - parseFloat(updatedAllocations.percentage));

    };

    const handleStockChange = (index, selectedOptions) => {
        const updatedAllocations = [...stockAllocations];
        updatedAllocations[index].stock = selectedOptions;
        setStockAllocations(updatedAllocations);
    };

    const handlePercentageChange = (index, value) => {
        const updatedAllocations = [...stockAllocations];
        updatedAllocations[index].percentage = value;
        setStockAllocations(updatedAllocations);
        calculateTotalPercentage();
    };
    const handleQuantityChange = (index, value) => {
        const updatedAllocations = [...stockAllocations];
        updatedAllocations[index].quantity = value;
        setStockAllocations(updatedAllocations);
    };
    const calculateTotalPercentage = () => {
        const newTotalPercentage = stockAllocations.reduce((total, allocation) => total + parseFloat(allocation.percentage), 0);
        setTotalPercentage(newTotalPercentage);
    };


    const handleAddInfo = () => {
        // Add information dynamically
        const newInfo = {
            title: '',
            about: '',
            basket_icon: '',
            report_pdf: '',
            category_id: '',
            stock_allocations: [{ stock: [], percentage: '0' }],
            stock_percentage: ''
        };

        setInfoArray(prevInfoArray => [...prevInfoArray, newInfo]);
    };
    const handleAddSubStockAllocation = (infoIndex) => {
        const updatedInfoArray = infoArray.map((info, index) =>
            index === infoIndex
                ? {
                    ...info,
                    stock_allocations: [...info.stock_allocations, { stock: [], percentage: '0' }],
                }
                : info
        );

        const info = updatedInfoArray[infoIndex];
        const totalPercentage = calculateSubTotalPercentage(info.stock_allocations);

        // Assuming max percentage is 100
        if (totalPercentage >= 100) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You cannot add more stocks. Total percentage exceeds 100%',
            })
            return;
        }

        // Update the state or perform any other necessary actions
        setInfoArray(updatedInfoArray);
    };

    const handleSubStockChange = (infoIndex, allocationIndex, selectedOptions) => {
        // Handle change in selected stocks for a stock allocation
        setInfoArray((prevInfoArray) =>
            prevInfoArray.map((info, index) =>
                index === infoIndex
                    ? {
                        ...info,
                        stock_allocations: info.stock_allocations.map((allocation, i) =>
                            i === allocationIndex ? { ...allocation, stock: selectedOptions } : allocation
                        ),
                    }
                    : info
            )
        );
    };

    const handleSubPercentageChange = (infoIndex, allocationIndex, value) => {
        // Handle change in percentage for a stock allocation
        setInfoArray((prevInfoArray) =>
            prevInfoArray.map((info, index) =>
                index === infoIndex
                    ? {
                        ...info,
                        stock_allocations: info.stock_allocations.map((allocation, i) =>
                            i === allocationIndex ? { ...allocation, percentage: value } : allocation
                        ),
                    }
                    : info
            )
        );
    };
    const handleSubQuantityChange = (infoIndex, allocationIndex, value) => {
        // Handle change in percentage for a stock allocation
        setInfoArray((prevInfoArray) =>
            prevInfoArray.map((info, index) =>
                index === infoIndex
                    ? {
                        ...info,
                        stock_allocations: info.stock_allocations.map((allocation, i) =>
                            i === allocationIndex ? { ...allocation, quantity: value } : allocation
                        ),
                    }
                    : info
            )
        );
    };
    const handleRemoveSubStockAllocation = (infoIndex, allocationIndex) => {
        // Remove a stock allocation for a specific info
        setInfoArray((prevInfoArray) =>
            prevInfoArray.map((info, index) =>
                index === infoIndex
                    ? {
                        ...info,
                        stock_allocations: info.stock_allocations.filter((_, i) => i !== allocationIndex),
                    }
                    : info
            )
        );
    };
    // Function to calculate total stock for an infoArray element
    const calculateTotalStock = (stockAllocations) => {
        return stockAllocations.length;
    };

    // Function to calculate total percentage for an infoArray element
    const calculateSubTotalPercentage = (stockAllocations) => {
        const newPercentage = stockAllocations.reduce((total, allocation) => total + (allocation.percentage ? Number(allocation.percentage) : 0), 0);

        return newPercentage;

    };
    const handleSubBasketChange = (index, field, value) => {
        // Update the field value in the array
        setInfoArray(prevInfoArray => {
            const newArray = [...prevInfoArray];
            newArray[index][field] = value;
            return newArray;
        })
    };

    const handleDeleteInfo = index => {
        setInfoArray(prevInfoArray => {
            const newArray = [...prevInfoArray];
            newArray.splice(index, 1);
            return newArray;
        });
    };
    const handleSelectChange = (index, selectedOptions) => {
        setInfoArray(prevInfoArray => {
            const newArray = [...prevInfoArray];
            newArray[index].category_id = selectedOptions;
            return newArray;
        });
    };
    const handleFileInput = (e, key, isSubBasket, index) => {
        const path = key == 'basket_icon' ? 'icon' : 'report_pdf'
        const files = e.target.files[0];
        const newfile = new Date().getTime() + files.name
        config.dirName = path;
        const ReactS3Client = new S3(config);

        ReactS3Client.uploadFile(files, newfile).then(data => {
            if (data.status === 204) {

                // setImageErr('')
                if (isSubBasket == 1) {
                    setInfoArray(prevInfoArray => {
                        const newArray = [...prevInfoArray];
                        newArray[index][key] = data.location.split("/")[4];
                        return newArray;
                    })
                }
                else {
                    key == 'basket_icon' ? setBasketIcon(data.location.split("/")[4]) : setBasketPDF(data.location.split("/")[4])
                }
            } else {
                console.log('obj err')
            }
        });
    }
    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Add Basket</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Title </label>
                                            <div>
                                                <input type="text" placeholder='Please enter title' className="form-control" {...register('title')} />
                                            </div>
                                            <div className="invalid-feedback">{errors.title?.message}</div>

                                        </div>
                                        <div className='col-md-6'>
                                            <label> About / Bio </label>
                                            <div>

                                                <textarea name="data" placeholder='Please enter bio' className="form-control" {...register('about')} cols="2" rows="2"></textarea>
                                                <div className="invalid-feedback">{errors.about?.message}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className='row'>

                                        <div className='col-md-6'>
                                            <label> Upload Icon </label>
                                            <div>
                                                <input type="file" className="form-control" id="inputGroupFile01" onChange={e => handleFileInput(e, 'basket_icon', 0, 0)} />

                                                <div className="invalid-feedback">{ }</div>


                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Upload Report PDF </label>
                                            <div>
                                                <input type="file" className="form-control" id="inputGroupFile01" onChange={e => handleFileInput(e, 'report_pdf', 0, 0)} />

                                                <div className="invalid-feedback">{ }</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Gradient Color 1 </label>
                                            <div>
                                                <input type="text" {...register('gradient1')} className="form-control" placeholder="Please enter gradient color 1" />
                                                <div className="invalid-feedback">{errors.gradient1?.message}</div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <label>  Gradient Color 2 </label>
                                            <div>
                                                <input type="text" {...register('gradient2')} className="form-control" placeholder="Please enter gradient color 2" />
                                                <div className="invalid-feedback">{errors.gradient2?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>

                                        <div className='col-md-6'>
                                            <label>Category </label>
                                            <div>
                                                <Controller
                                                    name="category"
                                                    control={control}
                                                    rules={{ required: 'Please select segment' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            options={categoryList}
                                                            value={category}
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                setCategory(selectedOption)
                                                            }}
                                                            placeholder="Select Category"
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                />
                                                <div className="invalid-feedback">{errors.category?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Labels </label>
                                            <div>
                                                <Controller
                                                    name="label"
                                                    control={control}
                                                    rules={{ required: 'Please select label' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            options={labelOptions}
                                                            value={field.value}
                                                            isMulti={true}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                setLabel(selectedOption);
                                                            }}
                                                            placeholder="Select Labels"
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                />

                                                <div className="invalid-feedback">{errors.label?.message}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>

                                        <div className='col-md-6'>
                                            <label>Sector </label>
                                            <div>
                                                <Controller
                                                    name="sector"
                                                    control={control}
                                                    rules={{ required: 'Please select segment' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            options={sectorList}
                                                            value={sector}
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                setSector(selectedOption)
                                                            }}
                                                            placeholder="Select Sector"
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                />
                                                <div className="invalid-feedback">{errors.sector?.message}</div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                {stockAllocations.map((allocation, index) => (
                                    <div className='row mt-2' key={index}>
                                        <div className='col-md-6'>
                                            <label>
                                                Stock:
                                            </label>
                                            <Select

                                                name="colors"
                                                options={symbolList}
                                                value={allocation.stock} onChange={(selectedOptions) => handleStockChange(index, selectedOptions)}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />



                                        </div>
                                        <div className='col-md-2'>

                                            <label>
                                                Percentage:</label>
                                            <input
                                                type="number"
                                                min={0}
                                                className='form-control'
                                                value={allocation.percentage}
                                                onChange={(e) => handlePercentageChange(index, e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-2'>
                                            <label>
                                                Quantity:</label>
                                            <input
                                                type="number"
                                                min={0}
                                                className='form-control'
                                                value={allocation.quantity}
                                                onChange={(e) => handleQuantityChange(index, e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-2'>
                                            <button className='mt-4 btn btn-danger' onClick={() => handleRemoveStockAllocation(index)}>Delete
                                            </button>
                                        </div>

                                    </div>

                                ))}
                                <div className="invalid-feedback">{Err}</div>


                                <div className='row ml-1 mt-2'>
                                    <div className='col-md-6'>

                                        <button type="button" className='btn btn-primary' onClick={handleAddStockAllocation}>
                                            Add Stock
                                        </button>
                                    </div>

                                    <div className="col-md-6">
                                        <p><b>Total Stock: </b>{stockAllocations.length} <br />
                                            <b>Total Percentage: </b> {totalPercentage}%</p>
                                    </div>
                                </div>
                                {infoArray.map((info, index) => (
                                    <div key={index} className="form-group mt-2" style={{ border: "2px solid black", padding: "10px", position: "relative" }}>
                                        <i className="fas fa-times" style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer", color: "white", backgroundColor: "red", borderRadius: "50%", padding: "5px" }} onClick={() => handleDeleteInfo(index)}></i>

                                        <div className="form-group">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label> Title </label>
                                                    <div>
                                                        <input type="text" placeholder='Please enter name' value={info.title} className="form-control" onChange={e => handleSubBasketChange(index, 'title', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label> About / Bio </label>
                                                    <div>

                                                        <textarea name="data" placeholder='Please enter bio' value={info.bio} className="form-control" onChange={e => handleSubBasketChange(index, 'about', e.target.value)} cols="2" rows="2"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className='row'>

                                                <div className='col-md-6'>
                                                    <label> Upload Icon </label>
                                                    <div>
                                                        <input type="file" onChange={e => handleFileInput(e, 'basket_icon', 1, index)} className="form-control" id="inputGroupFile01" />

                                                        <div className="invalid-feedback">{ }</div>

                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label> Upload Report PDF </label>
                                                    <div>
                                                        <input type="file" className="form-control" onChange={e => handleFileInput(e, 'report_pdf', 1, index)} id="inputGroupFile01" />

                                                        <div className="invalid-feedback">{ }</div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className='row'>

                                                <div className='col-md-6'>
                                                    <label>Category </label>
                                                    <div>
                                                        <Select required

                                                            name="colors"
                                                            options={categoryList}
                                                            value={info.category} onChange={(selectedOption) => handleSelectChange(index, selectedOption)}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <label> <strong>Stock Allocations</strong> </label>
                                                    <div>
                                                        {info.stock_allocations.map((allocation, stockIndex) => (

                                                            <div className='row mt-2' key={stockIndex}>
                                                                <div className='col-md-6'>
                                                                    <label>
                                                                        Stock:
                                                                    </label>
                                                                    <Select required

                                                                        name="colors"
                                                                        options={symbolList}
                                                                        value={allocation.stock_allocations} onChange={(selectedOptions) => handleSubStockChange(index, stockIndex, selectedOptions)}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />


                                                                </div>
                                                                <div className='col-md-2'>

                                                                    <label>
                                                                        Percentage:</label>
                                                                    <input
                                                                        type="number"
                                                                        min={0}
                                                                        className='form-control'
                                                                        value={allocation.percentage}
                                                                        onChange={(e) => handleSubPercentageChange(index, stockIndex, e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className='col-md-2'>
                                                                    <label>
                                                                        Quantity:</label>
                                                                    <input
                                                                        type="number"
                                                                        min={0}
                                                                        className='form-control'
                                                                        value={allocation.quantity}
                                                                        onChange={(e) => handleSubQuantityChange(index, e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className='col-md-2'>
                                                                    <button className='mt-4 btn btn-danger' onClick={() => handleRemoveSubStockAllocation(index, stockIndex)}>Remove
                                                                    </button>

                                                                </div>
                                                            </div>




                                                        ))}


                                                        <div className='row ml-1 mt-2'>
                                                            <div className='col-md-6'>

                                                                <button type="button" className='btn btn-primary' onClick={() => handleAddSubStockAllocation(index)}>
                                                                    Add Stock Allocation
                                                                </button>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p><b>Total Stock: </b>{calculateTotalStock(info.stock_allocations)} <br />
                                                                    <b>Total Percentage: </b> {calculateSubTotalPercentage(info.stock_allocations)}%</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                ))}
                                <center>
                                    <button type="button" className="btn btn-info waves-effect waves-light m-2 btn-custom" onClick={handleAddInfo}>
                                        Add Subbasket
                                    </button></center>
                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Add
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
