import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addLabel } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { moduleOptions } from '../../globals/constant';
import Select from 'react-select';



export default function AddLabel() {

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter category").max(128, "Category must be at most 128 character"),
        // type: Yup.string().required('Category type is required').nullable(),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const [selectedModule, setSelectedModule] = useState(null)

    const navigate = useNavigate();

    function redirect() {
        navigate("/label")
    }

    const onSubmit = (data) => {

        addLabel({ "name": data.name.trimStart().trimEnd() , "modules" : selectedModule }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/label")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-4 col-md-4 col-sm-4'>
                        <h5>Add Category</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="form-group">
                                    <label> Module </label>
                                    <div>
                                    <Select
                                        isMulti
                                        aria-labelledby="aria-label"
                                        placeholder="Modules.."
                                        value={selectedModule}
                                        onChange={(e) => setSelectedModule(e)}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        name="aria-live-color"
                                        options={moduleOptions}
                                        required
                                    />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label> Name </label>
                                    <div>
                                        <input type="text" {...register('name')} className="form-control" placeholder="Please enter category" />
                                        <div className="invalid-feedback">{errors.name?.message}</div>
                                    </div>
                                </div>
                                
                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Add
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
