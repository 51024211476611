/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getSignalById, editSignal } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Select from 'react-select';
import S3 from 'react-aws-s3';
import {uploadImageS3} from '../../common/Common'
window.Buffer = window.Buffer || require("buffer").Buffer;



export default function Editsignal() {

    const validationSchema = Yup.object().shape({

    });
    const navigate = useNavigate();
    let { id } = useParams();
    let contentRef = useRef(null);

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, handleSubmit, formState, setValue, reset, getValues } = useForm(formOptions);

    const { errors } = formState;
    const [signalDetails, setSignalDetails] = useState(false)
    const [radioType1, setRadioType1] = useState(null);
    const [radioType2, setRadioType2] = useState(null);
    const [chartImage, seChartImage] = useState(null);
    const [reportPDF, setReportPDF] = useState(null);
    const [buyingZone, setBuyingZone] = useState({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' });
    const [sellingZone, setSellingZone] = useState({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' });
    const [trailingSL, setTrailingSL] = useState(null);
    const [partialProfit, setPartialProfit] = useState(null);


   

    const handleSellingZoneChange = (e) => {
        const { name, value } = e.target;
        const updatedValues = { [name]: value };

        if (name === 'lowerRange') {
            updatedValues['lowerPrice'] = (signalDetails?.target_price?.price - parseFloat(value)).toFixed(2);
        } else if (name === 'upperRange') {
            updatedValues['upperPrice'] = (signalDetails?.target_price?.price + parseFloat(value)).toFixed(2);
        }

        setSellingZone((prevState) => ({ ...prevState, ...updatedValues }));
    };

    function redirect() {
        navigate("/signal")
    }


    useEffect(() => {
        if (id != undefined) {
            getSignalById({ "signal_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setSignalDetails(res.data)
                    setBuyingZone(res.data.buying_zone);
                    setSellingZone(res.data.selling_zone);
                    seChartImage(res.data.chart_image);
                    setReportPDF(res.data.report_pdf);
                    setRadioType1(res.data.type == '1')
                    setRadioType2(res.data.type == '2')
                    setTrailingSL(res.data.trailing_stoploss)
                    setPartialProfit(res.data.partial_profit)
                }
            });
        }

    }, [id])

    const image = chartImage != null ? `${process.env.REACT_APP_S3URL}/chart_image/${chartImage}` : '';
    const pdf = reportPDF != null ? `${process.env.REACT_APP_S3URL}/report_pdf/${reportPDF}` : '';
    // For Entry Price [ CMP and Custom Price ]

    //s3 file upload - Chart image
    const handleFileInput = async (e) => {
        const imageName  = await uploadImageS3({"dir_name" : 'chart_image' , 'file' : e.target.files[0]})
        console.log("Image :: ",imageName);
        seChartImage(imageName)
    }
    //s3 file upload - Report PDF
    const handlePDFInput = async (e) => {
        const pdfName  = await uploadImageS3({"dir_name" : 'report_pdf' , 'file' : e.target.files[0]})
        setReportPDF(pdfName)
    }
    // "chart_image": chartImage, "report_pdf": reportPDF,
    const onSubmit = (data) => {
        // console.log(data, "=========");
        editSignal({ "signal_id": id, "buying_zone": buyingZone, "selling_zone": sellingZone, "chart_image": chartImage, "report_pdf": reportPDF, "trailing_stoploss": trailingSL, "partial_profit": partialProfit }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/signal")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    if (signalDetails == null) return <> </>
    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Edit Signal</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Signal Name </label>
                                            <div>
                                                <input className="form-control" name="t2" type="text" value={signalDetails?.name} readOnly />

                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Trade Category </label>
                                            <div>

                                                <input className="form-control" name="t2" type="text" value={signalDetails?.trade_category} readOnly />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Signal category </label>
                                            <div>
                                                <input type="text" name='category' value={signalDetails?.category} className='form-control' readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Signal Type </label>
                                            <div>
                                                <input type="radio" id="age1" name="stype" value="1" checked={radioType1} readOnly />&nbsp;Free
                                                &nbsp;
                                                <input type="radio" id="age2" name="stype" value="2" checked={radioType2} readOnly />&nbsp;Premium
                                            </div>
                                        </div>

                                        {/* <div className='col-md-6'>
                                            <label> Please select option </label>
                                            <div>
                                                <input type="checkbox" name='option' value="1" checked={option1} onChange={handleSignalOption1Change} />&nbsp; Add More&nbsp;&nbsp;
                                                <input type="checkbox" name='option' value="2" checked={option2} onChange={handleSignalOption2Change} />&nbsp;Book Full Profit&nbsp;&nbsp;
                                                <input type="checkbox" name='option' value="3" checked={option3} onChange={handleSignalOption3Change} />&nbsp;Book Partial Profit
                                            </div>

                                            {
                                                option == false ? <></> : <><br />
                                                    <div className="form-group">
                                                        <div >
                                                            <input type="checkbox" name='option_type' value="1" checked={optionType1} onChange={handleSignalOptionType1Change} />&nbsp; Update Label&nbsp;&nbsp;
                                                            <input type="checkbox" name='option_type' value="2" checked={optionType2} onChange={handleSignalOptionType2Change} />&nbsp; Send Notification&nbsp;&nbsp;
                                                            <input type="checkbox" name='option_type' value="3" checked={optionType3} onChange={handleSignalOptionType3Change} />&nbsp; Both
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div> */}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>

                                            <label htmlFor="mySelect2">Symbols </label>
                                            <div className='custom-select-container'>
                                                <input type="text" readOnly value={signalDetails?.symbol} className="form-control" />

                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Segment </label>
                                            <div>
                                                <input type="text" name='category' value={signalDetails?.segment} className='form-control' readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className="font-weight-bold"> Entry Price </label>
                                            <div>
                                                <input className="form-control" name="t2" type="text" value={signalDetails?.entry_price} readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className="font-weight-bold"> Publish Date </label>
                                            <div>
                                                <input type="text" readOnly value={signalDetails?.publish_date} className="form-control" {...register('publish_date')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className="font-weight-bold">Stop Loss or Risk </label>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label htmlFor="percentage">Percentage:</label>
                                                    <div>
                                                        <input className="form-control red-input" type="number" placeholder="Please enter Percentage"
                                                            value={signalDetails?.stoploss?.percentage} readOnly />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <span style={{ marginLeft: '-23px', marginRight: '6px' }}>OR</span>
                                                    <label htmlFor="price">Price:</label>
                                                    <input className="form-control red-input" id="siret" type="text" placeholder="Please enter Price" value={signalDetails?.stoploss?.price} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className="font-weight-bold"> Target Price </label>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label htmlFor="percentage">Percentage:</label>
                                                    <div>
                                                        <input className="form-control green-input" type="number" placeholder="Please enter Percentage" readOnly value={signalDetails?.target_price?.percentage} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <span style={{ marginLeft: '-23px', marginRight: '6px' }}>OR</span>
                                                    <label htmlFor="price">Price:</label>
                                                    <input className="form-control green-input" readOnly type="text" placeholder="Please enter Price" value={signalDetails?.target_price?.price} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className="font-weight-bold"> Trailing Stoploss </label>
                                            <input className="form-control" name="t2" type="text" placeholder='Please Enter Price' value={trailingSL} onChange={(e) => setTrailingSL(e.target.value)} />

                                        </div>
                                        <div className='col-md-6'>
                                            <label className="font-weight-bold"> Partial Profit </label>
                                            <input className="form-control" name="t2" type="text" placeholder='Please Enter Price' value={partialProfit} onChange={(e) => setPartialProfit(e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className='row'>

                                        <div className='col-md-6'>
                                            <label> Upload Chart Image </label>
                                            <div>
                                                <input type="file" className="form-control" id="inputGroupFile01" onChange={handleFileInput} />
                                                {/*  */}


                                            </div>
                                            {image != '' && (<span class="loc-caption">
                                                <div className="mb-3">
                                                    <div className="profile-pic">

                                                        <img src={image} id="output" width="200" />
                                                    </div>
                                                </div>
                                            </span>)}
                                            <div className="invalid-feedback">{errors.chart_image?.message}</div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Upload Report PDF </label>
                                            <div>
                                                <input type="file" className="form-control" onChange={handlePDFInput} />

                                            </div>
                                            {pdf != '' && (<span class="loc-caption">
                                                            <div className="mb-3">

                                                            <a href={pdf} target="_blank" rel="noreferrer" style={{ color: 'blue' }}> Click Here To View PDF </a>
                                                            </div>
                                                        </span>)}
                                            <div className="invalid-feedback">{errors.report_pdf?.message}</div>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>  New Idea </label>
                                            <div>
                                                <input type="checkbox" id="switch1" switch="none" checked={newIdeaOn} onChange={(event) => setNewIdeaOn(event.target.value)} />
                                                <label id="agentStatus" for="switch1" class="data_lable" data-on-label="Yes" data-off-label="No"></label>

                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Display on Homepage </label>
                                            <div>
                                                <input type="checkbox" id="switch2" switch="none" checked={displayOnHomePage} onChange={(event) => event.target.checked == true ? 1 : 0} />
                                                <label id="agentStatus" for="switch2" class="data_lable" data-on-label="Yes" data-off-label="No"></label>

                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label htmlFor="switch4">  Save To Draft </label>
                                            <div>
                                                <input type="checkbox" id="switch3" switch="none" checked={isDraft} onChange={(event) => event.target.checked == true ? 1 : 0} />
                                                <label id="agentStatus" for="switch3" class="data_lable" data-on-label="Yes" data-off-label="No"></label>

                                            </div>
                                        </div>


                                    </div>
                                </div> */}
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className="font-weight-bold"> Buying Zone [In Rs.] </label>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label htmlFor="buyingLowerRange">Lower Range:</label>
                                                    <div>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            name="lowerRange"
                                                            min={0}
                                                            value={buyingZone.lowerRange}
                                                            placeholder="Please enter price"
                                                            required
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div>
                                                        <b>Calculated Lower Price:</b> {buyingZone.lowerPrice}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label htmlFor="buyingUpperRange">Upper Range:</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="upperRange"
                                                        min={0}
                                                        value={buyingZone.upperRange}
                                                        placeholder="Please enter price"
                                                        required
                                                        readOnly
                                                    />
                                                    <div>
                                                        <b>Calculated Upper Price:</b> {buyingZone.upperPrice}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <label className="font-weight-bold"> Selling Zone [In Rs.] </label>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label htmlFor="sellingLowerRange">Lower Range:</label>
                                                    <div>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            name="lowerRange"
                                                            step={0.1}  
                                                            min={0}
                                                            value={sellingZone.lowerRange}
                                                            onChange={handleSellingZoneChange}
                                                            placeholder="Please enter price"
                                                            
                                                        />
                                                    </div>
                                                    <div>
                                                        <strong>Calculated Lower Price:</strong> {sellingZone.lowerPrice}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label htmlFor="sellingUpperRange">Upper Range:</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="upperRange"
                                                        min={0}
                                                        step={0.1}  
                                                        value={sellingZone.upperRange}
                                                        onChange={handleSellingZoneChange}
                                                        placeholder="Please enter price"
                                                        
                                                    />
                                                    <div>
                                                        <strong>Calculated Upper Price:</strong>  {sellingZone.upperPrice}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>

                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}
