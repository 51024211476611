/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getUserById, getUserReferralData, extendSubscription } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import $ from "jquery";
import { Button, Modal, Form } from 'react-bootstrap';


export default function ViewUser() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [profileImage, setProfileImage] = useState(null);
    const [expireDate, setExpireDate] = useState(null);

    const [customerDetails, setCustomerDetails] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [selectedDays, setSelectedDays] = useState('');
    const imageUrl = profileImage != null ? `${process.env.REACT_APP_S3URL}/admin_profile/${profileImage}` : 'https://www.islgroups.com/images/3.jpg';

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleSelectChange = (event) => {
        setSelectedDays(event.target.value);
    };
    const handleExtensionSubmit = (event) => {
        // Handle the form submission logic here.
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const days = formData.get('extensionDays');
        if (days != '') {
            extendSubscription({ "user_id": id, "days": days }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: res.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setTimeout(() => {
                        setExpireDate(res.data.expiry_date)
                    }, 2000)
                }
            });

        }
        handleCloseModal();
    };

    useEffect(() => {
        if (id != undefined) {
            getUserById({ "user_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setCustomerDetails(res.data)
                    setProfileImage(res.data.profile_image)
                    setExpireDate(res.data.expiry_date)
                }
            });

        }
    }, [id])
    const [userReferrals, setUserReferrals] = useState(null);

    useEffect(() => {

        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        getUserReferralData({ "user_id": id }).then((response) => {
            Swal.close()
            response = JSON.parse(response)
            if (response.code == 200) {
                setUserReferrals(response.data)
            } else {
                setUserReferrals(null)
            }
        })



    }, [])

    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 5,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'copy',
                    className: 'btn-mute'
                }, {
                    extend: 'csv',
                    className: 'btn-mute'
                }, {
                    extend: 'print',
                    className: 'btn-mute'
                }],
                "bDestroy": true,
            }
        );
    }, [userReferrals])
    // const image = `${process.env.REACT_APP_S3URL}${process.env.CHART_IMAGE}${signalDetails.chart_image}`
    if (customerDetails == null) return <> </>

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className="card m-l-15 m-r-15">
                        <div className="card-body">

                            <h5 className="mt-0 header-title">View User</h5>

                            <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#home2" role="tab">Personal Details</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#profile1" role="tab">User Profile Details</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#profile2" role="tab">Other Details</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#messages2" role="tab">Rewards</a>
                                </li>

                            </ul>

                            <div className="tab-content">
                                <div className="tab-pane active p-3" id="home2" role="tabpanel">
                                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                        <div className="form-group">
                                            <div className='row'>
                                                {/* <div className="mb-3">
                                                    <div className="admin-profile image-view">

                                                        <img src={imageUrl} id="output" width="200" />
                                                    </div>
                                                </div> */}
                                                <div className='col-md-3'>
                                                    <div className="member-img">
                                                        <img src='https://www.pngitem.com/pimgs/m/508-5087336_person-man-user-account-profile-employee-profile-template.png' className="rounded-circle" alt="profile-image" style={{ width: 200, height: 200 }} />
                                                    </div>
                                                </div>
                                                <div className='col-md-9'>

                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="form-label font-weight-bold">First Name</label><br />
                                                                <span className="font-15">{customerDetails.first_name}</span>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="form-label font-weight-bold">Last Name</label><br />
                                                                <span className="font-15">{customerDetails.last_name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="form-label font-weight-bold">Mobile Number</label><br />
                                                                <span className="font-15">{customerDetails.country_code + " " + customerDetails.mobile_number}</span>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="form-label font-weight-bold">Referral Code</label><br />
                                                                <span className="font-15">{customerDetails.referral_code}</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="form-label font-weight-bold">Username</label><br />
                                                                <span className="font-15">{customerDetails.username}</span>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="form-label font-weight-bold">Email</label><br />
                                                                <span className="font-15">{customerDetails.email == null ? '-' : customerDetails.email}</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="form-label font-weight-bold">Broker Connect Status</label><br />
                                                                <span className="font-15">{customerDetails.is_connect_broker == 0 ? "No" : "Yes"}</span>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="form-label font-weight-bold">Algo User / Non Algo User</label><br />
                                                                <span className="font-15">{customerDetails.is_algo_user == 0 ? "Non Algo User" : "Algo User"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="form-label font-weight-bold">KYC Status</label><br />
                                                                <span className="font-15">{customerDetails.kyc_status == 0 ? "Pending" : "Done"}</span>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="form-label font-weight-bold">Status</label><br />
                                                                <span className="font-15">{customerDetails.is_active == 0 ? "🔴 Inactive" : "🟢 Active"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="tab-pane p-3" id="profile1" role="tabpanel">
                                <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                        <div className="form-group">

                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Age</label><br />
                                                    <span className="font-15">{customerDetails.age ?? '-'}</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Gender</label><br />
                                                    <span className="font-15">{customerDetails.gender ?? '-'}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Experience</label><br />
                                                    <span className="font-15">{customerDetails.experience ?? '-'}</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Financial Goals</label><br />
                                                    <span className="font-15">{customerDetails.goals.length > 0 ? customerDetails.goals.join(' , '): '-'}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Profession</label><br />
                                                    <span className="font-15">{customerDetails.profession ?? '-'}</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">StockWiz Usage</label><br />
                                                    <span className="font-15">{customerDetails.sw_usage.length > 0 ? customerDetails.sw_usage.join(' , '): '-'}</span>
                                                    {/* <span className="font-15">{customerDetails.sw_usage ?? '-'}</span> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Risk Type</label><br />
                                                    <span className="font-15">{customerDetails.risk_type ?? '-'}</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Current Investments</label><br />
                                                    <span className="font-15">{customerDetails.current_investment.length > 0 ? customerDetails.current_investment.join(' , '): '-'}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">When the market goes up by 25%, what would you do?</label><br />
                                                    <span className="font-15">{customerDetails.market_goes_up ?? '-'}</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">When the market goes down by 25%, what would you do?</label><br />
                                                    <span className="font-15">{customerDetails.market_goes_down ?? '-'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane p-3" id="profile2" role="tabpanel">
                                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                                        <div className="form-group">

                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Available Coins</label><br />
                                                    <span className="font-15">{customerDetails.available_coins}</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Pending Coins</label><br />
                                                    <span className="font-15">{customerDetails.pending_coins}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Total Coins</label><br />
                                                    <span className="font-15">{customerDetails.total_coins}</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Subscription Type</label><br />
                                                    <span className="font-15">{customerDetails.subscription_type == 1 ? "Trial Period" : customerDetails.subscription_type == 2 ? "Free" : "Paid"}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Days Left</label><br />
                                                    <span className="font-15">{customerDetails.left_days}</span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className="form-label font-weight-bold">Expiry Date</label><br />
                                                    <span className="font-15">{expireDate}</span>
                                                </div>

                                            </div>


                                        </div>
                                        <div className='form-group'>
                                            <div className='row'>

                                                <Button variant="primary" onClick={handleShowModal}>
                                                    Extend Subscription
                                                </Button>
                                                <Modal show={showModal} onHide={handleCloseModal}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Extend Subscription Plan</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form onSubmit={handleExtensionSubmit}>
                                                            {/* Add form fields for subscription extension here */}
                                                            {/* <Form.Group controlId="extensionDays">
                                                            <Form.Label>Number of days to extend</Form.Label>
                                                            <Form.Control type="number" name="extensionDays" />
                                                        </Form.Group> */}

                                                            <Form.Control as="select" name="extensionDays" onChange={handleSelectChange} value={selectedDays}>
                                                                <option value="">Select...</option>
                                                                <option value="3">3 days</option>
                                                                <option value="7">7 days</option>
                                                                <option value="14">14 days</option>
                                                            </Form.Control>
                                                            <Button variant="primary" className="btn btn-primary mt-2" type="submit">
                                                                Extend
                                                            </Button>
                                                        </Form>
                                                    </Modal.Body>
                                                </Modal>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane p-3" id="messages2" role="tabpanel">
                                    {
                                        userReferrals == null ? <>

                                        </> : <>

                                            <div className='cal col-md-12'>
                                                <table id="mangeUser" className="table table-hover dataTable js-exportable">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: "center" }}>ID</th>
                                                            <th style={{ textAlign: "center" }}>Name</th>
                                                            <th style={{ textAlign: "center" }}>Coin</th>
                                                            <th style={{ textAlign: "center" }}>Ref Code</th>
                                                            <th style={{ textAlign: "center" }}>Type</th>
                                                            <th style={{ textAlign: "center" }}>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tfoot>
                                                        <tr>
                                                            <th style={{ textAlign: "center" }}>ID</th>
                                                            <th style={{ textAlign: "center" }}>Name</th>
                                                            <th style={{ textAlign: "center" }}>Coin</th>
                                                            <th style={{ textAlign: "center" }}>Ref Code</th>
                                                            <th style={{ textAlign: "center" }}>Type</th>
                                                            <th style={{ textAlign: "center" }}>Status</th>
                                                        </tr>
                                                    </tfoot>
                                                    <tbody>

                                                        {
                                                            userReferrals && userReferrals.map((item, index) => {
                                                                return (
                                                                    <tr id={"roleId" + item.id} >
                                                                        <td style={{ textAlign: "center" }}>
                                                                            {index + 1}
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            {item.first_name}
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            {item.coin}
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            {item.referral_code}
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            {item.type == 1 ? "Signup" : item.type == 2 ? "Membership" : "Commission"}
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            {item.status == 0 ? "Pending" : "Done"}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}