import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addStrategy, getTags } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Select from 'react-select';
import S3 from 'react-aws-s3';


const config = {
    bucketName: process.env.REACT_APP_S3_NAME,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    s3Url: process.env.REACT_APP_S3URL,
    dirName: "chart_image",
};
export default function AddStrategy() {

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter strategy").max(128, "Strategy must be at most 128 character"),
        title: Yup.string().required("Please enter title").max(128, "Ttile must be at most 128 character"),
        subtitle: Yup.string().required("Please enter subtitle").max(128, "Subtitle must be at most 128 character"),
        description: Yup.string().required("Please enter description").max(155, "Description must be at most 128 character"),
        stype: Yup.string().required('Strategy type is required').nullable(),
        category: Yup.string().required('Strategy category is required').nullable(),
        risk_type: Yup.object().required('Risk type is required'),
        tag: Yup.array().required('Tag is required').nullable(),
        trade_type: Yup.string().required('Trade direction is required').nullable(),
        gradient1 :  Yup.string().required('Gradient color is required').nullable(),
        gradient2 :  Yup.string().required('Gradient color is required').nullable(),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const [selectTag, setSelectTag] = useState(null);
    const [riskType, setRiskType] = useState(null);
    const [radioType, setRadioType] = useState(null);
    const [bgImage, setBgImage] = useState(null);
    const [icon, setIcon] = useState(null);
    const [tagList, setTagList] = useState(null);
    const [ctaegory, setCtaegory] = useState(null);
    const [schematic, setSchematic] = useState(null);
    const [tradeDirection, setTradeDirection] = useState(0);


    const navigate = useNavigate();
    useEffect(() => {
        getTags({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setTagList(response.data)
            }
        })
    }, [])
    function redirect() {
        navigate("/strategy")
    }
    const categories = [
        { label: 'Low', value: '1' },
        { label: 'Medium', value: '2' },
        { label: 'High', value: '3' },
    ]
    const handleSelectTagChange = (selectedOption) => {
        setSelectTag(selectedOption);
    }
    const handleRiskTypeChange = (selectedOption) => {

        setRiskType(selectedOption);
    }

    const handleRadioChange = (event) => {
        setRadioType(event.target.value);
    };
    const handleCategoryChange = (event) => {
        setCtaegory(event.target.value);
    };
    const handleFileInput = (e) => {
        const files = e.target.files[0];
        var newfile = new Date().getTime() + files.name
        config.dirName = 'bg_image'
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(files, newfile).then(data => {
            if (data.status === 204) {
                setBgImage(data.location.split("/")[4])
                // setImageErr('')

            } else {
                console.log('obj err')
            }
        });
    }

    const handleIconInput = (e) => {
        const files = e.target.files[0];
        var newfile = new Date().getTime() + files.name
        config.dirName = 'icon'
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(files, newfile).then(data => {
            if (data.status === 204) {
                setIcon(data.location.split("/")[4])
                // setImageErr('')

            } else {
                console.log('obj err')
            }
        });
    }


    const handleSchematicInput = (e) => {
        const files = e.target.files[0];
        var newfile = new Date().getTime() + files.name
        config.dirName = 'schematic'
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(files, newfile).then(data => {
            if (data.status === 204) {
                setSchematic(data.location.split("/")[4])
                // setImageErr('')

            } else {
                console.log('obj err')
            }
        });
    }

    const onSubmit = (data) => {

        addStrategy({ "name": data.name.trimStart().trimEnd(), "title": data.title.trimStart().trimEnd(), "subtitle": data.subtitle.trimStart().trimEnd(), "description": data.description.trimStart().trimEnd(), "creator": data.creator, "risk_type": riskType, "tag": selectTag, "type": parseInt(radioType), "bg_image": bgImage, "icon": icon, "category": ctaegory, "schematic": schematic, "trade_direction": tradeDirection , "gradient1" : data.gradient1.trimStart().trimEnd() , "gradient2" : data.gradient2.trimStart().trimEnd() }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/strategy")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Add Strategy</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Name </label>
                                            <div>
                                                <input type="text" {...register('name')} className="form-control" placeholder="Please enter name" />
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <label> Creator </label>
                                            <div>
                                                <input type="text" {...register('creator')} className="form-control" defaultValue="STOCKWIZ TECHNOLOGIES" readOnly />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Strategy Type </label>
                                            <div>
                                                <input type="radio" id="age1" name="stype" onClick={handleRadioChange} value="1"  {...register('stype')} />&nbsp;Free
                                                &nbsp;
                                                <input type="radio" id="age2" name="stype" value="2" onClick={handleRadioChange}  {...register('stype')} />&nbsp;Premium
                                                <div className="invalid-feedback">{errors.stype?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Strategy Category </label>
                                            <div>
                                                <input type="radio" id="age1" name="category" onClick={handleCategoryChange} value="1"  {...register('category')} />&nbsp;Investing
                                                &nbsp;
                                                <input type="radio" id="age2" name="category" value="2" onClick={handleCategoryChange}  {...register('category')} />&nbsp;Trading
                                                <div className="invalid-feedback">{errors.category?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>

                                        <div className='col-md-6'>
                                            <label> Risk Type </label>
                                            <div>

                                                <Controller
                                                    name="risk_type"
                                                    control={control}
                                                    rules={{ required: 'Please select risk type' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            options={categories}
                                                            value={field.value}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                handleRiskTypeChange(selectedOption);
                                                            }}
                                                            placeholder="Select Risk Type"
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                />
                                                <div className="invalid-feedback">{errors.risk_type?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Upload Schematic Image </label>

                                            <div className="input-group"><input type="file" className="custom-file-input" id="inputGroupFile01" onChange={handleSchematicInput} {...register('schematic_image')} aria-describedby="inputGroupFileAddon01" /><label className="custom-file-label" for="inputGroupFile01">Choose file</label></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Upload Background Image </label>

                                            <div className="input-group"><div class="custom-file"><input type="file" className="custom-file-input" id="inputGroupFile01" onChange={handleFileInput} aria-describedby="inputGroupFileAddon01" /><label className="custom-file-label" for="inputGroupFile01">Choose file</label></div></div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Upload Icon </label>

                                            <div className="input-group"><div class="custom-file"><input type="file" className="custom-file-input" id="inputGroupFile01" onChange={handleIconInput} aria-describedby="inputGroupFileAddon01" /><label className="custom-file-label" for="inputGroupFile01">Choose file</label></div></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Title </label>
                                            <div>
                                                <input type="text" {...register('title')} className="form-control" placeholder="Please enter title" />
                                                <div className="invalid-feedback">{errors.title?.message}</div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <label> Subtitle </label>
                                            <div>
                                                <input type="text" {...register('subtitle')} className="form-control" placeholder="Please enter subtitle" />
                                                <div className="invalid-feedback">{errors.subtitle?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Gradient Color 1 </label>
                                            <div>
                                                <input type="text" {...register('gradient1')} className="form-control" placeholder="Please enter gradient color 1" />
                                                <div className="invalid-feedback">{errors.gradient1?.message}</div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <label>  Gradient Color 2 </label>
                                            <div>
                                                <input type="text" {...register('gradient2')} className="form-control" placeholder="Please enter gradient color 2" />
                                                <div className="invalid-feedback">{errors.gradient2?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Description </label>
                                            <div>
                                                <textarea placeholder="Please enter description" className="form-control" rows={4} cols={40} {...register('description')} />
                                                <div className="invalid-feedback">{errors.description?.message}</div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <label> Tags </label>
                                            <div>
                                                <Controller
                                                    name="tag"
                                                    control={control}
                                                    rules={{ required: 'Please select strategy' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            options={tagList}
                                                            value={field.value}
                                                            isMulti={true}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                handleSelectTagChange(selectedOption);
                                                            }}
                                                            placeholder="Select Tags"
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                />
                                                <div className="invalid-feedback">{errors.tag?.message}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>

                                        <div className='col-md-6'>
                                            <label> Trade Direction </label>
                                            <div>
                                                <input type="radio" id="age1" name="trade_type" value="1" onClick={(e) => setTradeDirection(e.target.value)}  {...register('trade_type')} />&nbsp;LONG
                                                &nbsp;
                                                <input type="radio" id="age2" name="trade_type" value="2" onClick={(e) => setTradeDirection(e.target.value)} {...register('trade_type')} />&nbsp;SHORT
                                                <div className="invalid-feedback">{errors.trade_type?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Add
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
