import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { editManagePage,getManagePage , getSubAdminById} from '../../api/apiHandler';
import Swal from 'sweetalert2'
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import PropTypes from 'prop-types';

function CMSPage(props) {
    
    const [text, setText] = useState(null);
    const [className,setClassName] = useState(null);
    const [permission,setPermission] = useState(0);
    const modules = {
        toolbar: [
            [{ font: [] }, { size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image", "video"],
            // [{ script: "super" }, { script: "sub" }],
            [{ header: [!1, 1, 2, 3, 4, 5, 6] }, "code-block"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] },

            { "background": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }
            ],
        ]
    };

    const formats = [
        "header", "height", "bold", "italic", "font",
        "underline", "strike", "blockquote", "code-block", "clean", "super", "sub",
        "list", "color", "background", "bullet", "indent",
        "link", "image", "video", "align", "size",
    ];  
    const navigate = useNavigate();
    useEffect(() => {
        setText(null);
        getManagePage({"tag_name":props.page_name}).then((response)=>{
            getSubAdminById({ "sub_admin_id":  localStorage.getItem('Aid') }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setClassName(props.class_name)
                    setPermission(res.data.permissions.filter(module => module.class_name === props.class_name && module.is_edit === 1).length > 0);
                    response = JSON.parse(response)
                    setText(response.data[0].content);
                }
            });
        });      
    },[props.page_name]);
   
    function redirect(){
        navigate("/")
    }
    
    function updateData(){
        if(permission === 1){
            editManagePage({"tag_name":props.page_name,"content":text}).then((response)=>{
                response = JSON.parse(response)
                if(response.code == 200){
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setTimeout(()=>{
                        navigate("/")
                    },2000)
                }else{
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    if (text == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-10 col-md-10 col-sm-10'>
                    <h5>Manage {props.title} Content</h5><br/>
                        <ReactQuill modules={modules}  theme="snow" formats={formats} value={text} style={{height:300}} onChange={(value) => { setText(value)}} placeholder="Type here..."/>
                        <br/><br/><br/>
                        <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom" onClick={updateData}> Save </button>
                        <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>
                    </div>
                </div>
            </div>
        </div>            
    );
}

export default CMSPage;
 //proType For Validation
 CMSPage.propTypes = {
    page_name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}
