import React from 'react'
import { Link,useParams,useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { resetPassword } from '../../api/apiHandler';
import Swal from 'sweetalert2'

export default function ResetPassword() {

    const navigate = useNavigate();
    let { id } = useParams(); 
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('New password is required')
            .min(6, 'New password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .required('Confirm password is required')
            .oneOf([Yup.ref('password'), null], 'New password and confirm password does not match')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const onSubmit = (data) => {
        resetPassword({"forgotToken":id,"newPassword":data.password}).then((response)=>{
            Swal.close()
            response = JSON.parse(response)
            if(response.code == 1){
                Swal.fire({
                    position: 'top',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(()=>{
                    navigate("/")
                },2000)
                
            }else{
                Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
        
    }

  return (
    <div className="wrapper-page">
        
            <div className="card">
                <div className="card-body">

                    <h3 className="text-center">
                        <Link to="/" className="logo logo-admin"><img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} height="45" alt="logo" /></Link>
                    </h3>

                    <div className="">
                        <h4 className="text-muted font-18 mb-3 text-center">Reset Password</h4>

                        <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group">
                                <label htmlFor="useremail">New Password</label>
                                <input type="password" {...register('password')} className="form-control" id="useremail" placeholder="Enter new password" />
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="useremail">Confirm Password</label>
                                <input type="password" {...register('confirmPassword')} className="form-control" id="useremail" placeholder="Enter confirm password" />
                                <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                            </div>

                            <div className="form-group row m-t-20">
                                <div className="col-12 text-center">
                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Reset Password</button>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        </div>   
  )
}
