import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getAppVersionDetails, editAppVersion, getSubAdminById } from '../../api/apiHandler';
import { uploadImageS3 } from '../../common/Common';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// uploadImageS3

export default function EditWebsiteSettings() {

    const [edit, setEdit] = useState(null)

    const [appVerion, setAppVersion] = useState(null);



    useEffect(() => {
        if (localStorage.getItem("Arole") == "S") {
            setEdit(1)
            getAPIData();
        }
        else {
            getSubAdminById({ "sub_admin_id": localStorage.getItem('Aid') }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setEdit(res.data.permissions.filter(module => module.class_name === 'settings' && module.is_edit === 1).length > 0
                    );
                }
            });
        }

    }, [])


    function getAPIData() {
        getAppVersionDetails({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setAppVersion(response.data.attribute_value)
            }
        });
    }


    const validationSchema = Yup.object().shape({
        // name: Yup.string().required("Please enter tag").max(128, "Tag must be at most 128 character"),
        // url: Yup.string().required('URL is required').nullable(),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate("/settings")
    }

    const onSubmit = (data) => {
        if (edit == 1) {
            editAppVersion({ "app_version": appVerion }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setTimeout(() => {
                        navigate("/settings")
                    }, 2000)
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }

    }

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Settings Details</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >


                                <div className="form-group col-md-6">
                                    <label className='font-weight-bold'>App Version </label>
                                    <div>
                                        <input type="text" className="form-control" placeholder="Please enter app version" defaultValue={appVerion} onChange={(e) => setAppVersion(e.target.value)} required onInvalid={(e) => e.target.setCustomValidity('Please enter app version')}
                                            onInput={(e) => e.target.setCustomValidity('')} />

                                    </div>
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}