import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { module_not_create, module_not_change_status, module_not_delete, module_not_list, module_import, module_not_view , module_not_update } from '../../globals/constant';
import { editSubAdmin, getSubAdmin, getCountryList, getPermissions, getSubAdminById } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export default function EditSubAdmin() {

    let { id } = useParams();
    const [subAdminDetails, setSubAdminDetails] = useState(null)
    const [countryList, setCountryList] = useState(null)
    const [permissions, setPermissions] = useState(null)
    const [permissionData, setPermissionData] = useState([])
    const [sectionList, setSectionList] = useState([])
    useEffect(() => {



        if (id != undefined) {
            getSubAdminById({ "sub_admin_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setSubAdminDetails(res.data)
                    setSectionList(res.data.rights)
                }
            });
        }


    }, [id])
    const handleOnChange = (event, rightType, moduleId) => {
        // Create a deep copy of the moduleList to modify
        const updatedModuleList = sectionList.map((item) => {
            if (item.module_id == moduleId) {
                console.log(rightType, "------");
                item[rightType] = event.target.checked ? 1 : 0;
                return {
                    ...item,
                };
            }
            return item;
        });
        setSectionList(updatedModuleList);
    };
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter name").matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field').max(128, "Name must be at most 128 character"),
        email: Yup.string().email("Please enter valid email").required("Please enter email"),
        country_code: Yup.string().required("Please select country code"),
        phone: Yup.string().required("Please enter phone number").matches('^[0-9]', 'Only numbers are allowed for this field').min(8, "Phone must be at least 8 numbers").max(14, "Phone must be at most 14 numbers"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate("/subadmin")
    }



    const onSubmit = (data) => {

        if (sectionList.length > 0) {
            editSubAdmin({ "sub_admin_id": id, "name": data.name.trimStart().trimEnd(), "email": data.email, "country_code": data.country_code, "phone": data.phone, "permissions": sectionList }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setTimeout(() => {
                        navigate("/subadmin")
                    }, 2000)
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: "Please select permission",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }
    if (subAdminDetails == null) return <> </>
    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Edit Sub Admin Details</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >

                                <div className="form-group">
                                    <label className='ml-3'> Name</label>
                                    <div className='ml-3'>
                                        <input type="text" {...register('name')} className="form-control" defaultValue={subAdminDetails.name} placeholder="Please Enter Name" />
                                        <div className="invalid-feedback">{errors.name?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className='ml-3'> Email</label>
                                    <div className='ml-3'>
                                        <input type="text" {...register('email')} className="form-control" defaultValue={subAdminDetails.email} placeholder="Please Enter Email Address" />
                                        <div className="invalid-feedback">{errors.email?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className='ml-3'>  Country Code </label>
                                    <div className="ml-3">
                                        <input type="text" {...register('country_code')} className="form-control" defaultValue={subAdminDetails.country_code} readOnly />
                                        <div className="invalid-feedback">{errors.country_code?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className='ml-3'> Phone Number</label>
                                    <div className='ml-3'>
                                        <input type="text" {...register('phone')} className="form-control" defaultValue={subAdminDetails.phone} placeholder="Please enter phone number" />
                                        <div className="invalid-feedback">{errors.phone?.message}</div>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <label
                                        className="form-label"
                                        htmlFor="validationTooltip04"
                                    >
                                        <h4>Sub Admin Rights</h4>
                                    </label>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="form-label" htmlFor="validationTooltip04">
                                                    <strong>Module</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>Listing</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>Add</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>Update</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>Delete</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>Change Status</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>View</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>Import Sheet</strong>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {sectionList?.map((item, index) => {
                                        return (

                                            <div
                                                key={index}
                                                className="col-md-12"
                                            >
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="validationTooltip04"
                                                        >
                                                            <strong>{item.module}</strong>
                                                        </label>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                onChange={(event) =>
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_list",
                                                                        item.module_id
                                                                    )
                                                                }
                                                                defaultChecked={item.is_list}
                                                                disabled={module_not_list.includes(item.class_name)}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                onChange={(event) =>
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_create",
                                                                        item.module_id
                                                                    )
                                                                }
                                                                defaultChecked={item.is_create}
                                                                disabled={module_not_create.includes(item.class_name)}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                defaultChecked={item.is_edit}
                                                                onChange={(event) =>
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_edit",
                                                                        item.module_id
                                                                    )
                                                                }
                                                                disabled={module_not_update.includes(item.class_name)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                defaultChecked={item.is_delete}
                                                                onChange={(event) =>
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_delete",
                                                                        item.module_id
                                                                    )
                                                                }
                                                                disabled={module_not_delete.includes(item.class_name)}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                defaultChecked={item.is_change_status}
                                                                onChange={(event) =>
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_change_status",
                                                                        item.module_id
                                                                    )
                                                                }
                                                                disabled={module_not_change_status.includes(item.class_name)}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                onChange={(event) => {
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_view",
                                                                        item.module_id
                                                                    );
                                                                }}
                                                                defaultChecked={item.is_view}
                                                                disabled={module_not_view.includes(item.class_name)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                disabled={!module_import.includes(item.class_name)}
                                                                onChange={(event) =>
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_import_sheet",
                                                                        item.module_id
                                                                    )
                                                                }
                                                                defaultChecked={item.is_import_sheet}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}