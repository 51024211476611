import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { editUser, getcategoryById, getcategorys , editcategory, getCategoryById, editCategory } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Select from 'react-select';
import { moduleOptions } from '../../globals/constant';



export default function EditCategory() {

    let { id } = useParams();
    const [categoryDetails, setCategoryDetails] = useState(null)
    const [radioType1, setRadioType1] = useState(null);
    const [radioType2, setRadioType2] = useState(null);
    const [radioType, setRadioType] = useState(null);
    const [selectedModule, setSelectedModule] = useState(null)


    useEffect(() => {

        if (id != undefined) {
            getCategoryById({ "category_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setCategoryDetails(res.data)
                    setRadioType(res.data.type)
                    const modules = res.data.modules
                    const selectedStr = moduleOptions.filter((option) =>
                    modules.includes(parseInt(option.value, 10)));
                    setSelectedModule(selectedStr)
                    setRadioType1(res.data.type == '1')
                    setRadioType2(res.data.type == '2')
                }
            });
        }

    }, [id])
   
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter strategy").max(128, "Strategy must be at most 128 character"),
        // stype: Yup.string().required('Strategy type is required').nullable(),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate("/category")
    }

    const onSubmit = (data) => {

        editCategory({ "category_id": id, "name": data.name.trimStart().trimEnd(), "type" : radioType , "modules" : selectedModule}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/category")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }
    const handleRadioChange = (event) => {
        setRadioType(event.target.value);
        setRadioType1(event.target.value == '1');
        setRadioType2(event.target.value == '2')
    };


    if (categoryDetails == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                <div className='cal col-xs-4 col-md-4 col-sm-4'>
                        <h5>Edit Category Details</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="form-group">
                                    <label> Module </label>
                                    <div>
                                    <Select
                                        isMulti
                                        aria-labelledby="aria-label"
                                        placeholder="Modules.."
                                        value={selectedModule}
                                        onChange={(e) => setSelectedModule(e)}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        name="aria-live-color"
                                        options={moduleOptions}
                                        required
                                    />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label> Name </label>
                                    <div>
                                        <input type="text" {...register('name')} className="form-control" placeholder="Please enter category" defaultValue={categoryDetails.name} />
                                        <div className="invalid-feedback">{errors.name?.message}</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label> Type </label>
                                    <div>
                                        <input type="radio" id="age1" name="stype" value="1" onClick={handleRadioChange}
                                                    checked={radioType1} {...register('type')}/>&nbsp;Investing
                                        &nbsp;
                                        <input type="radio" id="age2" name="stype" value="2" onClick={handleRadioChange}
                                                    checked={radioType2} {...register('type')} />&nbsp;Trading
                                    </div>
                                </div>
                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                    Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}