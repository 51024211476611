import React, { useEffect, useState, useRef } from 'react'
import { getKycCompletedUsers, importSWMembersData } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import Select from 'react-select';
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2'
import { useNavigate, Link } from 'react-router-dom';
import 'react-image-lightbox/style.css'; // Import lightbox styles
import Lightbox from 'react-image-lightbox';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';

function KYCmemberList() {
    const navigate = useNavigate();

    const [userList, setUserList] = useState(null)
    const fileInputRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    function fetchList() {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        getKycCompletedUsers({}).then((response) => {
            Swal.close()

            response = JSON.parse(response)

            if (response.code == 200) {
                setUserList(response.data)
            } else {
                setUserList([])
            }
        })
    }
    useEffect(() => {
        fetchList()
    }, [])



    useEffect(() => {
        // Initialize Magnific Popup when the component mounts
        $('.image-popup').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            closeBtnInside: false,
            fixedContentPos: true,
            image: {
                verticalFit: true
            },
            zoom: {
                enabled: true,
                duration: 300 // Ensure the duration also matches in the CSS
            }
        });

        // Cleanup on unmount to remove event handlers and instances
        return () => {
            $.magnificPopup.close();
        };
    }, []);

    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 20,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'copy',
                    className: 'btn-mute'
                }, {
                    extend: 'csv',
                    className: 'btn-mute'
                }, {
                    extend: 'print',
                    className: 'btn-mute'
                }],
                "bDestroy": true,
            }
        );

    }, [userList])
    const iamge = 'https://stockwiz-dev.s3.ap-south-1.amazonaws.com/kyc_images/1666653b-3505-4402-bd47-9fbbff53e9de.jpg'

    if (userList == null) return <> </>
    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>

                            <table id="mangeUser" className="table table-hover dataTable js-exportable ">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>First Name</th>
                                        <th style={{ textAlign: "center" }}>Last Name</th>
                                        <th style={{ textAlign: "center" }}>Country Code</th>
                                        <th style={{ textAlign: "center" }}>Mobile Number</th>
                                        <th style={{ textAlign: "center" }}>Aadhar Card Number</th>
                                        <th style={{ textAlign: "center" }}>Aadhar Card Front Image</th>
                                        <th style={{ textAlign: "center" }}>Aadhar Card Back Image</th>
                                        <th style={{ textAlign: "center" }}>Pan Card Number</th>
                                        <th style={{ textAlign: "center" }}>Pan Card Image</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>First Name</th>
                                        <th style={{ textAlign: "center" }}>Last Name</th>
                                        <th style={{ textAlign: "center" }}>Country Code</th>
                                        <th style={{ textAlign: "center" }}>Mobile Number</th>
                                        <th style={{ textAlign: "center" }}>Aadhar Card Number</th>
                                        <th style={{ textAlign: "center" }}>Aadhar Card Front Image</th>
                                        <th style={{ textAlign: "center" }}>Aadhar Card Back Image</th>
                                        <th style={{ textAlign: "center" }}>Pan Card Number</th>
                                        <th style={{ textAlign: "center" }}>Pan Card Image</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    {
                                        userList && userList.map((item, index) => {
                                            return (
                                                <tr id={"userId" + item._id} >
                                                    <td style={{ textAlign: "center" }}>
                                                        {index + 1}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.kyc_first_name}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.kyc_last_name}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.country_code}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.mobile_number}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.aadhar_card_details.card_number}
                                                    </td>

                                                    <td style={{ textAlign: "center" }}>
                                                        <div>
                                                          
                                                            {item.aadhar_card_details.front_page_url && (
                                                                <>
                                                                    <a href={item.aadhar_card_details.front_page_url} target="_blank" rel="noopener noreferrer" className="image-popup">
                                                                        <button type="button" className='btn-mute' style={{ cursor: "pointer" }}>View Image</button>
                                                                    </a>
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <div>
                                                            
                                                            {item.aadhar_card_details.back_page_url && (
                                                                <>
                                                                    <a href={item.aadhar_card_details.back_page_url} target="_blank" rel="noopener noreferrer" className="image-popup">
                                                                        <button type="button" className='btn-mute' style={{ cursor: "pointer" }}>View Image</button>
                                                                    </a>
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.pan_card_details?.card_number}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <div>
                                                            {item.pan_card_details.pan_card_image_url && (
                                                                <>
                                                               
                                                                    <a href={item.pan_card_details.pan_card_image_url} target="_blank" rel="noopener noreferrer" className="image-popup">
                                                                        <button type="button" className='btn-mute' style={{ cursor: "pointer" }}>View Image</button>
                                                                    </a>
                                                                </>
                                                            )}
                                                        </div>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default KYCmemberList;