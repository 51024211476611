import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { labelOptions } from '../../globals/constant';
import { addTag, getSymbols, editTheme, getCategories, getThemeById } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Select from 'react-select';
import S3 from 'react-aws-s3';

const config = {
    bucketName: process.env.REACT_APP_S3_NAME,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    s3Url: process.env.REACT_APP_S3URL,
};

export default function EditTheme() {

    let { id } = useParams();
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Please enter title").max(128, "Title must be at most 128 character"),
        about: Yup.string().required("Please enter about").max(250, "About must be at most 250 character"),
        // label: Yup.array().required("Please select labels"),
        // title: Yup.string().required("Please enter tag").max(128, "Tag must be at most 128 character"),
        // stock_percentage: Yup.string().required("Please enter stock percentage").matches('^[0-9]', 'Only numbers are allowed for this field')
        // title: Yup.string().required("Please enter tag").max(128, "Tag must be at most 128 character"),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [themeDetails, setThemeDetails] = useState(null)
    const [symbolList, setSymbolList] = useState(null)
    const [themeIcon, setThemeIcon] = useState(null);
    const [stockAllocations, setStockAllocations] = useState([{ stock: [], percentage: '0', quantity: '0' }]);
    const [totalPercentage, setTotalPercentage] = useState(0);
    const [label, setLabel] = useState(null)
    function redirect() {
        navigate("/theme")
    }
    useEffect(() => {
        if (id) {
            getThemeById({ "theme_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    setThemeDetails(res.data)
                    setThemeIcon(res.data.basket_icon)

                    getSymbols({}).then((symbols) => {
                        symbols = JSON.parse(symbols)
                        if (symbols.code == 200) {
                            setSymbolList(symbols.data)

                            const result = res.data.stock_allocations.map(({ stock, percentage, quantity,_id }) => ({
                                stock: {
                                    label: symbols.data.find(item => item.value === stock)?.label || "",
                                    value: stock
                                },
                                percentage: percentage,
                                quantity : quantity,
                            }));
                            setStockAllocations(result)
                            setTotalPercentage(res.data.stock_allocations.reduce((total, allocation) => total + allocation.percentage, 0));
                            const selectedStr = labelOptions.filter((option) =>
                                res.data.label.includes(option.label)
                            );
                            setLabel(selectedStr)
                        }
                    })

                }
            })
        }

    }, [id])
    const onSubmit = (data) => {
        
        editTheme({ "theme_id": id, "name": data.title.trimStart().trimEnd(), "about": data.about.trimStart().trimEnd(), "stock_allocations": stockAllocations, "theme_icon": themeIcon, "stock_percentage": data.stock_percentage, "labels": label }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/theme")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }


    const handleAddStockAllocation = () => {
        if (totalPercentage + parseFloat(stockAllocations[stockAllocations.length - 1]?.percentage) > 100) {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You cannot add more stocks. Total percentage exceeds 100%',
            })
            return;
        }
        setStockAllocations([...stockAllocations, { stock: [], percentage: '0', quantity: '0' }]);
    };

    const handleRemoveStockAllocation = (index) => {
        const updatedAllocations = [...stockAllocations];
        updatedAllocations.splice(index, 1);
        setStockAllocations(updatedAllocations);
        setTotalPercentage(totalPercentage - parseFloat(updatedAllocations.percentage));

    };

    const handleStockChange = (index, selectedOptions) => {
        const updatedAllocations = [...stockAllocations];
        updatedAllocations[index].stock = selectedOptions;
        setStockAllocations(updatedAllocations);
    };

    const handlePercentageChange = (index, value) => {
        const updatedAllocations = [...stockAllocations];
        updatedAllocations[index].percentage = value;
        setStockAllocations(updatedAllocations);
        calculateTotalPercentage();
    };
    const handleQuantityChange = (index, value) => {
        const updatedAllocations = [...stockAllocations];
        updatedAllocations[index].quantity = value;
        setStockAllocations(updatedAllocations);
        calculateTotalPercentage();
    };
    const calculateTotalPercentage = () => {
        const newTotalPercentage = stockAllocations.reduce((total, allocation) => total + parseFloat(allocation.percentage), 0);
        setTotalPercentage(newTotalPercentage);
    };

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files).slice(0, 5);
        // console.log(files, "==========FILES========"); return;
        const promises = files.map(async (file) => {
            try {
                config.dirName = 'theme_images';
                const ReactS3Client = new S3(config);
                var newfile = new Date().getTime() + file.name

                const data = await ReactS3Client.uploadFile(file, newfile);
                return data.location.split("/")[4];
            } catch (error) {
                console.error('Error uploading file:', error);
                return null;
            }
        });

        Promise.all(promises)
            .then((results) => {
                console.log("RESULTS :: ", results);
                setUploadedFiles(results.filter((result) => result !== null));
            })
            .catch((error) => {
                console.error('Error uploading files:', error);
            });
    };
    const handleFileInput = (e) => {
        const path = 'icon';
        const files = e.target.files[0];
        const newfile = new Date().getTime() + files.name
        config.dirName = path;
        const ReactS3Client = new S3(config);

        ReactS3Client.uploadFile(files, newfile).then(data => {
            if (data.status === 204) {
                setThemeIcon(data.location.split("/")[4])
            } else {
                console.log('obj err')
            }
        });
    }
    if (themeDetails == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Edit Theme</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Title </label>
                                            <div>
                                                <input type="text" placeholder='Please enter name' className="form-control" defaultValue={themeDetails.name} {...register('title')} />
                                            </div>
                                            <div className="invalid-feedback">{errors.title?.message}</div>

                                        </div>
                                        <div className='col-md-6'>
                                            <label> About / Bio </label>
                                            <div>

                                                <textarea name="data" placeholder='Please enter bio' className="form-control" {...register('about')} cols="2" rows="2" defaultValue={themeDetails.about}></textarea>
                                                <div className="invalid-feedback">{errors.about?.message}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className='row'>

                                        <div className='col-md-6'>
                                            <label> Upload Icon </label>
                                            <div>
                                                <input type="file" className="form-control" id="inputGroupFile01" onChange={e => handleFileInput(e)} />

                                                <div className="invalid-feedback">{ }</div>


                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Key Highlights Images [Upload max 5 images] :  </label>
                                            <div>
                                                <input type="file" className="form-control" id="inputGroupFile01" multiple onChange={handleFileChange} />

                                                <div className="invalid-feedback">{ }</div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Labels </label>
                                            <div>
                                                <Controller
                                                    name="label"
                                                    control={control}
                                                    rules={{ required: 'Please select label' }}
                                                    render={({ field }) => (
                                                        <Select
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            options={labelOptions}
                                                            value={label}
                                                            isMulti={true}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                setLabel(selectedOption);
                                                            }}
                                                            placeholder="Select Labels"
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                />

                                                <div className="invalid-feedback">{errors.label?.message}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {stockAllocations.map((allocation, index) => (
                                    <div className='row mt-2' key={index}>
                                        <div className='col-md-6'>
                                            <label>
                                                Stock:
                                            </label>
                                            <Select required

                                                name="colors"
                                                options={symbolList}
                                                value={allocation.stock} onChange={(selectedOptions) => handleStockChange(index, selectedOptions)}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />


                                        </div>
                                        <div className='col-md-2'>

                                            <label>
                                                Percentage:</label>
                                            <input
                                                type="number"
                                                min={0}
                                                className='form-control'
                                                value={allocation.percentage}
                                                onChange={(e) => handlePercentageChange(index, e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-2'>

                                            <label>
                                                Quantity:</label>
                                            <input
                                                type="number"
                                                min={0}
                                                className='form-control'
                                                value={allocation.quantity}
                                                onChange={(e) => handleQuantityChange(index, e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-2'>
                                            <button className='mt-4 btn btn-danger' onClick={() => handleRemoveStockAllocation(index)}>Delete
                                            </button>
                                        </div>

                                    </div>
                                ))}


                                <div className='row ml-1 mt-2'>
                                    <div className='col-md-6'>

                                        <button type="button" className='btn btn-primary' onClick={handleAddStockAllocation}>
                                            Add Stock
                                        </button>
                                    </div>
                                    <div className="col-md-6">
                                        <p><b>Total Stock: </b>{stockAllocations.length} <br />
                                            <b>Total Percentage: </b> {totalPercentage}%</p>
                                    </div>
                                </div>

                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
