import React, { useEffect } from 'react'
import { logout } from '../../api/apiHandler';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logOutRedirectCall } from '../common/RedirectPathMange';
import Swal from 'sweetalert2';

function Logout() {   
    useEffect(()=>{
        logout({}).then((res) => {
            const response = JSON.parse(res);
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1000
                })
                
                setTimeout(()=>{
                    logOutRedirectCall();
                },1000)
                
            }
            else {
                toast.error(response.message, {
                    position: "top-center",
                    autoClose: 4000,
                    closeOnClick: true,
                    pauseOnHover: true
                });
            }
        })
    },[])
    
    return (
        <div>
        </div>
    )
}

export default Logout;