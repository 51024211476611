/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addSignal, getStrategies, getSignalById, editSignal, getSymbols, getCategories } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import { segmentOptions } from '../../globals/constant';
import Swal from 'sweetalert2'
import Select from 'react-select';
import S3 from 'react-aws-s3';

window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
    bucketName: process.env.REACT_APP_S3_NAME,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    s3Url: process.env.REACT_APP_S3URL,
    dirName: "chart_image",
};
const configPDF = {
    bucketName: process.env.REACT_APP_S3_NAME,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    s3Url: process.env.REACT_APP_S3URL,
    dirName: "report_pdf",
};
const ReactS3ClientPDF = new S3(configPDF);
const ReactS3Client = new S3(config);

export default function EditTradLog() {

    const validationSchema = Yup.object().shape({
        // name: Yup.string().required("Please enter strategy").max(128, "Strategy must be at most 128 character"),
        // trade_category: Yup.string().required("Please select trade category"),
        // symbol: Yup.string().required("Please select symbol"),
        // strategies: Yup.string().required("Please select strategies"),
        // buying_zone: Yup.string().required("Please enter buy percentage").matches('^[0-9]', 'Only numbers are allowed for this field'),
        // selling_zone: Yup.string().required("Please enter sell percentage").matches('^[0-9]', 'Only numbers are allowed for this field'),
        // chart_image: Yup.string().required("Please upload chart image"),
        // report_pdf: Yup.string().required("Please upload report pdf"),
        // stype: Yup.string().required('Signal type is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const [type, setType] = useState(false)
    const [entryprice, setEntryprice] = useState(false)
    const [signalDetails, setSignalDetails] = useState(false)
    const [category, setCategory] = useState(false);
    const [category2, setCategory2] = useState(false);
    const [risk, setRisk] = useState(false)
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [symbol, setSymbol] = useState(null); const [newIdeaOn, setNewIdeaOn] = useState(true);
    const [displayOnHomePage, setDisplayOnHomePage] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const [radioType1, setRadioType1] = useState(null);
    const [radioType2, setRadioType2] = useState(null);
    const [radioType, setRadioType] = useState(null)
    const [chartImage, seChartImage] = useState(null);
    const [selectCategory, setSelectCategory] = useState(null);
    const [reportPDF, setReportPDF] = useState(null);
    const [currentDate, setCurrentDate] = useState('');
    const [risk1, setRisk1] = useState(false);
    const [risk2, setRisk2] = useState(false);
    const [target1, setTarget1] = useState(false);
    const [target2, setTarget2] = useState(false);
    const [targetPrice, setTargetPrice] = useState(false)
    const [strategyList, setStrategyList] = useState(null)
    const [selectedtradeCatgory, setSelectedtradeCatgory] = useState(null)
    const [categoryOption, setCategoryOption] = useState(null)
    const [strategy, setStrategy] = useState(null);

    const [option1, setOption1] = useState(false);
    const [option2, setOption2] = useState(false);
    const [option3, setOption3] = useState(false);
    const [option, setOption] = useState(false);

    const [optionType1, setOptionType1] = useState(false);
    const [optionType2, setOptionType2] = useState(false);
    const [optionType3, setOptionType3] = useState(false);
    const [optionType, setOptionType] = useState(false);
    const navigate = useNavigate();
    let { id } = useParams();
    const [symbolList, setSymbolList] = useState(null)
    const [selectSymbol, setSelectSymbol] = useState(null);
    const [tpValue, setTpValue] = useState(null);
    const [epValue, setEpValue] = useState(null);
    const [buyPriceCriteria1, setBuyPriceCriteria1] = useState('');
    const [buyPriceCriteria2, setBuyPriceCriteria2] = useState('');

    const [sellPriceCriteria1, setSellPriceCriteria1] = useState('');
    const [sellPriceCriteria2, setSellPriceCriteria2] = useState('');
    const [categories, setCategpries] = useState(null);
    const [sellingZone, setSellingZone] = useState(null);
    const [buyingZone, setBuyingZone] = useState(null);
    const [TLRisk, setTLRisk] = useState(null);
    const [TLRisk1, setTLRisk1] = useState(false);
    const [TLRisk2, setTLRisk2] = useState(false);
    const [segment, setSegment] = useState(null);
    const [name, setName] = useState(null);
    const [tradeDirection, setTradeDirection] = useState(null);

    function redirect() {
        navigate("/tradelog")
    }
    const handlePercentageChange = (event) => {
        const newValue = event.target.value;
        setPercentage(newValue);
    };

    useEffect(() => {
        if (id != undefined) {
            getSignalById({ "signal_id": id }).then((res) => {
                res = JSON.parse(res)
                if (res.code == 200) {
                    getStrategies({}).then((response) => {
                        response = JSON.parse(response)
                        if (response.code == 200) {
                            getSymbols({}).then((symbols) => {
                                symbols = JSON.parse(symbols)
                                if (symbols.code == 200) {
                                    const cat = res.data.category == 'Investing' ? 1 : 2
                                    getCategories({ "type": cat, "module": 3 }).then((categories) => {
                                        categories = JSON.parse(categories)
                                        if (categories.code == 200) {

                                            setSignalDetails(res.data)
                                            seChartImage(res.data.chart_image);
                                            setReportPDF(res.data.report_pdf);
                                            setCategory(res.data.category);
                                            setType(res.data.category)
                                            setCurrentDate(res.data.publish_date);
                                            setRadioType(res.data.type)
                                            setRadioType1(res.data.type == '1')
                                            setRadioType2(res.data.type == '2')

                                            setCheckbox1(res.data.entry_price_type == 'CMP')
                                            setCheckbox2(res.data.entry_price_type == 'Custom')
                                            setEntryprice(res.data.entry_price_type)
                                            setRisk(res.data.risk_type);
                                            setRisk1(res.data.risk_type == 'Percentage')
                                            setRisk2(res.data.risk_type == 'Price')
                                            setTargetPrice(res.data.target_price_type)
                                            setTarget1(res.data.target_price_type == 'Percentage')
                                            setTarget2(res.data.target_price_type == 'Price')
                                            setIsDraft(res.data.is_draft == "1");
                                            setDisplayOnHomePage(res.data.is_display_on_home_page == "1")
                                            setNewIdeaOn(res.data.is_new_idea == "1")
                                            const strategyNames = res.data.strategies.split(',').map((strategy) => strategy.trim());
                                            setStrategyList(response.data)
                                            const selectedStr = response.data.filter((option) =>
                                                strategyNames.includes(option.label)
                                            );
                                            setStrategy(selectedStr)

                                            setCategoryOption(categories.data)

                                            const foundObject = categories.data.find((category) => category.value == res.data.trade_category_id);
                                            setSelectCategory(foundObject)
                                            setSymbolList(symbols.data)

                                            const foundObject1 = symbols.data.find((symbol) => symbol.value == res.data.symbol_id);
                                            setSelectSymbol(foundObject1)
                                            setEpValue(res.data.entry_price);
                                            setTpValue(res.data.target_price);
                                            setBuyingZone(res.data.buy_percentage);
                                            setSellingZone(res.data.sell_percentage);

                                            setTLRisk(res.data.tradelog_risk_type);
                                            setTLRisk1(res.data.tradelog_risk_type == 'Percentage')
                                            setTLRisk2(res.data.tradelog_risk_type == 'Price')
                                            const segmentObject = segmentOptions.find((segment) => segment.label == res.data.segment);
                                            setSegment(segmentObject)
                                            setName(res.data.name);
                                            setTradeDirection(res.data.trade_direction)
                                        }
                                    });

                                }
                            });
                        }
                    });
                }
            });
        }

    }, [id])

    const image = chartImage != null ? `${process.env.REACT_APP_S3URL}/chart_image/${chartImage}` : '';
    const pdf = reportPDF != null ? `${process.env.REACT_APP_S3URL}/report_pdf/${reportPDF}` : '';
    // For Entry Price [ CMP and Custom Price ]

    const handleEntryPrice1Change = (event) => {
        const value = event.target.value;
        setEntryprice(value)
        setCheckbox1(true);
        setCheckbox2(false);
    };

    const handleEntryprice2Change = (event) => {
        const value = event.target.value;
        setEntryprice(value)
        setCheckbox1(false);
        setCheckbox2(true);
    };

    // For Option checkbox [Add More , BFP , BPP]
    const handleSignalOption1Change = (event) => {
        const value = event.target.value;
        setOption(value)
        setOption1(true);
        setOption2(false);
        setOption3(false);

    };

    const handleSignalOption2Change = (event) => {
        const value = event.target.value;
        setOption(value)
        setOption1(false);
        setOption2(true);
        setOption3(false);
    };


    const handleSignalOption3Change = (event) => {
        const value = event.target.value;
        setOption(value)
        setOption1(false);
        setOption2(false);
        setOption3(true);
    };


    // For Option checkbox [Update Label , Send Notification , Both]
    const handleSignalOptionType1Change = (event) => {
        const value = event.target.value;
        setOptionType(value)
        setOptionType1(true);
        setOptionType2(false);
        setOptionType3(false);

    };

    const handleSignalOptionType2Change = (event) => {
        const value = event.target.value;
        setOptionType(value)
        setOptionType1(false);
        setOptionType2(true);
        setOptionType3(false);
    };


    const handleSignalOptionType3Change = (event) => {
        const value = event.target.value;
        setOptionType(value)
        setOptionType1(false);
        setOptionType2(false);
        setOptionType3(true);
    };




    // For Stoploss / Risk [Percentage and Static Price]

    const handleRisk1Change = (event) => {
        const value = event.target.value;
        setRisk(value)
        setRisk1(true);
        setRisk2(false);
    };

    const handleRisk2Change = (event) => {
        const value = event.target.value;
        setRisk(value)
        setRisk1(false);
        setRisk2(true);
    };
    const handleTLRisk1Change = (event) => {
        const value = event.target.value;
        setTLRisk(value)
        setTLRisk1(true);
        setTLRisk2(false);
    };
    const handleTLRisk2Change = (event) => {
        const value = event.target.value;
        setTLRisk(value)
        setTLRisk1(false);
        setTLRisk2(true);
    };



    // For Target Price [ Percentage and Static Price ]

    const handleTargetPrice1Change = (event) => {
        const value = event.target.value;
        setTargetPrice(value)
        setTarget1(true);
        setTarget2(false);
    };

    const handleTargetPrice2Change = (event) => {
        const value = event.target.value;
        setTargetPrice(value)
        setTarget1(false);
        setTarget2(true);
    };







    // Handle Symbol Change 
    const handleSymbolChange = (selectedOption) => {
        setSymbol(selectedOption);
    }
    // For Strategies [Multi Select]
    const handleStrategyChange = (selectedOption) => {
        setStrategy(selectedOption);
    }

    // Trade Category Change 
    const handleSelectCategoryChange = (selectedOption) => {
        setSelectCategory(selectedOption);
    }

    //s3 file upload - Chart image
    const handleFileInput = (e) => {
        //setProfileUrl()
        const files = e.target.files[0];
        var newfile = new Date().getTime() + files.name

        ReactS3Client.uploadFile(files, newfile).then(data => {
            if (data.status === 204) {
                seChartImage(data.location.split("/")[4])
            } else {
                console.log('obj err')
            }
        });
    }

    const handleSellingZoneChange = (e) => {
        const enteredValue = e.target.value;
        console.log(epValue);
        const tp = parseFloat(epValue) + parseFloat(epValue * (tpValue / 100));
        const price1 = targetPrice == 'Price' ? tpValue : tp;
        const sp = targetPrice == 'Price' ? tpValue : tp;
        const price2 = sp - (sp * ((enteredValue) / 100));
        setSellingZone(enteredValue);
        setSellPriceCriteria1(price1);
        setSellPriceCriteria2(price2);

    };


    const handleBuyingZoneChange = (e) => {
        const enteredValue = e.target.value;
        const price2 = epValue - (epValue * ((enteredValue) / 100));
        setBuyingZone(enteredValue);
        setBuyPriceCriteria1(epValue);
        setBuyPriceCriteria2(price2);

    };

    //s3 file upload - Report PDF
    const handlePDFInput = (e) => {

        const files = e.target.files[0];
        var newfile = new Date().getTime() + files.name
        ReactS3ClientPDF.uploadFile(files, newfile).then(data => {
            if (data.status === 204) {
                setReportPDF(data.location.split("/")[4])
            } else {
                console.log('obj err')
            }
        });
    }
    // "chart_image": chartImage, "report_pdf": reportPDF,
    const onSubmit = (data) => {
        editSignal({ "signal_id": id, "is_trade_log": 1, "risk_type": risk, "risk": data.rvalue, "target_price_type": targetPrice, "target_price": data.tpvalue, "strategies": strategy, "segment": segment, "is_display_on_homepage": displayOnHomePage, "buy_percentage": buyingZone, "sell_percentage": sellingZone, "tradelog_risk_type": TLRisk, "tradelog_risk": data.tlrvalue, "name": name ,  "trade_direction" : tradeDirection}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/tradelog")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    // if ( == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Edit Tradelog</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >

                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Tradelog category </label>
                                            <div>
                                                <input type="text" name='category' value={category} className='form-control' readOnly />
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <label> Tradelog Type </label>
                                            <div>
                                                <input type="radio" id="age1" name="stype" value="1" checked={radioType1} readOnly />Free
                                                &nbsp;
                                                <input type="radio" id="age2" name="stype" value="2" checked={radioType2} readOnly />Premium



                                                <div className="invalid-feedback">{errors.stype?.message}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>

                                            <label htmlFor="mySelect2">Symbols </label>
                                            <div className='custom-select-container'>

                                                <Select
                                                    value={selectSymbol}
                                                    aria-labelledby="aria-label"
                                                    onChange={handleSymbolChange}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    name="aria-live-color"
                                                    options={symbolList}
                                                    isDisabled={true}
                                                />
                                                <div className="invalid-feedback">{errors.symbol?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Trade Category </label>
                                            <div>
                                                <Select value={selectCategory
                                                } onChange={handleSelectCategoryChange} options={categoryOption} menuPortalTarget={document.body} isDisabled={true}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </div>
                                            <div className="invalid-feedback">{errors.trade_category?.message}</div>
                                        </div>

                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Entry Price </label>
                                            <div>


                                                <input type="checkbox" value="CMP" checked={checkbox1} onclick="return false" name="c1" />&nbsp;CMP &nbsp;&nbsp;
                                                <input type="checkbox" value="Custom" checked={checkbox2} onclick="return false" name="c2" />&nbsp;Custom Price

                                                {

                                                    entryprice == 'Custom' ? <>
                                                        <input className="form-control form-control" name="t1" type="text" placeholder="Please enter Price" {...register('epvalue')} defaultValue={signalDetails.entry_price} readOnly />
                                                        <div className="invalid-feedback" ></div>
                                                    </> : entryprice == 'CMP' ? (
                                                        <>
                                                            <input className="form-control form-control" name="t2" type="text" {...register('epvalue')} defaultValue={signalDetails.entry_price} readOnly />
                                                            <div className="invalid-feedback"></div>
                                                        </>
                                                    ) :
                                                        <></>
                                                }
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Tradelog Name </label>
                                            <div>
                                                <input type="text" defaultValue={name} className="form-control" onChange={(e) => setName(e.target.value)} />
                                                {/* <div className="invalid-feedback">{errors.segment?.message}</div> */}
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label> Target Price </label>
                                            <div>

                                                <input type="checkbox" value="Percentage" checked={target1} onChange={handleTargetPrice1Change} name="c1" />&nbsp;Percentage &nbsp;&nbsp;
                                                <input type="checkbox" value="Price" checked={target2} onChange={handleTargetPrice2Change} name="c2" />&nbsp;Static Price
                                                <label id="agentStatus" for="target" class="data_lable" data-on-label="LTP" data-off-label="CMP"></label>
                                                {

                                                    targetPrice == 'Percentage' ? <>
                                                        <div>
                                                            <input className="form-control form-control" id="siret" type="number" onChange={handlePercentageChange} placeholder="Please enter Percentage" defaultValue={signalDetails.target_price} {...register('tpvalue')} />
                                                        </div>

                                                    </> : targetPrice == 'Price' ? (
                                                        <>

                                                            <input className="form-control form-control" id="siret" type="text" placeholder="Please enter Price" {...register('tpvalue')} defaultValue={signalDetails.target_price} />
                                                            <div className="invalid-feedback"></div>
                                                        </>
                                                    ) :
                                                        <></>
                                                }
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label> Strategies </label>
                                            <div>

                                                <Select
                                                    value={strategy}
                                                    isMulti
                                                    name="colors"
                                                    options={strategyList}
                                                    onChange={handleStrategyChange}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                                <div className="invalid-feedback">{errors.strategies?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6' >
                                            <label> Stop Loss or Risk </label>
                                            <div>

                                                <input type="checkbox" value="Percentage" checked={risk1} onChange={handleRisk1Change} name="c1" />&nbsp;Percentage &nbsp;&nbsp;
                                                <input type="checkbox" value="Price" checked={risk2} onChange={handleRisk2Change} name="c2" />&nbsp;Static Price
                                                <label id="agentStatus" for="risk" class="data_lable" data-on-label="LTP" data-off-label="CMP"></label>
                                                {

                                                    risk == 'Percentage' ? <>
                                                        <input className="form-control" type="number" placeholder="Please enter Percentage" onChange={handlePercentageChange} {...register('rvalue')} defaultValue={signalDetails.risk} />
                                                    </> : risk == 'Price' ? (
                                                        <>
                                                            <input className="form-control" id="siret" type="text" placeholder="Please enter Price" {...register('rvalue')} defaultValue={signalDetails.risk} />
                                                            <div className="invalid-feedback"></div>
                                                        </>
                                                    ) :
                                                        <></>
                                                }
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </div>

                                        </div>

                                        <div className='col-md-6' >
                                            <label> Tradelog Risk </label>
                                            <div>

                                                <input type="checkbox" value="Percentage" checked={TLRisk1} onChange={handleTLRisk1Change} name="c1" />&nbsp;Percentage &nbsp;&nbsp;
                                                <input type="checkbox" value="Price" checked={TLRisk2} onChange={handleTLRisk2Change} name="c2" />&nbsp;Static Price
                                                <label id="agentStatus" for="risk" class="data_lable" data-on-label="LTP" data-off-label="CMP"></label>
                                                {

                                                    risk == 'Percentage' ? <>
                                                        <input className="form-control" type="number" placeholder="Please enter Percentage" onChange={handlePercentageChange} {...register('tlrvalue')} defaultValue={signalDetails.tradelog_risk} />
                                                    </> : risk == 'Price' ? (
                                                        <>
                                                            <input className="form-control" id="siret" type="text" placeholder="Please enter Price" {...register('tlrvalue')} defaultValue={signalDetails.tradelog_risk} />
                                                            <div className="invalid-feedback"></div>
                                                        </>
                                                    ) :
                                                        <></>
                                                }
                                                {/* <div className="invalid-feedback">{errors.name?.message}</div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>



                                <div className="form-group">
                                    <div className='row'>

                                        <div className='col-md-6'>
                                            <label> Buying Zone (In %) </label>
                                            <div>
                                                <input type="text" className="form-control" onChange={handleBuyingZoneChange} defaultValue={buyingZone} />
                                                <div className="invalid-feedback">{errors.buying_zone?.message}</div>
                                            </div>
                                            {buyPriceCriteria2
                                                !== '' && buyPriceCriteria1 !== '' && (
                                                    <div>
                                                        Price Range: {buyPriceCriteria1} - {buyPriceCriteria2
                                                        }
                                                    </div>
                                                )}
                                        </div>

                                        <div className='col-md-6'>
                                            <label> Selling Zone (In %) </label>
                                            <div>
                                                <input type="text" className="form-control" onChange={handleSellingZoneChange} defaultValue={sellingZone} />
                                                <div className="invalid-feedback">{errors.selling_zone?.message}</div>
                                            </div>
                                            {sellPriceCriteria2 !== '' && sellPriceCriteria1 !== '' && (
                                                <div>
                                                    Price Range: {sellPriceCriteria1} - {sellPriceCriteria2}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group">
                                    <div className='row'>




                                        <div className='col-md-6'>
                                            <label> Segment </label>
                                            <div>
                                                <Select required
                                                    name="colors"
                                                    options={segmentOptions}
                                                    value={segment}
                                                    onChange={(selectedOption) => setSegment(selectedOption)}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label>Trade Direction</label>
                                            <div>
                                                <input
                                                    type="radio"
                                                    id="age1"
                                                    name="trade_type"
                                                    value="1"
                                                    onClick={(e) => setTradeDirection(e.target.value)}
                                                    checked={tradeDirection == '1'}
                                                />
                                                &nbsp;LONG
                                                &nbsp;
                                                <input
                                                    type="radio"
                                                    id="age2"
                                                    name="trade_type"
                                                    value="2"
                                                    onClick={(e) => setTradeDirection(e.target.value)}
                                                    checked={tradeDirection == '2'}
                                                />
                                                &nbsp;SHORT
                                                <div className="invalid-feedback">{errors.stype?.message}</div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="form-group">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Update
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>

                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}
