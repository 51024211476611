import React, { useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import { getSubAdminById } from '../../api/apiHandler';
import '../sidebar/Sidebar.css';

function Sidebar() {
    const [permissions, setPermissions] = useState(null);
    const [isOpen, setIsOpen] = useState(false)

    const location = useLocation();
    console.log("LOCATION :: ", location);
    const id = localStorage.getItem('Aid');
    console.log("PATH : ", location.pathname.split('/')[1], ":: ID ::", id);
    useEffect(() => {
        getSubAdminById({ "sub_admin_id": id }).then((res) => {
            res = JSON.parse(res)
            if (res.code == 200) {
                setPermissions(res.data.permissions)
            }
        });
    }, [])

    function Test() {
        if (isOpen) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }
    const isActive = (path) => location.pathname === path;
    const isSubmenuActive = (subPaths) => {
        return subPaths.includes(location.pathname.split('/')[1]);
    }
    console.log("ISOPEN : ", isOpen);
    return (

        <div className="left side-menu" style={{ overflowY: "scroll", maxHeight: "1000px" }}>

            <div className="slimscroll-menu" id="remove-scroll" >
                <div id="sidebar-menu">
                    <ul className="metismenu" id="side-menu">

                        <li>
                            <Link to='/' className={`${location.pathname.split('/')[1] === '' ? "waves-effect active" : ""}`}>
                                <i className='mdi mdi-home'></i> <span> Dashboard </span>
                            </Link>
                        </li>

                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'Sub Admin' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/subadmin' className={`${location.pathname.split('/')[1] === 'subadmin' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-account'></i> <span> Sub Admin </span>
                                </Link>
                            </li>
                            : null}

                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'user' && moduleObj?.is_list === 1)) ?
                            <li>

                                <Link to='/user' className={`${location.pathname.split('/')[1] === 'user' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-account'></i> <span> User </span>
                                </Link>
                            </li>
                            : null}
                        {localStorage.getItem('Arole') === 'S' ?
                            <li>

                                <Link to='/stockwiz_members' className={`${location.pathname.split('/')[1] === 'stockwiz_members' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-account'></i> <span> Stockwiz Members </span>
                                </Link>
                            </li>
                            : null}
                            {localStorage.getItem('Arole') === 'S' ?
                            <li>
                                <Link to='/kyc_members' className={`${location.pathname.split('/')[1] === 'kyc_members' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-account'></i> <span> KYC Members </span>
                                </Link>
                            </li>
                            : null}
                        <li>
                            {((localStorage.getItem('Arole') === 'S' || permissions?.some(moduleObj => moduleObj.class_name === 'about' && moduleObj.is_list === 1)) ||
                                (localStorage.getItem('Arole') === 'S' || permissions?.some(moduleObj => moduleObj.class_name === 'privacy_policy' && moduleObj.is_list === 1)) ||
                                (localStorage.getItem('Arole') === 'S' || permissions?.some(moduleObj => moduleObj.class_name === 'terms_conidtions' && moduleObj.is_list === 1)) ||
                                (localStorage.getItem('Arole') === 'S' || permissions?.some(moduleObj => moduleObj.class_name === 'faq' && moduleObj.is_list === 1)) ||
                                (localStorage.getItem('Arole') === 'S' || permissions?.some(moduleObj => moduleObj.class_name === 'contact_us' && moduleObj.is_list === 1))) ? (
                                <Link to='#' onClick={Test} className={isSubmenuActive(['about-us', 'privacy-policy', 'terms-conditions', 'faq', 'contact']) && !location.pathname.includes('strategy') ? 'waves-effect active' : ''}>
                                    <i className='mdi mdi-buffer'></i> <span> Page Contents <span className="float-right menu-arrow">{isOpen ? <i className="mdi mdi-chevron-down"></i> : <i className="mdi mdi-chevron-right"></i>}</span> </span>
                                </Link>
                            ) : null}
                            {isOpen ? (
                                <ul className="submenu">
                                    {localStorage.getItem('Arole') === 'S' || permissions?.some(moduleObj => moduleObj.class_name === 'about' && moduleObj.is_list === 1) ? (
                                        <li><Link to="/about-us" className={`${location.pathname.split('/')[1] === 'about-us' ? "waves-effect submenu-active" : ""}`}>About Us</Link></li>
                                    ) : null}

                                    {localStorage.getItem('Arole') === 'S' || permissions?.some(moduleObj => moduleObj.class_name === 'privacy_policy' && moduleObj.is_list === 1) ? (
                                        <li><Link to="/privacy-policy" className={`${location.pathname.split('/')[1] === 'privacy-policy' ? "waves-effect submenu-active" : ""}`}>Privacy Policy</Link></li>
                                    ) : null}

                                    {localStorage.getItem('Arole') === 'S' || permissions?.some(moduleObj => moduleObj.class_name === 'terms_conidtions' && moduleObj.is_list === 1) ? (
                                        <li><Link to="/terms-conditions" className={`${location.pathname.split('/')[1] === 'terms-conditions' ? "waves-effect submenu-active" : ""}`}>Terms & Conditions</Link></li>
                                    ) : null}

                                    {localStorage.getItem('Arole') === 'S' || permissions?.some(moduleObj => moduleObj.class_name === 'faq' && moduleObj.is_list === 1) ? (
                                        <li><Link to="/faq" className={`${location.pathname.split('/')[1] === 'faq' ? "waves-effect submenu-active" : ""}`}>FAQ</Link></li>
                                    ) : null}

                                    {localStorage.getItem('Arole') === 'S' || permissions?.some(moduleObj => moduleObj.class_name === 'contact_us' && moduleObj.is_list === 1) ? (
                                        <li><Link to="/contact" className={`${location.pathname.split('/')[1] === 'contact' ? "waves-effect submenu-active" : ""}`}>Contact Us</Link></li>
                                    ) : null}
                                </ul>
                            ) : <></>}
                        </li>


                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'strategy' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/strategy' className={`${location.pathname.split('/')[1] === 'strategy' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-rotate-right-variant'></i> <span> Strategy </span>
                                </Link>
                            </li>
                            : null}

                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'signal' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/signal' className={`${location.pathname.split('/')[1] === 'signal' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-signal'></i> <span> Signal </span>
                                </Link>
                            </li>
                            : null}

                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'symbol' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/symbol' className={`${location.pathname.split('/')[1] === 'symbol' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-shape-plus'></i> <span> Symbol </span>
                                </Link>
                            </li>
                            : null}

                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'category' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/category' className={`${location.pathname.split('/')[1] === 'category' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-layers'></i> <span> Category </span>
                                </Link>
                            </li>
                            : null}

                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'tag' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/tag' className={`${location.pathname.split('/')[1] === 'tag' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-tag-multiple'></i> <span> Tag </span>
                                </Link>
                            </li>
                            : null}
                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'tradelog' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/tradelog' className={`${location.pathname.split('/')[1] === 'tradelog' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-file-chart'></i> <span> Trade Log </span>
                                </Link>
                            </li>
                            : null}
                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'past_perfomance' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/past_perfomance' className={`${location.pathname.split('/')[1] === 'past_perfomance' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-speedometer'></i> <span> Past Perfomance </span>
                                </Link>
                            </li>
                            : null}

                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'baskets' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/basket' className={`${location.pathname.split('/')[1] === 'basket' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-basket'></i> <span> Baskets </span>
                                </Link>
                            </li>
                            : null}
                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'stock_database' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/stock_database' className={`${location.pathname.split('/')[1] === 'stock_database' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-database'></i> <span> Stock Database </span>
                                </Link>
                            </li>
                            : null}
                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'watchlist' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/watchlist' className={`${location.pathname.split('/')[1] === 'watchlist' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-eye'></i> <span> Watch List </span>
                                </Link>
                            </li>
                            : null}
                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'settings' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/settings' className={`${location.pathname.split('/')[1] === 'settings' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-settings'></i> <span> Settings </span>
                                </Link>
                            </li>
                            : null}
                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'webinar' && moduleObj?.is_view === 1)) ?
                            <li>
                                <Link to='/webinar' className={`${location.pathname.split('/')[1] === 'webinar' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-power-settings'></i> <span> Webinar Settings </span>
                                </Link>
                            </li>
                            : null}
                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'website' && moduleObj?.is_view === 1)) ?
                            <li>
                                <Link to='/website' className={`${location.pathname.split('/')[1] === 'website' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-power-settings'></i> <span> Website Settings </span>
                                </Link>
                            </li>
                            : null}
                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'theme' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/theme' className={`${location.pathname.split('/')[1] === 'theme' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-label'></i> <span> Themes </span>
                                </Link>
                            </li>
                            : null}
                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'market_watch' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/market_watch' className={`${location.pathname.split('/')[1] === 'market_watch' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-format-list-bulleted'></i> <span> Market Watch </span>
                                </Link>
                            </li>
                            : null}
                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'market_watch_category' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/market_category' className={`${location.pathname.split('/')[1] === 'market_category' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-format-list-bulleted'></i> <span> Market Watch Category</span>
                                </Link>
                            </li>
                            : null}

                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'fno' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/fno' className={`${location.pathname.split('/')[1] === 'fno' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-format-list-bulleted'></i> <span> Futures & Options </span>
                                </Link>
                            </li>
                            : null}

                        {localStorage.getItem('Arole') === 'S' || (permissions?.some(moduleObj => moduleObj?.class_name === 'notifications' && moduleObj?.is_list === 1)) ?
                            <li>
                                <Link to='/notification' className={`${location.pathname.split('/')[1] === 'notification' ? "waves-effect active" : ""}`}>
                                    <i className='mdi mdi-bell'></i> <span> Notifications </span>
                                </Link>
                            </li>
                            : null}

                    </ul>
                </div>
                <div className="clearfix"></div>
            </div>

        </div>
    );
}

export default Sidebar;