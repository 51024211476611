import React, { useEffect, useState } from 'react'
import { getSignalList, changesignalStatus, getSubAdminById, removeSignal, getAdminNames } from '../../api/apiHandler';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2'
import { typeOptions, signalDisplayOptions } from '../../globals/constant';
import { renderToStaticMarkup } from 'react-dom/server'
import { useNavigate } from 'react-router-dom';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from 'moment';
import Select from 'react-select';

function PastPerfomanceList() {
    const navigate = useNavigate();

    const [signalList, setSignalList] = useState(null)
    const [signalRemove, setSignalRemove] = useState(0)
    const [signalChangeStatus, setSignalChangeStatus] = useState(0)
    const [signalEdit, setSignalEdit] = useState(0)
    const [signalAdd, setSignalAdd] = useState(0)
    const [signalView, setSignalView] = useState(0)
    const [divState, setDivState] = useState(null);
    const [signalType, setSignalType] = useState(null);
    const [adminNames, setAdminNames] = useState(null);
    const [adminName, setAdminName] = useState(null);
    const [showSignal, setShowSignal] = useState(0);


    const [dates, setDatesState] = useState({
        startDate: "",
        endDate: ""
    });

    const setDates = (e, { startDate, endDate }) => {
        setDatesState({
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD")
        });
    };
    function getAdminData() {
        getAdminNames({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setAdminNames(response.data)
            }
        })
    }
    function clearFilter() {

        // Alert.processing()
        setTimeout(() => {
            Swal.close()
            setAdminName()
            setSignalType()
            setDatesState({
                startDate: "",
                endDate: ""
            });
        }, 0)

    }
    useEffect(() => {
        setSignalList(null)
        getSignalList({ "start_date": dates.startDate, "end_date": dates.endDate, "signal_status": 2, "type": signalType != null ? signalType.value : signalType, "admin_id": adminName != null ? adminName.value : adminName, "is_historical_signal": showSignal.value }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                // setUserList([])
                setSignalList(response.data)
                getAdminData()
            } else {
                setSignalList([])
            }
        })
    }, [dates, signalType, adminName, showSignal])
    useEffect(() => {
        if (localStorage.getItem("Arole") == "S") {
            setSignalRemove(1)
            setSignalChangeStatus(1)
            setSignalEdit(1)
            setSignalAdd(1)
            setSignalView(1)
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            getSignalList({ "signal_status": 2 }).then((response) => {
                Swal.close()
                response = JSON.parse(response)
                if (response.code == 200) {
                    setSignalList(response.data)
                    setAdminName({value : localStorage.getItem('Aid'), label : localStorage.getItem('Aname')})

                    getAdminData()
                } else {
                    setSignalList([])
                }
            })
        } else {
            getSignalList({ "signal_status": 2 }).then((response) => {
                Swal.close()

                response = JSON.parse(response)
                if (response.code == 200) {
                    setSignalList(response.data)
                    setAdminName({value : localStorage.getItem('Aid'), label : localStorage.getItem('Aname')})

                    getAdminData()
                    getSubAdminById({ "sub_admin_id": localStorage.getItem('Aid') }).then((res) => {
                        res = JSON.parse(res)
                        if (res.code == 200) {
                            setSignalEdit(res.data.permissions.filter(module => module.class_name === 'past_perfomance' && module.is_edit === 1).length > 0
                            );
                            setSignalView(res.data.permissions.filter(module => module.class_name === 'tradelog' && module.is_view === 1).length > 0
                            );
                            setSignalChangeStatus(res.data.permissions.filter(module => module.class_name === 'tradelog' && module.is_change_status === 1).length > 0)
                            setSignalAdd(res.data.permissions.filter(module => module.class_name === 'past_perfomance' && module.is_create === 1).length > 0)
                        }
                    });
                } else {
                    setSignalList([])
                }
            })
        }
    }, [])

    useEffect(() => {
        $('#mangeUser').DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                order: [[0, 'desc']],
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'copy',
                    className: 'btn-mute'
                }, {
                    extend: 'csv',
                    className: 'btn-mute'
                }, {
                    extend: 'print',
                    className: 'btn-mute'
                }],
                "bDestroy": true,
                scrollX: true,
                scrollY: "500px", // adjust the height of the scrollable area
                fixedHeader: true,
            }
        );
    }, [signalList])

    const showMessage = (msg) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: msg,
            toast: true,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function changeStatus(signalId, item, position) {
        if (signalChangeStatus == 1) {

            const status = (item?.is_active == 1) ? "0" : "1"

            changesignalStatus({ "signal_id": signalId, "is_active": status }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    showMessage(response.message)
                    signalList[position].is_active = status
                    const data = renderToStaticMarkup(renderStatusUiText(signalList[position])).replace("MuiBox-root css-4u9rcm", "MuiBox-root css-k0gowo").replace("MuiBox-root css-1oewkz4", "MuiBox-root css-lv896x")
                    $('#mangeUser').DataTable().row("#signalId" + signalId).cell("#signalStatus" + signalId).data(data).draw(false);

                }
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    function renderStatusUiText(item) {
        return (
            item?.is_active == 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    function deleteSignalIdWise(signalId) {
        if (signalRemove == 1) {
            Swal.fire({
                title: 'Are you sure you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#02C9D6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    // console.log("signalId " + signalId)
                    removeSignal({ "signal_id": signalId }).then((response) => {
                        response = JSON.parse(response)
                        if (response.code == 200) {
                            $('#mangeUser').DataTable().row("#signalId" + signalId).remove().draw(false);
                            showMessage(response.message)
                        }
                    });
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    const addSignal = () => {
        if (signalAdd == 1) {
            navigate(`/past_perfomance/add_historical_signal`);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    const viewPerfomanceAnalytics = () => {
        if (signalView == 1) {
            navigate(`/perfomance-analytics`);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    const editSignal = (item) => {
        if (signalEdit == 1) {
            navigate(`/past_perfomance/edit_historical_signal/${item?._id}`);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You do not have permission to access',
            })
        }
    }

    if (signalList == null) return <> </>

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>

                        <div className='cal'>
                            <button className='btn btn-primary btn-custom' style={{ marginLeft: '80%' }} onClick={addSignal}>Add Historical Signal</button>

                            <p>
                                <a className="btn btn-primary mo-mb-2" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                    Filter
                                </a>
                                <button className="btn btn-primary mo-mb-2 ml-2" onClick={viewPerfomanceAnalytics}>
                                    Performance Analytics
                                </button>
                                <div className={`collapse ${divState !== null ? 'show' : ''}`} id="collapseExample">
                                    <div className="card card-body">
                                        <div className='row'>
                                            <div className='col-md-2'>
                                                <label>Admin Name : </label>

                                                <Select
                                                    aria-labelledby="aria-label"
                                                    placeholder="Select Type.."
                                                    value={adminName}
                                                    onChange={(e) => { setAdminName(e); setDivState('show'); }}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    name="aria-live-color"
                                                    options={adminNames}
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <label>Signal Type : </label>
                                                <Select
                                                    aria-labelledby="aria-label"
                                                    placeholder="Select Type.."
                                                    value={signalType}
                                                    onChange={(e) => { setSignalType(e); setDivState('show'); }}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    name="aria-live-color"
                                                    options={typeOptions}
                                                />

                                            </div>

                                            <div className='col-md-6'>
                                                <label>Select Date Range : </label>
                                                <DateRangePicker
                                                    onApply={setDates}
                                                    initialSettings={{ startDate: moment().subtract(6, "days").format('MM/DD/YYYY'), endDate: moment().format('MM/DD/YYYY') }}
                                                >
                                                    <input
                                                        type="text"
                                                        value={dates.startDate !== '' ? moment(dates.startDate).format('MMMM DD, YYYY') + " - " + moment(dates.endDate).format('MMMM DD, YYYY') : 'Select Time'}
                                                        className="form-control"
                                                        onChange={() => { console.log(`changes`); }}
                                                    />
                                                </DateRangePicker>


                                            </div>
                                            <div className='col-md-2'>
                                                <label>Display Signals : </label>
                                                <Select
                                                    aria-labelledby="aria-label"
                                                    placeholder="Select Type.."
                                                    value={showSignal}
                                                    onChange={(e) => { setShowSignal(e); setDivState('show'); }}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    name="aria-live-color"
                                                    options={signalDisplayOptions}
                                                />

                                            </div>

                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <div className="form-inline">
                                                    <button href="" className="btn btn-warning waves-effect btn-file" onClick={clearFilter} >
                                                        <i className="mdi mdi-filter-remove-outline"></i> <span><b>Clear Filter</b></span>
                                                    </button>

                                                    <button className="btn btn-success waves-effect btn-file mx-1"  >
                                                        <i className="mdi mdi-cloud-download me-1 font-14"></i><span><b> Export </b></span>
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p>

                            <table id="mangeUser" className="table table-hover dataTable js-exportable " >
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Admin Name</th>
                                        <th style={{ textAlign: "center" }}>Name</th>
                                        <th style={{ textAlign: "center" }}>Category</th>
                                        <th style={{ textAlign: "center" }}>Signal Type</th>
                                        {/* <th style={{ textAlign: "center" }}>Strategies</th> */}
                                        <th style={{ textAlign: "center" }}>Scrip Symbol</th>
                                        <th style={{ textAlign: "center" }}>Stop Loss</th>
                                        <th style={{ textAlign: "center" }}>Entry Price</th>
                                        <th style={{ textAlign: "center" }}>Target Price</th>
                                        <th style={{ textAlign: "center" }}>Exit Price</th>
                                        <th style={{ textAlign: "center" }}>In Profit / In Loss</th>
                                        <th style={{ textAlign: "center" }}>Trade Direction</th>
                                        <th style={{ textAlign: "center" }}>Trade Duration</th>
                                        <th style={{ textAlign: "center" }}>Trade PDF</th>
                                        <th style={{ textAlign: "center" }}>Trade Open Date</th>
                                        <th style={{ textAlign: "center" }}>Trade Close Date</th>
                                        <th style={{ textAlign: "center" }}>Trade Return On Total Capital</th>
                                        <th style={{ textAlign: "center" }}>Trade Return</th>
                                        <th style={{ textAlign: "center" }}>Trade Status</th>
                                        <th style={{ textAlign: "center" }}>Risk Reward Ratio</th>
                                        <th style={{ textAlign: "center" }}>Action</th>

                                    </tr>
                                </thead>
                                {/* <tfoot>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>ID</th>
                                        <th style={{ textAlign: "center" }}>Admin Name</th>
                                        <th style={{ textAlign: "center" }}>Name</th>
                                        <th style={{ textAlign: "center" }}>Category</th>
                                        <th style={{ textAlign: "center" }}>Signal Type</th>
                                        <th style={{ textAlign: "center" }}>Scrip Symbol</th>
                                        <th style={{ textAlign: "center" }}>Stop Loss</th>
                                        <th style={{ textAlign: "center" }}>Entry Price</th>
                                        <th style={{ textAlign: "center" }}>Target Price</th>
                                        <th style={{ textAlign: "center" }}>Close Price</th>
                                        <th style={{ textAlign: "center" }}>In Profit / In Loss</th>
                                        <th style={{ textAlign: "center" }}>Trade Direction</th>
                                        <th style={{ textAlign: "center" }}>Trade Duration</th>
                                        <th style={{ textAlign: "center" }}>Trade PDF</th>
                                        <th style={{ textAlign: "center" }}>Trade Open Date</th>
                                        <th style={{ textAlign: "center" }}>Trade Close Date</th>
                                        <th style={{ textAlign: "center" }}>Trade Return On Total Capital</th>
                                        <th style={{ textAlign: "center" }}>Return On Investment</th>
                                        <th style={{ textAlign: "center" }}>Risk Reward Ratio</th>
                                        <th style={{ textAlign: "center" }}>Action</th>
                                    </tr>
                                </tfoot> */}
                                <tbody>
                                    {
                                        signalList && signalList.map((item, index) => {
                                            console.log(item?.report_pdf_url);
                                            return (
                                                <tr id={"signalId" + item?._id} >
                                                    <td style={{ textAlign: "center" }}>
                                                        {index + 1}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.userName}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.name}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.category}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.type == 1 ? "Free" : "Premium"}
                                                    </td>
                                                    {/* <td style={{ textAlign: "center" }}>
                                                        {item?.strategyNames}
                                                    </td> */}
                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.symbolName}
                                                    </td>
                                                    {/* <td style={{ textAlign: "center" }}>
                                                        {item?.fno_symbol ?? '-'}
                                                    </td> */}
                                                    <td style={{ textAlign: "center" }}>
                                                        {`\u20B9 ${item?.stoploss.price}`}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {`\u20B9 ${item?.entry_price}`}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {`\u20B9 ${item?.target_price.price}`}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {`\u20B9 ${item?.trade_close_price}`}
                                                    </td>
                                                    <td style={{ textAlign: "center" }} >
                                                        {item?.is_profit == 1 ? <span style={{ color: 'green' }}>Profit</span> : <span style={{ color: 'red' }}>Loss</span>}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.trade_direction === 1 ? 'LONG' : 'SHORT'}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.trade_duration}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.report_pdf != null ? <a href={`${process.env.REACT_APP_S3URL}/report_pdf/${item?.report_pdf}`} target="_blank" rel="noreferrer" style={{ color: 'blue' }}> Click Here To View PDF </a> : 'Not Uploaded'}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.entry_date ? moment(item?.entry_date).format('YYYY-MM-DD HH:mm:ss') : moment(item?.created_at).format('YYYY-MM-DD HH:mm:ss')}                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {moment(item?.trade_close_date).format('YYYY-MM-DD HH:mm:ss')}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.profit.value ?
                                                            (item?.profit.type === 'Percentage' ?
                                                                `${item?.profit.value}%` :
                                                                (item?.profit.type === 'Price' ?
                                                                    `\u20B9 ${item?.profit.value}` :
                                                                    '-'
                                                                )
                                                            ) :
                                                            '-'
                                                        }
                                                       
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {`${item?.trade_return?.toFixed(2)}%`}
                                                       
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.trade_return >= 0.2 ? (
                                                            <span style={{ color: 'green' }}>Profit Booked</span>
                                                        ) : item?.trade_return <= -0.2 ? (
                                                            <span style={{ color: 'red' }}>Loss Booked</span>
                                                        ) : (
                                                            <span style={{ color: 'blue' }}>Cost Exit</span>
                                                        )}
                                                    </td>

                                                    <td style={{ textAlign: "center" }}>
                                                        {item?.risk_reward_ratio}
                                                    </td>

                                                    <td style={{ textAlign: "center" }}>



                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => { deleteSignalIdWise(item?._id) }}>
                                                                <FeatherIcon icon="trash-2" width="18" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={() => { editSignal(item) }}>
                                                                <FeatherIcon icon="edit" width="18" />
                                                            </IconButton>
                                                        </Tooltip>


                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PastPerfomanceList;