import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
const XLSX = require('xlsx');

export default function ExcelEncryption() {
    
    const navigate = useNavigate();

    var asyncLoop = require('node-async-loop');

    const [encryptionData, setEncryptionData] = useState([])
    

    useEffect(() => {
        // console.log("---------",encryptionData);
        if(encryptionData.length != 0){
            asyncLoop(encryptionData, function (item, next)
            {
                var version = '01'
                // console.log("----->",data)
                var firstArray = [item.Category,item.SpeedRating,item.PlantCode]
                var firstResult = encryptValuesMax15(firstArray)
                // console.log("firstArray----->",firstArray)
                // console.log("firstResult----->",firstResult.toString().padStart(4, "0"))
                var secondArray = [item.LoadIndex,item.Platform,item.SizeCode,item.OptionalCode,item.Customer]
                var secondResult = encryptValuesMax255(secondArray)
                // console.log("secondArray----->",secondArray)
                // console.log("secondResult----->",secondResult.toString().padStart(12, "0"))
                var thirdResult = encryptBarcodeAndWeekCode(item.Barcode.toString().padStart(10, "0"),item.Weekcode.toString().padStart(4, "0"))
                // console.log("thirdArray----->",data.Barcode,data.Weekcode)
                // console.log("thirdResult----->",thirdResult)
                var fourResult = encryptSizeTyreTypeBarcodeAndWeekCode(thirdResult,item.Size,item.TyreType)
                // console.log("fourArray----->",thirdResult,data.Size,data.Tyre)
                // console.log("fourResult----->",fourResult.toString().padStart(19, "0"))
                var finalResult = version+firstResult.toString().padStart(4, "0")+secondResult.toString().padStart(12, "0")+fourResult.toString().padStart(19, "0")
                item.Encryption=finalResult
                // console.log("--->",finalResult,finalResult.length)
                next();
            }, function ()
            {   
                Swal.close()
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'QR Encryption successfully',
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                convertToExcel(encryptionData)
                setTimeout(()=>{
                    navigate("/")
                },2000)
                // console.log('Finished!',encryptionData);
            });
        }      
    }, [encryptionData])
       
    function encryptValuesMax15(values) {
        let result = BigInt(0);
      
        for (let i = 0; i < 3; i++) {
          if (i < values.length) {
            let value =  BigInt(values[i]);
            result |= value << ( BigInt(i) *  BigInt(4));
          }
        }
      
        return result.toString();
    }

    function encryptValuesMax255(values) {
        let result = BigInt(0);

        for (let i = 0; i < 5; i++) {
            if (i < values.length) {
            const value = BigInt(values[i]);
            result |= value << (BigInt(i) * BigInt(8));
            }
        }
       
        return result.toString();
    }


    function encryptBarcodeAndWeekCode(barcode, weekCode) {
        let encryptBarcodeAndWeekCode = ( BigInt(barcode) << BigInt(17)) |  BigInt(weekCode);
        return encryptBarcodeAndWeekCode.toString();
    }

    function encryptSizeTyreTypeBarcodeAndWeekCode(encryptBarcodeAndWeekCode, size, tyreType) {
        let encryptedSizeTyreTypeEncryptBarcodeAndWeekCode = ( BigInt(encryptBarcodeAndWeekCode) << BigInt(11)) | ( BigInt(tyreType) << BigInt(10)) |  BigInt(size);
        return encryptedSizeTyreTypeEncryptBarcodeAndWeekCode.toString();
    }
      
    const convertToExcel = (jsonData) => {
        // Convert JSON to worksheet
        const worksheet = XLSX.utils.json_to_sheet(jsonData);
      
        // Create workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      
        // Generate Excel file buffer
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      
        // Convert buffer to Blob for saving
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        
        // Save the file using FileSaver.js
        saveAs(blob, 'encryption.xlsx');
    };

    const readUploadFile = (e) => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setEncryptionData(json)
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }    
      
  return (
    <>
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-6 col-md-6 col-sm-6'>
                        <h5>QR Encryption</h5>
                        <hr></hr>
                        <div className='mt-4'>
                            <input type="file" accept='.xls,.xlsx' onChange={readUploadFile} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}