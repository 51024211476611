import React from 'react';
import { Link  } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { login } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import { loginStoreData } from '../common/RedirectPathMange';
import Swal from 'sweetalert2'


function Login() {

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
     
        login(data).then((res) => {
            console.log(data,"------------");
            const response = JSON.parse(res)
            console.log(response,"==================");
            if (response.code == 200) {
                loginStoreData(response.data)
            }
            else {
                Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        });
    }

    
    return (
        <div className="wrapper-page">
            <div className="card">
                <div className="card-body">
                    <h3 className="text-center">
                        <Link to="/" className="logo logo-admin"><img src={process.env.PUBLIC_URL + "/assets/images/logo1.png"} height="45" alt="logo" /></Link>
                    </h3>
                    <div className="">
                        <h4 className="text-muted font-18 m-b-5 text-center">Welcome Back !</h4>
                        <p className="text-muted text-center">Sign in to continue to StockWiz</p>
                       
                        <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)} >

                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" {...register('email')} className="form-control" id="email" placeholder="Enter Email" />
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="userpassword">Password</label>
                                <input type="password" {...register('password')} className="form-control" id="userpassword" placeholder="Enter Password" />
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </div>

                            <div className="form-group row m-t-30">

                                <div className="col-12 text-center">
                                    <button className="btn btn-primary w-md waves-effect 
                                    waves-light btn-custom" type="submit">Login</button>
                                    {/* <Link className="btn btn-primary w-md waves-effect waves-light" type="submit">Login</Link> */}
                                </div>
                            </div>

                            <div className="form-group m-t-10 mb-0 row">
                                <div className="col-12 m-t-20">
                                    <Link to="/forgotPassword" className="text-muted btn btn-link"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <div className="m-t-40 text-center">
                <p>© {new Date().getFullYear()} ISL Cargo. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
            </div> */}
        </div>
    );
}


export default Login;